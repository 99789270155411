<div
  id="editFieldLayer"
  class="ddp-ui-layer-box ddp-layer-shelf"
  [style.display]="editingField == null ? 'none' : 'block'"
  (clickOutside)="clickOutside()"
  [excludeBeforeClick]="true"
  [exclude]="'.ddp-icon-layer'"
>
  <div *ngIf="editingField">
    <div class="ddp-ui-layer-title" title="{{ editingField.name }}">
      {{ editingField.name }}
    </div>
    <ul class="ddp-list-layer-option ddp-list-radio">
      <li *ngIf="-1 === editingField?.field?.logicalType?.toString().indexOf('GEO')">
        <a href="javascript:">
          <label class="ddp-label-toggle">
            {{ 'msg.page.th.filter' | translate }}
            <input
              (change)="onChangeFilter($event)"
              [checked]="filterFiledList.indexOf(editingField.name) != -1"
              type="checkbox"
            />
            <span class="ddp-txt-toggle">
              <i class="ddp-icon-checked"></i>
            </span>
          </label>
        </a>
      </li>

      <li [class.ddp-hover]="fix2DepthContext">
        <a (mouseover)="setEditingFieldAlias(editingField)" href="javascript:">
          {{ 'msg.page.th.alias-this-chart' | translate }}
          <div class="ddp-data-result">
            <span class="ddp-txt-result" title="{{ getDisplayEditingPivotAlias(editingField) }}">
              {{ getDisplayEditingPivotAlias(editingField) }}
              <em class="ddp-icon-view"></em>
            </span>
          </div>
        </a>

        <div class="ddp-ui-layer-sub">
          <div class="ddp-box-layer">
            <input
              [(ngModel)]="editingFieldAlias"
              type="text"
              class="ddp-input-typebasic"
              placeholder="{{ getAliasPlaceholder(editingField) }}"
              (focus)="fix2DepthContext = true"
              (keyup.enter)="onAliasApply($event)"
            />
            <a
              *ngIf="isSetPivotAlias(editingField)"
              (click)="onAliasReset($event)"
              href="javascript:"
              class="ddp-btn-line"
            >
              {{ 'msg.page.btn.reset' | translate }}
            </a>
            <a
              href="javascript:"
              (click)="onAliasApply($event)"
              [class.ddp-full]="!isSetPivotAlias(editingField)"
              class="ddp-btn-solid"
            >
              {{ 'msg.page.btn.apply' | translate }}
            </a>
          </div>
        </div>
      </li>
      <li *ngIf="editingField.subType == 'user_expr'">
        <a href="javascript:">
          {{ 'msg.page.th.edit-cond' | translate }}
          <div class="ddp-data-result ddp-padr0">
            <span class="ddp-txt-result">{{ unescapeCustomColumnExpr(editingField.field.expr) }}</span>
            <em
              (click)="changePivotContext.emit({ type: 'customField', value: editingField.field })"
              class="ddp-btn-option"
            ></em>
          </div>
        </a>
      </li>
      <li *ngIf="'map' !== uiOption.type?.toString()" (mouseover)="fix2DepthContext = false">
        <a href="javascript:">
          {{ 'msg.page.th.align' | translate }}
          <div class="ddp-data-result ddp-type">
            {{ getAlignName() }}
            <em class="ddp-icon-view"></em>
          </div>
        </a>

        <div class="ddp-ui-layer-sub">
          <ul class="ddp-list-popup">
            <li (click)="onChangeOrder('NONE')" [class.ddp-selected]="hasAlign('NONE')">
              <a href="javascript:">
                {{ 'msg.page.li.data.order' | translate }}
                <em class="ddp-icon-check"></em>
              </a>
            </li>
            <li (click)="onChangeOrder('ASC')" [class.ddp-selected]="hasAlign('ASC')">
              <a href="javascript:">
                {{ 'msg.page.li.asc' | translate }}
                <em class="ddp-icon-check"></em>
              </a>
            </li>
            <li (click)="onChangeOrder('DESC')" [class.ddp-selected]="hasAlign('DESC')">
              <a href="javascript:">
                {{ 'msg.page.li.desc' | translate }}
                <em class="ddp-icon-check"></em>
              </a>
            </li>
          </ul>
        </div>
      </li>

      <li *ngIf="showAggregate()" (mouseover)="fix2DepthContext = false">
        <a href="javascript:">
          {{ 'msg.page.th.aggregate' | translate }}
          <div class="ddp-data-result ddp-type">
            {{ editingField.aggregationType }}
            <em class="ddp-icon-view"></em>
          </div>
        </a>

        <div class="ddp-ui-layer-sub">
          <ul class="ddp-list-popup">
            <li
              *ngFor="let aggType of aggTypeList"
              [class.ddp-selected]="
                aggType['id'] == editingField.aggregationType && 'PERCENTILE' !== editingField.aggregationType
              "
              (click)="onChangeAggregationType(aggType['id'])"
              [class.ddp-disabled]="-1 !== editingField.aggregationTypeList.indexOf(aggType['id'])"
            >
              <a href="javascript:">
                {{ aggType['label'] }}
                <em *ngIf="'PERCENTILE' !== aggType['id']" class="ddp-icon-check"></em>

                <div *ngIf="'PERCENTILE' === aggType['id']" class="ddp-data-result ddp-type">
                  {{
                    'PERCENTILE' !== editingField.aggregationType
                      ? ''
                      : editingField.options && -1 !== editingField.options.indexOf(0.25)
                      ? '1/4'
                      : '3/4'
                  }}
                  <em class="ddp-icon-view"></em>
                </div>
              </a>

              <div *ngIf="'PERCENTILE' === aggType['id']" class="ddp-ui-layer-sub">
                <ul class="ddp-list-popup">
                  <li
                    *ngFor="let item of aggType['options']"
                    (click)="onChangeAggregationType(aggType['id'], item.decimal)"
                    [class.ddp-selected]="
                      'PERCENTILE' === editingField.aggregationType &&
                      editingField.options &&
                      -1 !== editingField.options.indexOf('value')
                        ? editingField.options.indexOf(item.decimal) !== -1
                        : false
                    "
                    [class.ddp-disabled]="-1 !== editingField.aggregationTypeList.indexOf(aggType['id'])"
                  >
                    <a href="javascript:">
                      {{ item.fraction }}
                      <em class="ddp-icon-check"></em>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </li>
      <li *ngIf="chartType == 'combine' && editingField.type == 'measure'" (mouseover)="fix2DepthContext = false">
        <a href="javascript:">
          {{ 'msg.page.th.vslztn' | translate }}
          <div class="ddp-data-result ddp-type">
            {{
              !editingField || !editingField?.options
                ? combineAggIndex % 2 == 0
                  ? 'Bar'
                  : 'Line'
                : -1 !== editingField.options.indexOf('bar')
                ? 'Bar'
                : 'Line'
            }}
            <em class="ddp-icon-view"></em>
          </div>
        </a>

        <div class="ddp-ui-layer-sub">
          <ul class="ddp-list-popup">
            <li
              (click)="combineChangeSeriesConvertType('bar')"
              [ngClass]="{
                'ddp-selected':
                  !editingField || !editingField?.options
                    ? combineAggIndex % 2 == 0
                    : -1 !== editingField.options.indexOf('bar')
              }"
            >
              <a href="javascript:">
                {{ 'msg.page.li.bar' | translate }}
                <em class="ddp-icon-check"></em>
              </a>
            </li>
            <li
              (click)="combineChangeSeriesConvertType('line')"
              [ngClass]="{
                'ddp-selected':
                  !editingField || !editingField?.options
                    ? combineAggIndex % 2 !== 0
                    : -1 !== editingField.options.indexOf('line')
              }"
            >
              <a href="javascript:">
                {{ 'msg.page.li.line' | translate }}
                <em class="ddp-icon-check"></em>
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li *ngIf="!uiOption?.split && editingField.type == 'measure'" (mouseover)="fix2DepthContext = false">
        <a href="javascript:">
          {{ 'msg.page.th.format' | translate }}
          <div class="ddp-data-result ddp-type">
            {{
              !editingField.format
                ? ''
                : editingField.format.type == 'number'
                ? ('msg.page.li.num' | translate)
                : editingField.format.type == 'currency'
                ? ('msg.page.li.currency' | translate)
                : editingField.format.type == 'percent'
                ? ('msg.page.li.percent' | translate)
                : editingField.format.type == 'exponent10'
                ? ('msg.page.th.exponential.form' | translate)
                : ''
            }}
            <em class="ddp-icon-view"></em>
          </div>
        </a>

        <div class="ddp-ui-layer-sub">
          <ul class="ddp-list-popup">
            <li
              (click)="onChangeFormat('number')"
              [class.ddp-selected]="editingField.format && editingField.format.type == 'number'"
            >
              <a href="javascript:">
                {{ 'msg.page.li.num' | translate }}
                <em class="ddp-icon-check"></em>
              </a>
            </li>
            <li
              (click)="onChangeFormat('currency')"
              [class.ddp-selected]="editingField.format && editingField.format.type == 'currency'"
            >
              <a href="javascript:">
                {{ 'msg.page.li.currency' | translate }}
                <em class="ddp-icon-check"></em>
              </a>
            </li>
            <li
              (click)="onChangeFormat('percent')"
              [class.ddp-selected]="editingField.format && editingField.format.type == 'percent'"
            >
              <a href="javascript:">
                {{ 'msg.page.li.percent' | translate }}
                <em class="ddp-icon-check"></em>
              </a>
            </li>
            <li
              (click)="onChangeFormat('exponent10')"
              [class.ddp-selected]="editingField.format && editingField.format.type == 'exponent10'"
            >
              <a href="javascript:">
                {{ 'msg.page.th.exponential.form' | translate }}
                <em class="ddp-icon-check"></em>
              </a>
            </li>
            <li (click)="onChangeFormat('')">
              <a href="javascript:">
                {{ 'msg.page.li.detail-settings' | translate }}
                <em class="ddp-btn-option"></em>
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li *ngIf="false && ['measure', 'FIXME-측정값기타'].indexOf(editingField.type) > -1">
        <a href="javascript:">
          <label class="ddp-label-toggle">
            {{ 'msg.page.chart.show.value.on.chart' | translate }}
            <input
              (change)="onChangeShowValue($event.target.checked)"
              [(ngModel)]="editingField.showValue"
              type="checkbox"
            />
            <span class="ddp-txt-toggle">
              <i class="ddp-icon-checked"></i>
            </span>
          </label>
        </a>
      </li>
      <li *ngIf="false && editingField.type == 'measure' && isPossibleSeries()">
        <a href="javascript:">
          <label class="ddp-label-toggle">
            {{ 'msg.page.chart.set.secondary.axis' | translate }}
            <input (change)="onChangeSecondaryAxis($event)" [checked]="isSecondaryAxis()" type="checkbox" />
            <span class="ddp-txt-toggle">
              <i class="ddp-icon-checked"></i>
            </span>
          </label>
        </a>
      </li>
      <li *ngIf="editingField.type == 'timestamp'" (mouseover)="fix2DepthContext = false">
        <a href="javascript:">
          {{ 'msg.page.th.gran' | translate }}
          <div class="ddp-data-result ddp-type">
            {{ getGranularityName(editingField, true) }}
            <em class="ddp-icon-view"></em>
          </div>
        </a>

        <div class="ddp-ui-layer-sub ddp-type">
          <div class="ddp-ui-layer-title">
            {{ 'msg.page.ui.formula' | translate }}
          </div>
          <ul class="ddp-list-popup">
            <li
              (click)="onChangeGranularity(false, 'SECOND')"
              [class.ddp-selected]="isGranularitySelected(editingField, false, 'SECOND')"
              *ngIf="isUseGranularity(false, 'SECOND')"
              [class.ddp-disabled]="-1 !== editingField.granularityList.indexOf('SECOND')"
            >
              <a href="javascript:">
                {{ 'msg.page.li.second' | translate }}
                <div class="ddp-data-info">May 8, 2015 14:05:30</div>
              </a>
            </li>

            <li
              (click)="onChangeGranularity(false, 'MINUTE')"
              [class.ddp-selected]="isGranularitySelected(editingField, false, 'MINUTE')"
              *ngIf="isUseGranularity(false, 'MINUTE')"
              [class.ddp-disabled]="-1 !== editingField.granularityList.indexOf('MINUTE')"
            >
              <a href="javascript:">
                {{ 'msg.page.li.minute' | translate }}
                <div class="ddp-data-info">May 8, 2015 14:05</div>
              </a>
            </li>
            <li
              (click)="onChangeGranularity(false, 'HOUR')"
              [class.ddp-selected]="isGranularitySelected(editingField, false, 'HOUR')"
              *ngIf="isUseGranularity(false, 'HOUR')"
              [class.ddp-disabled]="-1 !== editingField.granularityList.indexOf('HOUR')"
            >
              <a href="javascript:">
                {{ 'msg.page.li.hour' | translate }}
                <div class="ddp-data-info">May 8, 2015 14:00</div>
              </a>
            </li>
            <li
              (click)="onChangeGranularity(false, 'DAY')"
              [class.ddp-selected]="isGranularitySelected(editingField, false, 'DAY')"
              *ngIf="isUseGranularity(false, 'DAY')"
              [class.ddp-disabled]="-1 !== editingField.granularityList.indexOf('DAY')"
            >
              <a href="javascript:">
                {{ 'msg.page.li.day' | translate }}
                <div class="ddp-data-info">May 8, 2015</div>
              </a>
            </li>

            <li
              (click)="onChangeGranularity(false, 'MONTH')"
              [class.ddp-selected]="isGranularitySelected(editingField, false, 'MONTH')"
              *ngIf="isUseGranularity(false, 'MONTH')"
              [class.ddp-disabled]="-1 !== editingField.granularityList.indexOf('MONTH')"
            >
              <a href="javascript:">
                {{ 'msg.page.li.month' | translate }}
                <div class="ddp-data-info">May 2015</div>
              </a>
            </li>
            <li
              (click)="onChangeGranularity(false, 'QUARTER')"
              [class.ddp-selected]="isGranularitySelected(editingField, false, 'QUARTER')"
              *ngIf="isUseGranularity(false, 'QUARTER')"
              [class.ddp-disabled]="-1 !== editingField.granularityList.indexOf('QUARTER')"
            >
              <a href="javascript:">
                {{ 'msg.page.li.quarter' | translate }}
                <div class="ddp-data-info">Q2 2015</div>
              </a>
            </li>
            <li
              (click)="onChangeGranularity(false, 'YEAR')"
              [class.ddp-selected]="isGranularitySelected(editingField, false, 'YEAR')"
              *ngIf="isUseGranularity(false, 'YEAR')"
              [class.ddp-disabled]="-1 !== editingField.granularityList.indexOf('YEAR')"
            >
              <a href="javascript:">
                {{ 'msg.page.li.year' | translate }}
                <div class="ddp-data-info">2015</div>
              </a>
            </li>

            <li
              (click)="onChangeGranularity(false, 'NONE')"
              [class.ddp-selected]="isGranularitySelected(editingField, false, 'NONE')"
              [class.ddp-disabled]="-1 !== editingField.granularityList.indexOf('NONE')"
            >
              <a href="javascript:">
                {{ 'msg.page.li.none' | translate }}
              </a>
            </li>
          </ul>

          <div class="ddp-ui-layer-title">
            {{ 'msg.page.ui.discon.type' | translate }}
          </div>
          <ul class="ddp-list-popup">
            <li
              (click)="onChangeGranularity(true, 'SECOND')"
              [class.ddp-selected]="isGranularitySelected(editingField, true, 'SECOND')"
              *ngIf="isUseGranularity(true, 'SECOND')"
              [class.ddp-disabled]="-1 !== editingField.granularityList.indexOf('SECOND')"
            >
              <a href="javascript:">
                {{ 'msg.page.li.second' | translate }}
                <div class="ddp-data-info">14:05:30 (by minute)</div>
              </a>
            </li>

            <li
              (click)="onChangeGranularity(true, 'MINUTE')"
              [class.ddp-selected]="isGranularitySelected(editingField, true, 'MINUTE')"
              *ngIf="isUseGranularity(true, 'MINUTE')"
              [class.ddp-disabled]="-1 !== editingField.granularityList.indexOf('MINUTE')"
            >
              <a href="javascript:">
                {{ 'msg.page.li.minute' | translate }}
                <div class="ddp-data-info">14:05 (by hour)</div>
              </a>
            </li>
            <li
              (click)="onChangeGranularity(true, 'HOUR')"
              [class.ddp-selected]="isGranularitySelected(editingField, true, 'HOUR')"
              *ngIf="isUseGranularity(true, 'HOUR')"
              [class.ddp-disabled]="-1 !== editingField.granularityList.indexOf('HOUR')"
            >
              <a href="javascript:">
                {{ 'msg.page.li.hour' | translate }}
                <div class="ddp-data-info">14:00 (by day)</div>
              </a>
            </li>
            <li
              [class.ddp-selected]="isGranularitySelected(editingField, true, 'DAY')"
              *ngIf="isUseGranularity(true, 'DAY')"
              [class.ddp-disabled]="-1 !== editingField.granularityList.indexOf('DAY')"
            >
              <a href="javascript:">
                {{ 'msg.page.li.day' | translate }}
                <div class="ddp-data-result ddp-type">
                  Friday (by week)
                  <em class="ddp-icon-view"></em>
                </div>
              </a>

              <div class="ddp-ui-layer-sub">
                <ul class="ddp-list-popup">
                  <li
                    (click)="onChangeGranularity(true, 'DAY', 'WEEK')"
                    [class.ddp-selected]="isGranularitySelected(editingField, true, 'DAY', 'WEEK')"
                    [class.ddp-disabled]="-1 !== editingField.granularityList.indexOf('DAY')"
                  >
                    <a href="javascript:">
                      By week
                      <div class="ddp-data-info">Friday</div>
                      <em class="ddp-icon-check"></em>
                    </a>
                  </li>
                  <li
                    (click)="onChangeGranularity(true, 'DAY', 'MONTH')"
                    [class.ddp-selected]="isGranularitySelected(editingField, true, 'DAY', 'MONTH')"
                    [class.ddp-disabled]="-1 !== editingField.granularityList.indexOf('DAY')"
                  >
                    <a href="javascript:">
                      By month
                      <div class="ddp-data-info">8</div>
                      <em class="ddp-icon-check"></em>
                    </a>
                  </li>
                  <li
                    (click)="onChangeGranularity(true, 'DAY', 'YEAR')"
                    [class.ddp-selected]="isGranularitySelected(editingField, true, 'DAY', 'YEAR')"
                    [class.ddp-disabled]="-1 !== editingField.granularityList.indexOf('DAY')"
                  >
                    <a href="javascript:">
                      By year
                      <div class="ddp-data-info">130</div>
                      <em class="ddp-icon-check"></em>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li
              [class.ddp-selected]="isGranularitySelected(editingField, true, 'WEEK')"
              *ngIf="isUseGranularity(true, 'WEEK')"
              [class.ddp-disabled]="-1 !== editingField.granularityList.indexOf('WEEK')"
            >
              <a href="javascript:">
                {{ 'msg.page.li.week' | translate }}
                <div class="ddp-data-result ddp-type">
                  Week 19 (by year)
                  <em class="ddp-icon-view"></em>
                </div>
              </a>

              <div class="ddp-ui-layer-sub">
                <ul class="ddp-list-popup">
                  <li
                    (click)="onChangeGranularity(true, 'WEEK', 'MONTH')"
                    [class.ddp-selected]="isGranularitySelected(editingField, true, 'WEEK', 'MONTH')"
                    [class.ddp-disabled]="-1 !== editingField.granularityList.indexOf('WEEK')"
                  >
                    <a href="javascript:">
                      By month
                      <div class="ddp-data-info">Week 1</div>
                      <em class="ddp-icon-check"></em>
                    </a>
                  </li>
                  <li
                    (click)="onChangeGranularity(true, 'WEEK', 'YEAR')"
                    [class.ddp-selected]="isGranularitySelected(editingField, true, 'WEEK', 'YEAR')"
                    [class.ddp-disabled]="-1 !== editingField.granularityList.indexOf('WEEK')"
                  >
                    <a href="javascript:">
                      By year
                      <div class="ddp-data-info">Week 19</div>
                      <em class="ddp-icon-check"></em>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li
              [class.ddp-selected]="isGranularitySelected(editingField, true, 'MONTH')"
              *ngIf="isUseGranularity(true, 'MONTH')"
              [class.ddp-disabled]="-1 !== editingField.granularityList.indexOf('MONTH')"
            >
              <a href="javascript:">
                {{ 'msg.page.li.month' | translate }}
                <div class="ddp-data-result ddp-type">
                  May (by year)
                  <em class="ddp-icon-view"></em>
                </div>
              </a>

              <div class="ddp-ui-layer-sub">
                <ul class="ddp-list-popup">
                  <li
                    (click)="onChangeGranularity(true, 'MONTH', 'YEAR')"
                    [class.ddp-selected]="isGranularitySelected(editingField, true, 'MONTH', 'YEAR')"
                    [class.ddp-disabled]="-1 !== editingField.granularityList.indexOf('MONTH')"
                  >
                    <a href="javascript:">
                      By year
                      <div class="ddp-data-info">May</div>
                      <em class="ddp-icon-check"></em>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li
              (click)="onChangeGranularity(true, 'QUARTER')"
              [class.ddp-selected]="isGranularitySelected(editingField, true, 'QUARTER')"
              *ngIf="isUseGranularity(true, 'QUARTER')"
              [class.ddp-disabled]="-1 !== editingField.granularityList.indexOf('QUARTER')"
            >
              <a href="javascript:">
                {{ 'msg.page.li.quarter' | translate }}
                <div class="ddp-data-info">Q2 (by year)</div>
              </a>
            </li>
            <li
              (click)="onChangeGranularity(true, 'YEAR')"
              [class.ddp-selected]="isGranularitySelected(editingField, true, 'YEAR')"
              *ngIf="isUseGranularity(true, 'YEAR')"
              [class.ddp-disabled]="-1 !== editingField.granularityList.indexOf('YEAR')"
            >
              <a href="javascript:">
                {{ 'msg.page.li.year' | translate }}
                <div class="ddp-data-info">2015</div>
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</div>
