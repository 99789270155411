<div class="ddp-ui-divide">
  <div
    class="ddp-ui-down-title2 ddp-flex"
    [attr.data-gl-dragsource]="isDraggable ? 'enable' : 'disable'"
    [class.ddp-selected]="isNewFilter"
    [ngStyle]="!isDashboardMode ? { cursor: 'default' } : {}"
  >
    <div *ngIf="filter" class="ddp-itemtype-title">
      <div class="ddp-data-itemtype ddp-type">
        <span class="ddp-icon-box">
          <selfai-bi-shared-field-icon
            *ngIf="field"
            [view]="'DIMENSION'"
            [type]="field.type"
            [logicalType]="field.logicalType"
          ></selfai-bi-shared-field-icon>
          <em class="ddp-icon-use3" *ngIf="isWidgetInLayout"></em>
        </span>
        <span class="ddp-txt-itemtype">
          <span class="ddp-data-name">{{ filter.field }}</span>
          <span class="ddp-data-sub">{{ dataSource?.name }}</span>
          <span *ngIf="!isBoardFilter" class="ddp-txt-chartname">
            {{ "msg.board.ui.filter.used-this-chart" | translate }}
          </span>
        </span>
      </div>
    </div>

    <div *ngIf="filter" class="ddp-flex-button">
      <em class="ddp-icon-global-s" *ngIf="isBoardFilter"></em>

      <div *ngIf="filter.ui.filteringSeq > 0" class="ddp-wrap-datarecommend">
        {{ filter.ui.filteringSeq }}
        <em class="ddp-icon-recommend-s2"></em>
      </div>

      <div
        class="ddp-wrap-morebutton ddp-hover-tooltip"
        [class.ddp-selected]="isShowDetailMenu"
        (click)="toggleDetailMenu()"
        (clickOutside)="isShowDetailMenu = false"
      >
        <em class="ddp-icon-more"></em>
        <div class="ddp-ui-tooltip-info ddp-down">
          <em class="ddp-icon-view-top"></em>
          {{ "msg.comm.ui.more" | translate }}
        </div>

        <div class="ddp-wrap-popup2 ddp-types">
          <ul class="ddp-list-popup">
            <li *ngIf="!isDashboardMode && isBoardFilter && isChangeable">
              <a href="javascript:" (click)="changeGlobalToChart(filter)">
                <em class="ddp-icon-drop-global2"></em>
                {{ "msg.board.filter.ui.turn.chart" | translate }}
              </a>
            </li>
            <li *ngIf="false">
              <a href="javascript:" (click)="changeChartToGlobal(filter)">
                <em class="ddp-icon-drop-global"></em>
                {{ "msg.board.filter.ui.turn.global" | translate }}
              </a>
            </li>
            <li>
              <a href="javascript:" (click)="resetFilter(filter)">
                <em class="ddp-icon-drop-refresh"></em>
                {{ "msg.board.filter.ui.reset" | translate }}
              </a>
            </li>
            <li *ngIf="isEditable">
              <a
                href="javascript:"
                (click)="openUpdateFilterPopup(originalFilter)"
              >
                <em class="ddp-icon-drop-editmodify"></em>
                {{ "msg.board.filter.ui.edit" | translate }}
              </a>
            </li>
            <li *ngIf="isDeletable">
              <a href="javascript:" (click)="deleteFilter(filter)">
                <em class="ddp-icon-drop-editdel3"></em>
                {{ "msg.board.filter.ui.remove" | translate }}
              </a>
            </li>
          </ul>

          <div class="ddp-wrap-layer">
            <div class="ddp-area-toggle">
              <ul class="ddp-list-buttons ddp-clear">
                <li
                  (click)="setAbsolute()"
                  [class.ddp-selected]="!isRelativeType"
                  class="ddp-col-6"
                >
                  Absolute
                </li>
                <li
                  (click)="setRelative()"
                  [class.ddp-selected]="isRelativeType"
                  class="ddp-col-6"
                >
                  Relative
                </li>
              </ul>
            </div>

            <ul class="ddp-list-layer-option type-option type-sub-down">
              <li *ngIf="!isRelativeType">
                <a href="javascript:">
                  <div class="ddp-data-result ddp-type">
                    {{ filter.timeUnit?.toString() }}
                    <em class="ddp-icon-view"></em>
                  </div>
                  <span class="ddp-txt-data" title="Unit">
                    <em class="ddp-icon-drop-unit"></em>
                    Unit
                  </span>
                </a>
                <div class="ddp-ui-layer-sub">
                  <span class="ddp-label ddp-padt0">Continuous type</span>
                  <ul class="ddp-list-popup">
                    <li
                      *ngFor="let unit of dpContinuousList"
                      (click)="selectTimeUnit(false, unit.toUpperCase())"
                      [class.ddp-selected]="isSelectedContinuous(unit)"
                    >
                      <a href="javascript:">
                        {{ unit }}
                        <em class="ddp-icon-check"></em>
                      </a>
                    </li>
                  </ul>
                  <div class="ddp-wrap-layer">
                    <span class="ddp-label">Discontinuous type</span>
                    <ul class="ddp-list-popup">
                      <li
                        *ngFor="let byUnit of dpDiscontinuousList"
                        (click)="
                          selectTimeUnit(true, byUnit['unit'], byUnit['byUnit'])
                        "
                        [class.ddp-selected]="isSelectedDiscontinuous(byUnit)"
                      >
                        <a href="javascript:">
                          {{ byUnit["name"] }}
                          <em class="ddp-icon-check"></em>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li *ngIf="!isRelativeType">
                <a href="javascript:">
                  <div class="ddp-data-result ddp-type">
                    {{ isListType ? "List" : "Range" }}
                    <em class="ddp-icon-view"></em>
                  </div>
                  <span class="ddp-txt-data" title="Selection">
                    <em class="ddp-icon-drop-select"></em>
                    Selection
                  </span>
                </a>
                <div class="ddp-ui-layer-sub">
                  <ul class="ddp-list-popup">
                    <li
                      (click)="setTimeListFilter()"
                      [class.ddp-selected]="isListType"
                    >
                      <a href="javascript:">
                        List
                        <em class="ddp-icon-check"></em>
                      </a>
                    </li>
                    <li
                      (click)="setTimeRangeFilter()"
                      [class.ddp-selected]="isRangeType"
                    >
                      <a href="javascript:">
                        Range
                        <em class="ddp-icon-check"></em>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li *ngIf="isListType">
                <a href="javascript:">
                  <div class="ddp-data-result ddp-type">
                    {{
                      isFrequencyAsc
                        ? ("msg.comm.ui.soring.frequency.asc" | translate)
                        : ("msg.comm.ui.soring.alphnumeric.asc" | translate)
                    }}
                    <em class="ddp-icon-view"></em>
                  </div>
                  <span class="ddp-txt-data" title="Selection">
                    <em class="ddp-icon-drop-sort"></em>
                    Sort by
                  </span>
                </a>
                <div class="ddp-ui-layer-sub">
                  <ul class="ddp-list-popup">
                    <li
                      (click)="sortList('FREQUENCY', 'ASC')"
                      [class.ddp-selected]="isFrequencyAsc"
                    >
                      <a href="javascript:">
                        {{ "msg.comm.ui.soring.frequency.asc" | translate }}
                        <em class="ddp-icon-check"></em>
                      </a>
                    </li>
                    <li
                      (click)="sortList('ALPHNUMERIC', 'ASC')"
                      [class.ddp-selected]="isAlphnumericAsc"
                    >
                      <a href="javascript:">
                        {{ "msg.comm.ui.soring.alphnumeric.asc" | translate }}
                        <em class="ddp-icon-check"></em>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isShowFilter" class="ddp-contents-divide">
  <div
    *ngIf="!isDiscontinuousFilter && isContinuousByAll"
    class="ddp-ui-divide"
  >
    <div class="ddp-wrap-toggle-contents">
      <div *ngIf="isAllType" class="ddp-ui-toggle-contents">
        <span class="ddp-txt-preview">(No time filtering)</span>
      </div>

      <div *ngIf="isRelativeType" class="ddp-ui-toggle-contents">
        <selfai-bi-time-relative-filter
          [mode]="'PANEL'"
          [filter]="filter"
          (changeFilter)="changeFilterEvent($event)"
        ></selfai-bi-time-relative-filter>
      </div>

      <div *ngIf="isRangeType" class="ddp-ui-toggle-contents">
        <selfai-bi-time-range-filter
          [mode]="'PANEL'"
          [filter]="filter"
          [dashboard]="dashboard"
          (changeFilter)="changeFilterEvent($event)"
        ></selfai-bi-time-range-filter>
      </div>
    </div>
  </div>

  <div
    *ngIf="!isDiscontinuousFilter && !isContinuousByAll"
    class="ddp-ui-divide"
  >
    <div class="ddp-wrap-toggle-contents">
      <div *ngIf="isListType" class="ddp-ui-toggle-contents">
        <app-time-list-filter
          [mode]="'PANEL'"
          [inputFilter]="filter"
          [dashboard]="dashboard"
          [field]="field"
          (changeFilter)="changeFilterEvent($event)"
        ></app-time-list-filter>
      </div>

      <div *ngIf="isRangeType" class="ddp-ui-toggle-contents">
        <selfai-bi-time-range-filter
          [mode]="'PANEL'"
          [filter]="filter"
          [dashboard]="dashboard"
          (changeFilter)="changeFilterEvent($event)"
        ></selfai-bi-time-range-filter>
      </div>
    </div>
  </div>

  <div *ngIf="isDiscontinuousFilter" class="ddp-ui-divide">
    <app-time-list-filter
      [mode]="'PANEL'"
      [inputFilter]="filter"
      [dashboard]="dashboard"
      [field]="field"
      (changeFilter)="changeFilterEvent($event)"
    ></app-time-list-filter>
  </div>
</div>
