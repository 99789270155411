<div class="ddp-type-contents ddp-resize2" *ngIf="isShow" (click)="clickComponent($event)">
  <a (click)="close()" href="javascript:" class="ddp-btn-close"></a>

  <div class="ddp-ui-name2 ddp-type">{{ 'msg.space.create.title' | translate }}</div>

  <div class="ddp-box-popupcontents4">
    <div class="ddp-wrap-edit2" [class.ddp-error]="isInvalidName">
      <label class="ddp-label-type">{{ 'msg.comm.ui.name' | translate }}</label>
      <input
        type="text"
        class="ddp-input-type"
        placeholder="{{ 'msg.comm.ui.create.name' | translate }}"
        (ngModelChange)="nameChange($event)"
        [ngModel]="shareWorkspace.name"
        maxlength="50"
      />
      <span class="ddp-ui-error">{{ errMsgName }}</span>
    </div>

    <div class="ddp-wrap-edit2" [class.ddp-error]="isInvalidDesc">
      <label class="ddp-label-type">{{ 'msg.comm.ui.description' | translate }}</label>
      <input
        type="text"
        class="ddp-input-type"
        placeholder="{{ 'msg.comm.ui.create.desc' | translate }}"
        [(ngModel)]="shareWorkspace.description"
        (focus)="isInvalidDesc = false"
        maxlength="150"
      />
      <span class="ddp-ui-error">{{ errMsgDesc }}</span>
    </div>

    <div class="ddp-wrap-edit2">
      <label class="ddp-label-type">Permission schema</label>
      <ul class="ddp-radio-inline">
        <li>
          <label *ngIf="isPermissionManager()" (click)="changeRoleSetType(false)" class="ddp-label-radio">
            <input name="roleSetType" type="radio" [checked]="!isUseCustomRoleSet" />
            <i class="ddp-icon-radio"></i>
            <span class="ddp-txt-radio">{{ 'msg.space.ui.use-schema-preset' | translate }}</span>
          </label>

          <div
            *ngIf="!isUseCustomRoleSet"
            (click)="openPredefinedRoleSetOpts()"
            [ngClass]="{ 'ddp-selected': isShowPredefinedRoleSetList, 'ddp-disabled': isUseCustomRoleSet }"
            class="ddp-type-selectbox"
          >
            <span class="ddp-txt-selectbox">
              {{
                false === isUseCustomRoleSet && selectedRoleSetInfo ? selectedRoleSetInfo.name : 'Load a schema preset'
              }}
            </span>
            <ul class="ddp-list-selectbox">
              <li *ngFor="let item of roleSetList" (click)="selectRoleSet(item, false)">
                <a href="javascript:">{{ item.name }}</a>
              </li>
            </ul>
          </div>
        </li>
        <li *ngIf="isPermissionManager()">
          <label (click)="changeRoleSetType(true)" class="ddp-label-radio">
            <input name="roleSetType" type="radio" [checked]="isUseCustomRoleSet" />
            <i class="ddp-icon-radio"></i>
            <span class="ddp-txt-radio">{{ 'msg.space.ui.use-custom-schema' | translate }}</span>
          </label>
        </li>
      </ul>
    </div>

    <div class="ddp-wrap-edit2">
      <label class="ddp-label-type">
        {{ 'msg.spaces.permission.schema.userroles' | translate }}
        <a
          *ngIf="isUseCustomRoleSet"
          (click)="onClickOpenPermissionSchemaSet()"
          href="javascript:"
          class="ddp-link-edit"
        >
          <em class="ddp-icon-edit"></em>
          {{ 'msg.comm.ui.edit' | translate }}
        </a>
      </label>
      <div *ngIf="!selectedRoleSetDetail" class="ddp-box-datanone">
        {{ 'msg.permission.ui.please-select-schema' | translate }}
      </div>

      <app-permission-schema
        *ngIf="selectedRoleSetDetail"
        [roleSet]="selectedRoleSetDetail"
        [editMode]="false"
      ></app-permission-schema>
    </div>
  </div>

  <div class="ddp-ui-buttons">
    <a href="javascript:" class="ddp-btn-type-popup" (click)="close()">{{ 'msg.comm.btn.cancl' | translate }}</a>
    <a href="javascript:" class="ddp-btn-type-popup ddp-bg-black" (click)="createShareWorkspace()">
      {{ 'msg.comm.btn.done' | translate }}
    </a>
  </div>
</div>

<app-permission-schema-set (afterUpdate)="afterUpdatePermissionRoles($event)"></app-permission-schema-set>
