import { isNullOrUndefined } from 'util';

import { Component, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core';

import * as _ from 'lodash';

import {
  DatasourceField as Field,
  FieldRole,
  TimeFilter,
  Filter,
  TimeRelativeFilter,
  TimeRangeFilter,
  TimeListFilter,
  ByTimeUnit,
  TimeUnit,
} from '@selfai-platform/bi-domain';

import { CommonConstant } from '../../../common/constant/common.constant';
import { SubscribeArg } from '../../../common/domain/subscribe-arg';
import { PopupService } from '../../../common/service/popup.service';
import { FilterUtil } from '../../util/filter.util';
import { AbstractFilterPanelComponent } from '../abstract-filter-panel.component';
import { TimeUnitSelectResult } from '../component/timeUnit-select.component';

import { TimeListFilterComponent } from './time-list-filter.component';
import { TimeRangeFilterComponent } from './time-range-filter.component';
import { TimeRelativeFilterComponent } from './time-relative-filter.component';

@Component({
  selector: 'time-filter-panel',
  templateUrl: './time-filter-panel.component.html',
  styleUrls: ['./time-filter-panel.component.scss'],
})
export class TimeFilterPanelComponent extends AbstractFilterPanelComponent implements OnInit {
  @ViewChild(TimeListFilterComponent)
  private _candidateComp: TimeListFilterComponent;

  @ViewChild(TimeRelativeFilterComponent)
  private _relativeComp: TimeRelativeFilterComponent;

  @ViewChild(TimeRangeFilterComponent)
  private _rangeComp: TimeRangeFilterComponent;

  @ViewChild(TimeListFilterComponent)
  private _listComp: TimeListFilterComponent;

  private _tempRelativeFilter: TimeRelativeFilter;
  private _tempRangeFilter: TimeRangeFilter;
  private _tempListFilter: TimeListFilter;

  public filter: TimeFilter;

  public isTimeStamp = false;

  public isContinuousByAll = false;
  public isDiscontinuousFilter = false;
  public isAllType = false;
  public isRelativeType = false;
  public isRangeType = false;
  public isListType = false;
  public isNewFilter = false;

  public dpContinuousList: string[] = ['Second', 'Minute', 'Hour', 'Day', 'Week', 'Month', 'Year', 'None'];
  public dpDiscontinuousList: any[] = [
    { name: 'Day by week', unit: 'DAY', byUnit: 'WEEK' },
    { name: 'Day by month', unit: 'DAY', byUnit: 'MONTH' },
    { name: 'Day by year', unit: 'DAY', byUnit: 'YEAR' },
    { name: 'Week by month', unit: 'WEEK', byUnit: 'MONTH' },
    { name: 'Week by year', unit: 'WEEK', byUnit: 'YEAR' },
    { name: 'Month by year', unit: 'MONTH', byUnit: 'YEAR' },
    { name: 'Year', unit: 'YEAR' },
  ];

  @Input('filter')
  public originalFilter: TimeFilter;

  constructor(private popupService: PopupService, protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngAfterViewInit() {
    super.ngAfterViewInit();

    this._initialize(this.originalFilter);

    if (this.originalFilter['isNew']) {
      this.isNewFilter = true;
      this.safelyDetectChanges();
      delete this.originalFilter['isNew'];
      setTimeout(() => {
        this.isNewFilter = false;
        this.safelyDetectChanges();
      }, 1500);
    }

    const popupSubscribe = this.popupService.filterView$.subscribe((data: SubscribeArg) => {
      if (data.type === 'page' && this.isDashboardMode) return;

      if (
        'change-filter' === data.name &&
        this.filter.field === data.data.field &&
        this.filter.dataSource === data.data.dataSource
      ) {
        this._initialize(data.data);
      }
    });
    this.subscriptions.push(popupSubscribe);
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public changeFilterEvent(filter: TimeFilter) {
    if (isNullOrUndefined(filter) || !FilterUtil.isTimeFilter(filter)) {
      return;
    }

    this._updateFilter(filter);
  }

  public resetFilter() {
    const filter: TimeFilter = _.cloneDeep(this.originalFilter);
    switch (filter.type) {
      case 'time_list':
        this._candidateComp.setData(<TimeListFilter>filter);
        break;
      case 'time_relative':
        this._relativeComp.setData(<TimeRelativeFilter>filter);
        break;
      case 'time_range':
        this._rangeComp.setData(<TimeRangeFilter>filter);
        break;
    }
    this.filter = filter;
    this.safelyDetectChanges();
    this._updateFilter(filter);
  }

  public toggleDetailMenu() {
    this.isShowDetailMenu = !this.isShowDetailMenu;
  }

  public setTimeRangeFilter() {
    if (this.isDashboardMode) {
      let cloneFilter = JSON.parse(JSON.stringify(this.filter));
      if (this._tempRangeFilter) {
        cloneFilter = this._tempRangeFilter;
      } else {
        cloneFilter = FilterUtil.getTimeRangeFilter(
          cloneFilter.clzField,
          cloneFilter.timeUnit,
          cloneFilter.ui.importanceType,
          this.dataSource,
        );
      }
      this._updateFilter(cloneFilter);
    } else {
      if (this._tempRangeFilter) {
        this.filter = this._tempRangeFilter;
      } else {
        this.filter = FilterUtil.getTimeRangeFilter(
          this.filter.clzField,
          this.filter.timeUnit,
          this.filter.ui.importanceType,
        );
      }
      this.originalFilter.ui.widgetId && (this.filter.ui.widgetId = this.originalFilter.ui.widgetId);
      this.originalFilter = _.cloneDeep(this.filter);
      this._setStatus();
    }
  }

  public setTimeRelativeFilter() {
    if (this.isDashboardMode) {
      let cloneFilter = JSON.parse(JSON.stringify(this.filter));
      if (this._tempRelativeFilter) {
        cloneFilter = this._tempRelativeFilter;
      } else {
        cloneFilter = FilterUtil.getTimeRelativeFilter(
          cloneFilter.clzField,
          cloneFilter.timeUnit,
          cloneFilter.ui.importanceType,
        );
      }
      this._updateFilter(cloneFilter);
    } else {
      if (this._tempRelativeFilter) {
        this.filter = this._tempRelativeFilter;
      } else {
        this.filter = FilterUtil.getTimeRelativeFilter(
          this.filter.clzField,
          this.filter.timeUnit,
          this.filter.ui.importanceType,
        );
      }
      this.originalFilter.ui.widgetId && (this.filter.ui.widgetId = this.originalFilter.ui.widgetId);
      this.originalFilter = _.cloneDeep(this.filter);
      this._setStatus();
    }
  }

  public setTimeListFilter() {
    if (TimeUnit.NONE === this.filter.timeUnit) {
      this.filter.timeUnit = TimeUnit.SECOND;
    }
    if (this._tempListFilter) {
      this.filter = this._tempListFilter;
    } else {
      this.filter = FilterUtil.getTimeListFilter(
        this.filter.clzField,
        this.filter.discontinuous,
        this.filter.timeUnit,
        this.filter.byTimeUnit,
        this.filter.ui.importanceType,
      );
    }
    this.originalFilter.ui.widgetId && (this.filter.ui.widgetId = this.originalFilter.ui.widgetId);
    this.originalFilter = _.cloneDeep(this.filter);
    this._setStatus();
  }

  public deleteFilter(filter: Filter) {
    this.originalFilter.ui.widgetId && (filter.ui.widgetId = this.originalFilter.ui.widgetId);
    this.deleteFilterEvent.emit(filter);
  }

  public isSelectedContinuous(item: string): boolean {
    return !FilterUtil.isDiscontinuousTimeFilter(this.filter) && this.filter.timeUnit === TimeUnit[item.toUpperCase()];
  }

  public isSelectedDiscontinuous(item: { name: string; unit: string; byUnit: string }): boolean {
    if (FilterUtil.isDiscontinuousTimeFilter(this.filter)) {
      if (item.byUnit) {
        return (
          this.filter.timeUnit === TimeUnit[item.unit.toUpperCase()] &&
          this.filter.byTimeUnit === ByTimeUnit[item.byUnit.toUpperCase()]
        );
      } else {
        return this.filter.timeUnit === TimeUnit[item.unit.toUpperCase()];
      }
    } else {
      return false;
    }
  }

  public selectTimeUnit(discontinuous: boolean = false, unit: string, byUnit?: string) {
    const data = new TimeUnitSelectResult(
      discontinuous,
      TimeUnit[unit.toUpperCase()],
      byUnit ? ByTimeUnit[byUnit.toUpperCase()] : null,
    );

    let currFilter: TimeFilter = _.cloneDeep(this.filter);
    if (TimeUnit.NONE !== data.unit) {
      currFilter = FilterUtil.getTimeListFilter(
        currFilter.clzField,
        data.discontinuous,
        data.unit,
        data.byUnit,
        currFilter.ui.importanceType,
      );
    } else {
      currFilter = FilterUtil.getTimeRangeFilter(
        currFilter.clzField,
        TimeUnit.NONE,
        currFilter.ui.importanceType,
        this.dataSource,
      );
    }
    this._initialize(currFilter, true);
  }

  public setAbsolute() {
    if (this.isListType) {
      this.setTimeListFilter();
    } else {
      this.setTimeRangeFilter();
    }
  }

  public setRelative() {
    this.filter.timeUnit = TimeUnit.NONE;
    this.setTimeRelativeFilter();
  }

  public get isFrequencyAsc() {
    if (this.isListType) {
      return this.filter['sortTarget'] === 'FREQUENCY' && this.filter['sortType'] === 'ASC';
    } else {
      return false;
    }
  }

  public get isFrequencyDesc() {
    if (this.isListType) {
      return this.filter['sortTarget'] === 'FREQUENCY' && this.filter['sortType'] === 'DESC';
    } else {
      return false;
    }
  }

  public get isAlphnumericAsc() {
    if (this.isListType) {
      return this.filter['sortTarget'] === 'ALPHNUMERIC' && this.filter['sortType'] === 'ASC';
    } else {
      return false;
    }
  }

  public get isAlphnumericDesc() {
    if (this.isListType) {
      return this.filter['sortTarget'] === 'ALPHNUMERIC' && this.filter['sortType'] === 'DESC';
    } else {
      return false;
    }
  }

  public sortList(target: string, type: string) {
    if (this._listComp) {
      this._listComp.sortCandidateValues(this.filter as TimeListFilter, target, type);
    }
  }

  private _updateFilter(filter: TimeFilter) {
    this.originalFilter.ui.widgetId && (filter.ui.widgetId = this.originalFilter.ui.widgetId);

    if (!filter.discontinuous) {
      switch (filter.type) {
        case 'time_relative':
          this._tempRelativeFilter = _.cloneDeep(<TimeRelativeFilter>filter);
          break;
        case 'time_range':
          this._tempRangeFilter = _.cloneDeep(<TimeRangeFilter>filter);
          break;
        case 'time_list':
          this._tempListFilter = _.cloneDeep(<TimeListFilter>filter);
          break;
      }
    }

    this.updateFilterEvent.emit(filter);
  }

  private _initialize(filter: TimeFilter, isTriggerUpdateEvent: boolean = false) {
    this.isShowFilter = false;
    this.safelyDetectChanges();

    this._tempRelativeFilter = null;
    this._tempRangeFilter = null;
    this._tempListFilter = null;

    this.filter = filter;
    this.originalFilter = _.cloneDeep(filter);

    this.setPanelData(filter);

    if (this.dataSource) {
      if (this.field && this.field.type === 'TIMESTAMP' && this.field.role === FieldRole.TIMESTAMP) {
        this.isTimeStamp = true;
      } else if (this.field == null && filter.field === CommonConstant.COL_NAME_CURRENT_DATETIME) {
        this.isTimeStamp = true;
      }

      this._setStatus();

      this.isShowFilter = true;

      if (isTriggerUpdateEvent) {
        this.updateFilterEvent.emit(filter);
      }
    }

    this.safelyDetectChanges();
  }

  private _setStatus() {
    this.isContinuousByAll = FilterUtil.isContinuousByAll(this.filter);
    this.isDiscontinuousFilter = FilterUtil.isDiscontinuousTimeFilter(this.filter);
    this.isAllType = FilterUtil.isTimeAllFilter(this.filter);
    this.isRelativeType = FilterUtil.isTimeRelativeFilter(this.filter);
    this.isRangeType = FilterUtil.isTimeRangeFilter(this.filter);
    this.isListType = FilterUtil.isTimeListFilter(this.filter);
    this.safelyDetectChanges();
  }
}
