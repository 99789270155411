import { isNullOrUndefined } from 'util';

import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import each from 'lodash/each';
import eq from 'lodash/eq';
import extend from 'lodash/extend';
import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';
import indexOf from 'lodash/indexOf';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';
import merge from 'lodash/merge';
import remove from 'lodash/remove';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { DragulaService } from 'ng2-dragula';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import {
  BarChartComponent,
  GridChartComponent,
  LineChartComponent,
  OptionGenerator,
} from '@selfai-platform/bi-chart-engine';
import {
  AggregationType,
  BarMarkType,
  BoardConfiguration,
  ChartColorType,
  ChartType,
  ConnectionType,
  CustomField,
  DIRECTION,
  Dashboard,
  DashboardDomainService,
  Datasource,
  DatasourceField,
  EventType,
  ExpressionField,
  FieldPivot,
  FieldRole,
  Filter,
  Format,
  LogicalType,
  MapLayerType,
  MeasureField,
  PageWidget,
  PageWidgetConfiguration,
  Pivot,
  PivotField,
  SPEC_VERSION,
  SearchQueryRequest,
  Shelf,
  ShelfLayers,
  ShelveFieldType,
  Sort,
  UIMapOption,
  UIOption,
  createDatasourceField,
  createPageWidget,
  createPageWidgetConfiguration,
  createPivot,
  createShelf,
  createSort,
} from '@selfai-platform/bi-domain';
import { DestroyService } from '@selfai-platform/shared';

import { AbstractPopupComponent } from '../common/component/abstract-popup.component';
import { ConfirmModalComponent } from '../common/component/modal/confirm/confirm.component';
import { Modal } from '../common/domain/modal';
import { ImageService } from '../common/service/image.service';
import { PopupService } from '../common/service/popup.service';
import { CommonUtil } from '../common/util/common.util';
import { StringUtil } from '../common/util/string.util';
import { ChartLimitInfo, convertPageWidgetSpecToServer, getMainDataSources } from '../dashboard';
import { ConfigureFiltersComponent } from '../dashboard/filters/configure-filters.component';
import { WidgetService } from '../dashboard/service';
import { DashboardApiService } from '../dashboard/service/dashboard-api.service';
import { DashboardUtil } from '../dashboard/util/dashboard.util';
import { FilterUtil } from '../dashboard/util/filter.util';
import { DatasourceService } from '../datasource/service/datasource.service';

import { AnalysisComponent } from './component/analysis/analysis.component';
import { AnalysisPredictionService } from './component/analysis/service/analysis.prediction.service';
import { PageSectionContentComponent } from './components/page-section-content/page-section-content.component';
import { PageSectionSideComponent } from './components/page-section-side/page-section-side.component';
import { POSSIBLE_CHART_OBJECT } from './consts';
import { PagePivotComponent } from './page-pivot/page-pivot.component';

@Component({
  selector: 'app-page',
  templateUrl: 'page.component.html',
  styleUrls: ['./page.component.scss'],
  providers: [DestroyService],
})
export class PageComponent extends AbstractPopupComponent implements OnInit, OnDestroy {
  @ViewChild(ConfirmModalComponent, { static: true })
  private confirmModalComponent: ConfirmModalComponent;

  @ViewChild('sectionContent')
  private sectionContent: PageSectionContentComponent;

  @ViewChild('sectionSide')
  private sectionSide: PageSectionSideComponent;

  @ViewChild(AnalysisComponent, { static: true })
  private analysisComponent?: AnalysisComponent;

  @ViewChild(ConfigureFiltersComponent, { static: true })
  private _configFilterComp: ConfigureFiltersComponent;

  @Input('widget')
  set setWidget(widget: PageWidget) {
    this.#originalWidget = widget;
    this.widget = cloneDeep(widget);
  }

  @Input('dashboard')
  set setIsDashboard(isDashboard: boolean) {
    this.isDashboard = isDashboard;
  }

  @Output('changeFieldAlias')
  private changeFieldAliasEvent: EventEmitter<DatasourceField> = new EventEmitter();

  eventSubject: Subject<any> = new Subject<any>();
  loadingPage = false;
  isChartView = true;
  dataSource: Datasource;
  recommendCharts: string[] = [];
  pageDimensions: DatasourceField[] = [];
  pageMeasures: DatasourceField[] = [];
  dimensionPage = 1;
  dimensionTotalPage = 1;
  measurePage = 1;
  measureTotalPage = 1;
  widget: PageWidget;
  isDashboard = true;
  isPageNameEdit = false;
  editingPageName: string;
  isNoData = false;
  isError = false;
  limitInfo: ChartLimitInfo = {
    id: '',
    isShow: false,
    currentCnt: 0,
    maxCnt: 0,
  };
  isSankeyNotAllNode = false;
  dataSourceList: Datasource[] = [];
  boardFilters: Filter[] = [];
  geoType: LogicalType;
  modifyDataVariable: any;
  isChartShow: boolean;
  nav = {
    chart: false,
    data: false,
  };
  dataLayerKey: string;
  isDataDimensionLayerShow: boolean;
  isDataMeasureLayerShow: boolean;
  fieldSearchText = '';
  rnbMenu = 'filter';
  isShowCustomFiled = false;
  isShowDataDetail = false;
  dashboard: Dashboard;
  fields: DatasourceField[] = [];
  columnType: string;
  selectedCustomField: ExpressionField;
  selectedField: DatasourceField;
  isColumnDetail = false;
  resultData: any;
  fieldDetailLayer: DatasourceField;
  showFieldIconsFl = false;

  #isVersionCheck = false;
  #selectChartSource: Subject<Object> = new Subject<Object>();
  #customMeasures: ExpressionField[];
  #measures: DatasourceField[];
  #dimensions: DatasourceField[];
  #customDimensions: ExpressionField[];
  #MAX_PAGE_COUNT = 30;
  #originalWidget: PageWidget;
  #query: SearchQueryRequest;

  readonly #translateService = inject(TranslateService);

  get widgetConfiguration(): PageWidgetConfiguration {
    return <PageWidgetConfiguration>this.widget.configuration;
  }

  get selectChart(): string {
    return this.widgetConfiguration?.chart.type ? this.widgetConfiguration.chart.type.toString().toLowerCase() : '';
  }

  set selectChart(chartType: string) {
    if (this.selectChart === chartType || !this.widgetConfiguration?.chart) {
      return;
    }

    this.widgetConfiguration.chart.type = ChartType[chartType.toUpperCase()] as ChartType;

    const deepCopyUiOption = cloneDeep(this.uiOption);

    this.uiOption = OptionGenerator.initUiOption(this.uiOption);

    this.uiOption.minValue = deepCopyUiOption.minValue;
    this.uiOption.maxValue = deepCopyUiOption.maxValue;

    this.changeDetect.detectChanges();

    if ('map' === chartType) {
      this.shelf = this.#convertPivotToShelf(this.shelf);

      this.geoType = this.#getMapGeoType();

      this.#setDefaultAreaForBBox(this.dataSource);
    } else {
      this.pivot = this.#convertShelfToPivot(this.pivot, deepCopyUiOption);
    }

    this.changeDetect.detectChanges();

    this.#getPivotComp().onChangePivotPosition(chartType);

    this.#recommendChart();

    this.#selectChartSource.next({
      chartType: chartType,
      type: EventType.CHART_TYPE,
    });

    this.#drawChart();
  }

  get pivot(): Pivot {
    if (this.widgetConfiguration.pivot === undefined) {
      return createPivot();
    }
    return this.widgetConfiguration.pivot;
  }

  set pivot(pivot: Pivot) {
    this.widgetConfiguration.pivot = pivot;
  }

  get shelf(): Shelf {
    if (this.widgetConfiguration.shelf === undefined) {
      return createShelf();
    }
    return this.widgetConfiguration.shelf;
  }

  set shelf(shelf: Shelf) {
    this.widgetConfiguration.shelf = shelf;
  }

  get uiOption(): UIOption {
    return this.widgetConfiguration.chart;
  }

  set uiOption(uiOption: UIOption) {
    this.widgetConfiguration.chart = uiOption;
  }

  get sorts(): Sort[] {
    return this.widgetConfiguration.limit.sort;
  }

  set sorts(sorts: Sort[]) {
    this.widgetConfiguration.limit.sort = sorts;
  }

  /**
   * Used syncDatasourceAliasInWidgetPivot
   */
  static updatePivotAliasFromField(pivot: Pivot, field: DatasourceField) {
    pivot.columns.forEach((col) => {
      if (col.name === field.name) {
        (col.fieldAlias === col.alias || col.name === col.alias) && (col.alias = field.nameAlias.nameAlias);
        col.fieldAlias = field.nameAlias.nameAlias;
        col.field = merge(col.field, field);
        return true;
      }
    });
    pivot.rows.forEach((row) => {
      if (row.name === field.name) {
        (row.fieldAlias === row.alias || row.name === row.alias) && (row.alias = field.nameAlias.nameAlias);
        row.fieldAlias = field.nameAlias.nameAlias;
        row.field = merge(row.field, field);
        return true;
      }
    });
    pivot.aggregations.forEach((aggr) => {
      if (aggr.name === field.name) {
        (aggr.fieldAlias === aggr.alias || aggr.name === aggr.alias) && (aggr.alias = field.nameAlias.nameAlias);
        aggr.fieldAlias = field.nameAlias.nameAlias;
        aggr.field = merge(aggr.field, field);
        return true;
      }
    });
  }

  /**
   * used syncDatasourceAliasInWidgetPivot
   */
  static updateShelfAliasFromField(shelf: Shelf, field: DatasourceField, layerNum: number) {
    shelf.layers[layerNum].fields.forEach((layer) => {
      if (layer.name === field.name) {
        (layer.fieldAlias === layer.alias || layer.name === layer.alias) && (layer.alias = field.nameAlias.nameAlias);
        layer.fieldAlias = field.nameAlias.nameAlias;
        layer.field = merge(layer.field, field);
        return true;
      }
    });
  }

  constructor(
    protected elementRef: ElementRef,
    protected injector: Injector,
    private popupService: PopupService,
    private dragulaService: DragulaService,
    private datasourceService: DatasourceService,
    private analysisPredictionService: AnalysisPredictionService,
    private readonly destroy$: DestroyService,
    private readonly dashboardDomainService: DashboardDomainService,
    private readonly widgetService: WidgetService,
    private readonly dashboardService: DashboardApiService,
    private readonly imageService: ImageService,
  ) {
    super(elementRef, injector);
  }

  ngOnInit() {
    super.ngOnInit();

    this.#init();
    this.#settingDragAndDrop();

    this.#getDashboard();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.dragulaService.destroy('dragbag');
  }

  setSelectDChart(value) {
    this.widgetConfiguration.chart.type = value;
  }

  setUiOptions() {
    return OptionGenerator.initUiOption(this.uiOption);
  }

  emitAggregationValueToChild() {
    this.eventSubject.next(null);
  }

  setSelectNav(select) {
    if (select === 'data') {
      if (this.nav.data === true) {
        this.nav.data = false;
        this.nav.chart = false;
        // this.navWidthContChart = "0px";
        // this.navWidth = "100% + 135px";
      } else {
        this.nav.data = true;
        this.nav.chart = false;
      }
    }
    if (select === 'chart') {
      if (this.nav.chart === true) {
        this.nav.data = false;
        this.nav.chart = false;
        // this.navWidthContChart = "0px";
        // this.navWidth = "100% + 135px";
      } else {
        this.nav.data = false;
        this.nav.chart = true;
      }
      // this.getNavWidth();
    }
  }

  getSelectNav() {
    return this.nav;
  }

  selectDataSource(dataSource: Datasource, isBBoxChange: boolean) {
    this.widget || (this.widget = cloneDeep(this.#originalWidget));

    if (ChartType.MAP === this.widget.configuration.chart.type) {
      this.boardFilters = this.dashboard.configuration.filters;
      this.dataSource = dataSource;

      isBBoxChange && this.#setDefaultAreaForBBox(dataSource);

      this.#setDatasourceFields(true);

      this.widget.configuration.dataSource = this.dashboard.configuration.dataSource;

      this.#setDataSourceCurrentLayer(dataSource);

      this.geoType = this.#getMapGeoType();
    } else {
      this.isChartShow = false;
      this.dataSource = dataSource;
      let widgetName: string = null;
      if (this.widget && this.widget.name) {
        widgetName = this.widget.name;
      }
      this.widget = cloneDeep(this.#originalWidget);
      this.widget.name = !widgetName ? this.#originalWidget.name : widgetName;
      const widgetDataSource: Datasource = DashboardUtil.getDataSourceFromBoardDataSource(
        this.dashboard,
        this.widget.configuration.dataSource,
      );

      if (widgetDataSource.id !== dataSource.id) {
        this.widget.configuration = createPageWidgetConfiguration({
          dataSource: DashboardUtil.findDataSourceOnBoard(this.dashboard, dataSource),
          filters: [],
          customFields: [],
        });
      }

      if (ConnectionType.LINK === this.dataSource.connType) {
        this.boardFilters = DashboardUtil.getAllFiltersDsRelations(
          this.dashboard,
          isNullOrUndefined(this.dataSource.engineName) ? this.dataSource.name : this.dataSource.engineName,
        );
      } else {
        this.boardFilters = DashboardUtil.getAllFiltersDsRelations(
          this.dashboard,
          isNullOrUndefined(this.widget.configuration.dataSource.engineName)
            ? this.widget.configuration.dataSource.name
            : this.widget.configuration.dataSource.engineName,
        );
      }

      if (StringUtil.isEmpty(this.widget.name)) {
        this.widget.name = this.translateService.instant('msg.board.chart.newDiagram');
      }
      this.uiOption = this.widgetConfiguration.chart;
      // this.originalWidgetConfiguration = cloneDeep(this.widgetConfiguration);

      this.#setDatasourceFields(true);

      if (this.sectionContent?.pagePivot) this.sectionContent?.pagePivot.removeAnimation();
    }
  }

  #uploadChartImage(widgetId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const chart = this.$element.find('.ddp-ui-chart-area');

      if (0 === chart.length) {
        reject('not found chart');
      }
      chart.css('background-color', '#FFF');

      this.imageService
        .getBlob(chart)
        .then((blob) => {
          this.imageService
            .uploadImage(this.widget.name, blob, widgetId, 'page', 250)
            .then((response) => {
              resolve(response);
            })
            .catch((err) => reject(err));
        })
        .catch((err) => reject(err));
    });
  }

  save() {
    if (!this.sectionContent?.chart || !this.isChartShow) {
      return;
    }
    this.loadingShow();

    if (
      eq(this.selectChart, ChartType.MAP) &&
      this.uiOption['layers'] &&
      this.uiOption['layers'][this.uiOption['layerNum']]['type'] == MapLayerType.CLUSTER &&
      !isUndefined(this.uiOption['layers'][this.uiOption['layerNum']]['clustering']) &&
      this.uiOption['layers'][this.uiOption['layerNum']]['clustering']
    ) {
      this.uiOption['layers'][this.uiOption['layerNum']]['type'] = MapLayerType.SYMBOL;
    }

    if (this.#isNewWidget()) {
      if (StringUtil.isEmpty(this.widget.name)) {
        this.widget.name = this.translateService.instant('msg.board.chart.newDiagram');
      }

      let param;

      if (eq(this.selectChart, ChartType.MAP)) {
        param = extend({}, this.widget, { shelf: this.shelf });
      } else {
        param = extend({}, this.widget, { pivot: this.pivot });
      }

      delete param.configuration.pivot.fields;

      param.configuration = convertPageWidgetSpecToServer(param.configuration);

      const pageConf: PageWidgetConfiguration = param.configuration as PageWidgetConfiguration;

      if (!isEmpty(this.sectionContent?.chart.saveDataZoomRange()))
        pageConf.chart.chartZooms = this.sectionContent?.chart.saveDataZoomRange();

      pageConf.chart.version = 2;

      this.widgetService
        .createWidget(param, this.dashboard.id)
        .then((widget) => {
          const pageWidget: PageWidget = extend(
            createPageWidget({
              dashBoardId: this.dashboard.id,
              name: this.#translateService.instant('msg.board.chart.newDiagram'),
            }),
            widget,
          );

          pageWidget.configuration.filters.forEach((filter) => {
            filter.ui ||
              (filter.ui = {
                widgetId: pageWidget.id,
                importanceType: 'general',
              });
          });

          this.#uploadChartImage(pageWidget.id)
            .then((res) => {
              const configuration = {
                configuration: pageWidget.configuration,
                imageUrl: res['imageUrl'],
              };
              this.widgetService.updateWidget(pageWidget.id, configuration).then(() => {
                this.popupService.notiPopup({
                  name: 'create-page-complete',
                  data: pageWidget,
                });
              });
            })
            .catch((err) => {
              this.popupService.notiPopup({
                name: 'create-page-complete',
                data: pageWidget,
              });
              console.error(err);
            });
        })
        .catch((err) => {
          this.loadingHide();
          console.error(err);
        });
    } else {
      const param = {
        configuration: this.widgetConfiguration,
        name: this.widget.name,
      };

      param.configuration = convertPageWidgetSpecToServer(param.configuration);

      const pageConf: PageWidgetConfiguration = param.configuration as PageWidgetConfiguration;

      if (!isEmpty(this.sectionContent?.chart.saveDataZoomRange()))
        pageConf.chart.chartZooms = this.sectionContent?.chart.saveDataZoomRange();

      pageConf.chart.version = 2;
      this.widgetService
        .updateWidget(this.widget.id, param)
        .then((widget) => {
          this.dashboardService
            .updateDashboard(this.dashboard.id, {
              configuration: this.dashboard.configuration,
            })
            .then(() => {
              const pageWidget: PageWidget = extend(
                createPageWidget({
                  dashBoardId: this.dashboard.id,
                  name: this.#translateService.instant('msg.board.chart.newDiagram'),
                }),
                widget,
              );

              pageWidget.configuration.filters.forEach((filter) => {
                filter.ui ||
                  (filter.ui = {
                    importanceType: 'general',
                    widgetId: pageWidget.id,
                  });
              });

              this.#uploadChartImage(pageWidget.id)
                .then((res) => {
                  const imageUrl = res['imageUrl'];
                  this.widgetService.updateWidget(pageWidget.id, { imageUrl }).then(() => {
                    this.popupService.notiPopup({
                      name: 'modify-page-complete',
                      data: pageWidget,
                    });
                  });
                })
                .catch((err) => {
                  this.popupService.notiPopup({
                    name: 'modify-page-complete',
                    data: pageWidget,
                  });
                  console.error(err);
                });
            })
            .catch((err) => {
              this.loadingHide();
              console.error(err);
            });
        })
        .catch((err) => {
          this.loadingHide();
          console.error(err);
        });
    }
  }

  close() {
    super.close();

    this.popupService.notiPopup({
      name: 'modify-page-close',
      data: {
        widget: this.#originalWidget,
        isNew: this.#isNewWidget(),
      },
    });
  }

  openCustomFieldPopup(customField?: ExpressionField, columnType?: string) {
    this.columnType = columnType;

    if (customField) {
      this.selectedCustomField = customField;
    } else {
      this.selectedCustomField = null;
    }

    this.isShowCustomFiled = true;
  }

  onColumnDetailPopup(field: DatasourceField): void {
    this.selectedField = field;
    this.isColumnDetail = true;
    this.isShowDataDetail = true;
  }

  onDataPreviewPopup(): void {
    this.selectedField = null;
    this.isColumnDetail = false;
    this.isShowDataDetail = true;
  }

  toggleRnb(rnbMenu: string) {
    if (!this.isChartShow && !['filter', 'common'].includes(rnbMenu)) {
      return;
    }

    if (this.rnbMenu === rnbMenu) {
      this.rnbMenu = '';
    } else {
      this.rnbMenu = rnbMenu;
    }

    this.changeDetect.detectChanges();

    if (this.#getPivotComp()) this.#getPivotComp().onShelveAnimation(this.$element.find('.ddp-wrap-default'));
    if (this.selectChart == 'map' || this.rnbMenu == '') this.#chartResize();
  }

  showDataZoom(show: boolean): void {
    const chartZooms = cloneDeep(this.uiOption.chartZooms);
    chartZooms.forEach((zoom) => {
      zoom.auto = show;
    });

    this.uiOption = <UIOption>extend({}, this.uiOption, { chartZooms });
  }

  onNameChange($event): void {
    this.isPageNameEdit = false;
    this.editingPageName = $event;

    if (StringUtil.isEmpty(this.editingPageName.trim())) {
      this.alertPrimeService.info(this.translateService.instant('msg.page.alert.insert.chart.name'));
      return;
    }

    this.widget.name = this.editingPageName.trim();
  }

  onNameEdit($event: Event): void {
    $event.stopPropagation();
    this.isPageNameEdit = !this.isPageNameEdit;
    this.editingPageName = this.widget.name;
    this.changeDetect.detectChanges();
  }

  onFormatCommonChange(format: Format): void {
    this.widgetConfiguration.format = format;
  }

  deleteCustomField(field: DatasourceField) {
    const useChartList: string[] = [];
    const useFilterList: string[] = [];

    let chartFilters: Filter[] = [];

    const widgets = this.dashboard.widgets;

    if (widgets && widgets.length > 0) {
      let customFields: CustomField[];
      if (this.dashboard.configuration.hasOwnProperty('customFields')) {
        customFields = this.dashboard.configuration.customFields;
      } else {
        customFields = [];
      }

      widgets.forEach((widget: PageWidget) => {
        if (this.widget.id !== widget.id) {
          if (
            widget.configuration &&
            widget.configuration.hasOwnProperty('pivot') &&
            widget.configuration['pivot'].hasOwnProperty('columns')
          ) {
            const idx = findIndex(widget.configuration['pivot']['columns'], {
              name: field.name,
            });
            if (idx > -1) useChartList.push(widget.name);
          }

          if (
            widget.configuration &&
            widget.configuration.hasOwnProperty('pivot') &&
            widget.configuration['pivot'].hasOwnProperty('aggregations')
          ) {
            const idx = findIndex(widget.configuration['pivot']['aggregations'], { name: field.name });
            if (idx > -1) useChartList.push(widget.name);
          }

          if (
            widget.configuration &&
            widget.configuration.hasOwnProperty('pivot') &&
            widget.configuration['pivot'].hasOwnProperty('rows')
          ) {
            const idx = findIndex(widget.configuration['pivot']['rows'], {
              name: field.name,
            });
            if (idx > -1) useChartList.push(widget.name);
          }

          if (widget.configuration.hasOwnProperty('filters') && widget.configuration.filters.length > 0) {
            widget.configuration.filters.forEach((filter: Filter) => {
              filter.ui.widgetId = widget.id;
              chartFilters.push(filter);
            });
          }
        }
      });
    }

    if (this.widget.configuration.filters) {
      chartFilters = chartFilters.concat(this.widget.configuration.filters);
    }

    if (
      this.widget.configuration &&
      this.widget.configuration.hasOwnProperty('pivot') &&
      this.widget.configuration['pivot'].hasOwnProperty('columns')
    ) {
      const idx = findIndex(this.widget.configuration['pivot']['columns'], {
        name: field.name,
      });
      if (idx > -1) useChartList.push(this.widget.name);
    }

    if (
      this.widget.configuration &&
      this.widget.configuration.hasOwnProperty('pivot') &&
      this.widget.configuration['pivot'].hasOwnProperty('aggregations')
    ) {
      const idx = findIndex(this.widget.configuration['pivot']['aggregations'], { name: field.name });
      if (idx > -1) useChartList.push(this.widget.name);
    }

    if (
      this.widget.configuration &&
      this.widget.configuration.hasOwnProperty('pivot') &&
      this.widget.configuration['pivot'].hasOwnProperty('rows')
    ) {
      const idx = findIndex(this.widget.configuration['pivot']['rows'], {
        name: field.name,
      });
      if (idx > -1) useChartList.push(this.widget.name);
    }

    let idx = findIndex(chartFilters, { field: field.name });
    if (idx > -1) useFilterList.push(chartFilters[idx].type + '_' + chartFilters[idx].field);

    const globalFilters = this.dashboard.configuration.filters;
    idx = findIndex(globalFilters, { field: field.name });
    if (idx > -1) useFilterList.push(globalFilters[idx].type + '_' + globalFilters[idx].field);

    if (useFilterList.length > 0 || useChartList.length > 0) {
      let description = '';
      if (useFilterList.length > 0 && useChartList.length > 0) {
        description =
          "'" +
          useChartList.join("' , '") +
          "','" +
          useFilterList.join("' , '") +
          "' " +
          this.translateService.instant('msg.board.ui.use.chart.filter');
      } else if (useChartList.length > 0) {
        description = "'" + useChartList.join("' , '") + "' " + this.translateService.instant('msg.board.ui.use.chart');
      } else if (useFilterList.length > 0) {
        description =
          "'" + useFilterList.join("' , '") + "' " + this.translateService.instant('msg.board.ui.use.filter');
      }

      const modal = new Modal();
      modal.name = this.translateService.instant('msg.board.ui.not.delete.custom');
      modal.description = description;
      modal.isShowCancel = false;
      modal.data = {
        type: 'deleteCustomField',
      };
      this.confirmModalComponent.init(modal);
      return;
    }

    if (this.dashboard.configuration && !this.dashboard.configuration.customFields)
      this.dashboard.configuration.customFields = [];
    const customFields = this.dashboard.configuration.customFields;

    idx = findIndex(customFields, { name: field.name });

    if (idx > -1) {
      customFields.splice(idx, 1);

      this.dashboard.configuration.customFields = customFields;
      (<PageWidgetConfiguration>this.widget.configuration).customFields = customFields;

      this.#setDatasourceFields();
    }
  }

  onChangePivotFormat(field: PivotField): void {
    if (eq(this.selectChart, ChartType.MAP)) {
      if (!this.sectionSide.mapFormatOption) {
        this.toggleRnb('mapFormat');
      }

      if (field != null && this.sectionSide.mapFormatOption) {
        this.sectionSide.mapFormatOption.setFormatType(field);
      } else if (field && !this.sectionSide.mapFormatOption) {
        this.alertPrimeService.warn(this.translateService.instant('msg.page.alert.apply.after.chart'));
      }
    } else {
      if (!this.sectionSide.formatOption) {
        this.toggleRnb('format');
      }

      if (field != null && this.sectionSide.formatOption) {
        this.sectionSide.formatOption.setFormatType(field);
      } else if (field && !this.sectionSide.formatOption) {
        this.alertPrimeService.warn(this.translateService.instant('msg.page.alert.apply.after.chart'));
      }
    }
  }

  onFormatEachChange(pivot: any): void {
    if (eq(this.selectChart, ChartType.MAP)) {
      this.shelf = pivot;
    } else {
      this.pivot = pivot;
    }
    delete this.widgetConfiguration.format;
  }

  onSetDrawChartParam(drawChartParam) {
    this.#drawChart({
      resultFormatOptions: drawChartParam.resultFormatOptions,
      type: drawChartParam.type,
    });
  }

  drawChartTooltip(event): void {
    if (!this.isChartShow) {
      const offsetTop = event.target.offsetTop;
      $(event.target)
        .find('.ddp-ui-tooltip-info')
        .css('top', offsetTop + 106);
    }

    if (this.uiOption['analysis'] != null && this.uiOption['analysis']['use'] == true) {
      $('.ddp-wrap-chart-menu a').mouseover(function () {
        const $tooltipTop = $(this).offset().top;
        $(this)
          .find('.ddp-ui-tooltip-info')
          .css('top', $tooltipTop + 15);
      });
    }
  }

  openUpdateFilterPopup(filter?: Filter) {
    this._configFilterComp.open(this.dashboard, this.widget.configuration.filters, filter, this.widget);
  }

  closeFilterPopup() {
    this._configFilterComp.close();
  }

  updateFilter(filter: Filter, isSetPanel: boolean = false) {
    if (filter.ui.widgetId) {
      this.#setChartFilter(filter, isSetPanel);
    }
    this.#drawChart({ type: EventType.FILTER });
    this.closeFilterPopup();
  }

  configureFilter(filter: Filter) {
    this.updateFilter(filter, true);
  }

  deleteFilter(filter: Filter) {
    if (filter.ui.widgetId) {
      const idx = findIndex(this.widgetConfiguration.filters, {
        field: filter.field,
      });
      if (idx < 0) {
        return;
      } else {
        this.widgetConfiguration.filters.splice(idx, 1);
      }

      this.#setUseFilter();
      this.#drawChart();
    } else {
      const idx = findIndex(this.dashboard.configuration.filters, {
        field: filter.field,
      });
      if (idx < 0) {
        return;
      } else {
        this.dashboard.configuration.filters.splice(idx, 1);
      }

      this.#setUseFilter();
      this.#drawChart();
    }

    if (this.sectionSide._filterPanelComp) {
      this.sectionSide._filterPanelComp.setFilters(
        this.dashboard.configuration.filters,
        this.widget.configuration.filters,
      );
    }
  }

  openFieldDetailLayer(event, field) {
    event.stopPropagation();

    this.fieldDetailLayer = field;

    // this.showFieldIconsFl = this.dataContext.init(
    //   field,
    //   this.dashboard.configuration.dataSource,
    //   $(event.currentTarget)
    // );
  }

  isCustomMeasureField(field: DatasourceField) {
    return FieldRole.MEASURE === field.role && 'user_expr' === field.type;
  }

  toggleFilter(field: DatasourceField, $event?: MouseEvent) {
    $event && $event.stopPropagation();

    if (this.isCustomMeasureField(field)) {
      return;
    }

    if (field.aggregated) {
      this.alertPrimeService.info(this.translateService.instant('msg.page.custom.measure.aggregation.unavailable'));
      return;
    }

    let selectedField: DatasourceField;

    if (field['field']) selectedField = field['field'];
    else selectedField = field;

    this.rnbMenu = 'filter';
    if (selectedField.useFilter) {
      const globalFilters = this.dashboard.configuration.filters;
      let idx = findIndex(globalFilters, { field: selectedField.name });
      if (-1 < idx) {
        this.alertPrimeService.warn(this.translateService.instant('msg.board.alert.global-filter.del.error'));
        return;
      }

      if (selectedField.filtering) {
        this.alertPrimeService.warn(this.translateService.instant('msg.board.alert.recomm-filter.del.error'));
        return;
      }

      selectedField.useFilter = false;

      const chartFilters = this.widgetConfiguration.filters;
      idx = findIndex(chartFilters, { field: selectedField.name });
      if (idx > -1) this.deleteFilter(chartFilters[idx]);

      this.#getPivotComp().setWidgetConfig = this.widgetConfiguration;

      return;
    } else {
      selectedField.useFilter = true;

      let newFilter: Filter;
      if (selectedField.logicalType === LogicalType.TIMESTAMP) {
        const timeFilter = FilterUtil.getTimeRangeFilter(selectedField, undefined, undefined, this.dataSource);

        timeFilter.ui.widgetId = this.widget.id;
        if (this.#isNewWidget()) {
          timeFilter.ui.widgetId = 'NEW';
        }

        newFilter = timeFilter;
      } else if (selectedField.role === FieldRole.MEASURE) {
        const boundFilter = FilterUtil.getBasicBoundFilter(selectedField);

        boundFilter.ui.widgetId = this.widget.id;
        if (this.#isNewWidget()) {
          boundFilter.ui.widgetId = 'NEW';
        }

        if (selectedField.type === 'user_expr') {
          boundFilter.ref = 'user_defined';
        }

        newFilter = boundFilter;
      } else {
        const inclusionFilter = FilterUtil.getBasicInclusionFilter(selectedField);

        inclusionFilter.ui.widgetId = this.widget.id;
        if (this.#isNewWidget()) {
          inclusionFilter.ui.widgetId = 'NEW';
        }

        if (selectedField.type === 'user_expr') {
          inclusionFilter.ref = 'user_defined';
        }

        newFilter = inclusionFilter;
      }
      newFilter.dataSource = this.dataSource.engineName;

      this.updateFilter(newFilter, true);
    }
  }

  openConfirmPopup(filter: Filter, type: string) {
    if ('toChartFilter' === type) {
      const modal = new Modal();
      modal.name = this.translateService.instant('msg.board.filter.alert.change.chart');
      modal.description = this.translateService.instant('msg.board.filter.alert.change.chart.des');
      modal.data = {
        afterConfirm: () => {
          this.#setChartFilter(filter);
        },
      };
      this.confirmModalComponent.init(modal);
    }
  }

  confirm(modal: Modal) {
    if (modal.data.afterConfirm) {
      modal.data.afterConfirm.call(this);
    } else if (modal.data.eventType === EventType.GRID_ORIGINAL) {
      this.sectionSide.commonOption.changeGridViewType(modal.data.data);
    } else if (modal.data.eventType == EventType.SERIES_VIEW) {
      this.sectionSide.commonOption.changeBarSeriesViewType(modal.data.data);
    } else if (modal.data.eventType == EventType.GRANULARITY) {
      this.#getPivotComp().onSetGranularity(
        modal.data.data.discontinuous,
        modal.data.data.unit,
        modal.data.data.byUnit,
      );
    } else if (modal.data.eventType == EventType.CUMULATIVE) {
      this.sectionSide.commonOption.changeCumulative(modal.data.data);
    }
  }

  changeAxisByStack(type: BarMarkType) {
    let checkDimensionExist = false;

    const allRowAggregations = this.pivot.rows.concat(this.pivot.aggregations);

    for (const item of allRowAggregations) {
      if (item.type === String(ShelveFieldType.DIMENSION)) {
        checkDimensionExist = true;
      }
    }

    if (checkDimensionExist) {
      if (String(BarMarkType.STACKED) === String(type)) {
        for (let num = this.pivot.aggregations.length; num--; ) {
          const item = this.pivot.aggregations[num];

          if (item.type === String(ShelveFieldType.DIMENSION)) {
            this.pivot.aggregations.splice(num, 1);

            item.currentPivot = FieldPivot.ROWS;

            this.pivot.rows.push(item);
          }
        }
      } else {
        for (let num = this.pivot.rows.length; num--; ) {
          const item = this.pivot.rows[num];

          if (item.type === String(ShelveFieldType.DIMENSION)) {
            this.pivot.rows.splice(num, 1);

            item.currentPivot = FieldPivot.AGGREGATIONS;

            this.pivot.aggregations.push(item);
          }
        }
      }
    }
  }

  isAvaliableGrid() {
    const notAvaliableChart = ['grid', 'scatter', 'pie'];
    return notAvaliableChart.indexOf(this.selectChart) === -1;
  }

  onChangePivotItem(data: Object) {
    const item: PivotField = data['data'];
    const measureTargetList: PivotField[] = data['list'];
    const addTargetType: FieldPivot = data['addType'];
    const deleteTargetType: FieldPivot = data['deleteType'];

    if (String(ShelveFieldType.DIMENSION) === item.type || String(ShelveFieldType.TIMESTAMP) === item.type) {
      item.field.pivot.splice(item.field.pivot.indexOf(deleteTargetType), 1);
      item.field.pivot.push(addTargetType);
    } else if (String(ShelveFieldType.MEASURE) === item.type) {
      const existIndex = findIndex(measureTargetList, (aggItem) => item.name === aggItem.name);

      if (-1 !== existIndex) {
        const field = item.field;

        const measureIndex = findIndex(this.#measures, (measureItem) => {
          return (
            field.alias === measureItem.alias && field.name === measureItem.name && field.type === measureItem.type
          );
        });

        item.field.pivot.splice(item.field.pivot.indexOf(deleteTargetType), 1);

        if (-1 !== measureIndex && addTargetType) {
          this.#measures[measureIndex].pivot.push(addTargetType);
        }
      } else {
        item.field.pivot.splice(item.field.pivot.indexOf(deleteTargetType), 1);
      }
    }
  }

  onDeletePivotItem(data: Object) {
    const item: PivotField = data['data'];
    const addType: FieldPivot = data['addType'];
    const deleteType: FieldPivot = data['deleteType'];

    if (String(ShelveFieldType.DIMENSION) === item.type || String(ShelveFieldType.TIMESTAMP) === item.type) {
      delete item.field.pivot;
    } else if (String(ShelveFieldType.MEASURE) === item.type) {
      if (deleteType) item.field.pivot.splice(item.field.pivot.indexOf(deleteType), 1);

      if (addType) item.field.pivot.push(addType);
    }
  }

  possibleChartCheck(type: string, chartType: string): boolean {
    if ('map' === chartType && -1 !== type.indexOf('mapLayer')) {
      return indexOf(POSSIBLE_CHART_OBJECT[type], chartType) > -1 && !this.#setDisableMapLayer();
    }

    return indexOf(POSSIBLE_CHART_OBJECT[type], chartType) > -1;
  }

  getCntShelfItem(type: 'DIMENSION' | 'MEASURE'): number {
    let cntShelfItems = 0;
    const strType: string = type.toLowerCase();
    if (ChartType.MAP === this.widgetConfiguration.chart.type) {
      this.shelf.layers.forEach((layer) => {
        cntShelfItems =
          cntShelfItems +
          layer.fields.filter((field) => {
            return strType === field.type && field.field.dataSource === this.dataSource?.engineName;
          }).length;
      });
    } else {
      cntShelfItems =
        cntShelfItems +
        this.pivot.rows.filter((row) => {
          return strType === row.type && row.field.dataSource === this.dataSource?.engineName;
        }).length;
      cntShelfItems =
        cntShelfItems +
        this.pivot.columns.filter((col) => {
          return strType === col.type && col.field.dataSource === this.dataSource?.engineName;
        }).length;
      cntShelfItems =
        cntShelfItems +
        this.pivot.aggregations.filter((aggr) => {
          return strType === aggr.type && aggr.field?.dataSource === this.dataSource?.engineName;
        }).length;
    }
    return cntShelfItems;
  }

  onPivotSelect(targetField: DatasourceField, isDimension: boolean): void {
    const pivotFiled: PivotField = {
      name: targetField.name,
      alias: targetField.alias,
      role: targetField.role,
      type: targetField.type as ShelveFieldType,
    };

    let isAlreadyPivot = false;
    let alreadyFieldPivot: FieldPivot;
    let alreadyPivot: PivotField[];
    let alreadyIndex: number;

    if (eq(this.selectChart, ChartType.MAP)) {
      this.#setDataSourceCurrentLayer(this.dataSource);

      const layerNum = (<UIMapOption>this.uiOption).layerNum;
      const currentMapLayer = this.shelf.layers[layerNum].fields;
      const [firstMapLayer] = currentMapLayer;

      if (firstMapLayer?.field?.dataSource !== targetField.dataSource) {
        this.alertPrimeService.warn(this.translateService.instant('msg.page.layer.multi.datasource.same.shelf'));
        return;
      }

      const fieldPivotMap = {
        [FieldPivot.MAP_LAYER0.toString()]: FieldPivot.MAP_LAYER0,
        [FieldPivot.MAP_LAYER1.toString()]: FieldPivot.MAP_LAYER1,
        [FieldPivot.MAP_LAYER2.toString()]: FieldPivot.MAP_LAYER2,
      };

      const fieldPivot: FieldPivot | undefined = fieldPivotMap['MAP_LAYER' + layerNum];
      const index = currentMapLayer.findIndex(({ name }: PivotField) => name === targetField.name);

      if (index !== -1) {
        isAlreadyPivot = true;
        alreadyFieldPivot = fieldPivot;
        alreadyPivot = currentMapLayer;
        alreadyIndex = index;
      }

      const uiLayer = (<UIMapOption>this.uiOption).layers[(<UIMapOption>this.uiOption).layerNum];
      const isUserExpr = targetField.type === 'user_expr' && targetField.aggregated;
      const isTile = uiLayer.type !== MapLayerType.TILE;

      if (!isAlreadyPivot || (!isDimension && !isUserExpr && !isTile)) {
        this.shelf.layers[layerNum].fields.push(pivotFiled as PivotField);
        this.sectionContent?.mapPivot.convertField(targetField, 'layer' + layerNum);
        return;
      }

      this.sectionContent?.mapPivot.removeField(null, alreadyFieldPivot, alreadyPivot, alreadyIndex);
      return;
    }

    if (
      !eq(this.selectChart, ChartType.MAP) &&
      !eq(this.selectChart, '') &&
      targetField.logicalType &&
      targetField.logicalType.toString().indexOf('GEO') != -1
    ) {
      this.alertPrimeService.warn(this.translateService.instant('msg.board.ui.invalid-column'));
      return;
    }

    for (let num = 0; num < this.pivot.columns.length; num++) {
      const field: PivotField = this.pivot.columns[num];
      if (field.name == targetField.name) {
        isAlreadyPivot = true;
        alreadyFieldPivot = FieldPivot.COLUMNS;
        alreadyPivot = this.pivot.columns;
        alreadyIndex = num;
        break;
      }
    }

    for (let num = 0; num < this.pivot.rows.length; num++) {
      const field: PivotField = this.pivot.rows[num];
      if (field.name == targetField.name) {
        isAlreadyPivot = true;
        alreadyFieldPivot = FieldPivot.ROWS;
        alreadyPivot = this.pivot.rows;
        alreadyIndex = num;
        break;
      }
    }

    for (let num = 0; num < this.pivot.aggregations.length; num++) {
      const field: PivotField = this.pivot.aggregations[num];
      if (field.name == targetField.name) {
        isAlreadyPivot = true;
        alreadyFieldPivot = FieldPivot.AGGREGATIONS;
        alreadyPivot = this.pivot.aggregations;
        alreadyIndex = num;
        break;
      }
    }

    if (isDimension) {
      if (
        targetField.logicalType &&
        targetField.logicalType.toString().indexOf('GEO') != -1 &&
        !eq(this.selectChart, '')
      ) {
        this.alertPrimeService.warn(this.translateService.instant('msg.board.ui.invalid-pivot'));
        return;
      }

      if (
        eq(this.selectChart, ChartType.BAR) ||
        eq(this.selectChart, ChartType.LINE) ||
        eq(this.selectChart, ChartType.HEATMAP) ||
        eq(this.selectChart, ChartType.CONTROL) ||
        eq(this.selectChart, ChartType.COMBINE) ||
        eq(this.selectChart, ChartType.WATERFALL) ||
        eq(this.selectChart, ChartType.SANKEY) ||
        eq(this.selectChart, ChartType.GRID) ||
        eq(this.selectChart, '')
      ) {
        if (!isAlreadyPivot) {
          this.pivot.columns.push(pivotFiled as PivotField);
          this.sectionContent?.pagePivot.convertField(targetField, 'column');
        } else {
          this.#getPivotComp().removeField(null, alreadyFieldPivot, alreadyPivot, alreadyIndex);
        }
      } else if (eq(this.selectChart, ChartType.GAUGE)) {
        if (!isAlreadyPivot) {
          this.pivot.rows.push(pivotFiled as PivotField);
          this.sectionContent?.pagePivot.convertField(targetField, 'row');
        } else {
          this.#getPivotComp().removeField(null, alreadyFieldPivot, alreadyPivot, alreadyIndex);
        }
      } else if (
        eq(this.selectChart, ChartType.SCATTER) ||
        eq(this.selectChart, ChartType.PIE) ||
        eq(this.selectChart, ChartType.DPIE) ||
        eq(this.selectChart, ChartType.DDONAT) ||
        eq(this.selectChart, ChartType.WORDCLOUD) ||
        eq(this.selectChart, ChartType.RADAR)
      ) {
        if (!isAlreadyPivot) {
          this.pivot.aggregations.push(pivotFiled as PivotField);
          this.sectionContent?.pagePivot.convertField(targetField, 'aggregation');
        } else {
          this.#getPivotComp().removeField(null, alreadyFieldPivot, alreadyPivot, alreadyIndex);
        }
      } else if (eq(this.selectChart, ChartType.BOXPLOT) || eq(this.selectChart, ChartType.GRAPH)) {
        if (!isAlreadyPivot) {
          let columnCount = 0;
          for (let num = 0; num < this.pivot.columns.length; num++) {
            columnCount++;
          }

          let rowCount = 0;
          for (let num = 0; num < this.pivot.rows.length; num++) {
            rowCount++;
          }

          if (columnCount == 0) {
            this.pivot.columns.push(pivotFiled as PivotField);
            this.sectionContent?.pagePivot.convertField(targetField, 'column');
          } else if (rowCount == 0) {
            this.pivot.rows.push(pivotFiled as PivotField);
            this.sectionContent?.pagePivot.convertField(targetField, 'row');
          } else {
            this.pivot.columns.push(pivotFiled as PivotField);
            this.sectionContent?.pagePivot.convertField(targetField, 'column');
          }
        } else {
          this.#getPivotComp().removeField(null, alreadyFieldPivot, alreadyPivot, alreadyIndex);
        }
      } else if (eq(this.selectChart, ChartType.TREEMAP)) {
        if (!isAlreadyPivot) {
          let columnCount = 0;
          for (let num = 0; num < this.pivot.columns.length; num++) {
            columnCount++;
          }

          let rowCount = 0;
          for (let num = 0; num < this.pivot.rows.length; num++) {
            rowCount++;
          }

          if (columnCount == 0) {
            this.pivot.columns.push(pivotFiled as PivotField);
            this.sectionContent?.pagePivot.convertField(targetField, 'column');
          } else if (rowCount == 0) {
            this.pivot.rows.push(pivotFiled as PivotField);
            this.sectionContent?.pagePivot.convertField(targetField, 'row');
          } else {
            this.pivot.rows.push(pivotFiled as PivotField);
            this.sectionContent?.pagePivot.convertField(targetField, 'row');
          }
        } else {
          this.#getPivotComp().removeField(null, alreadyFieldPivot, alreadyPivot, alreadyIndex);
        }
      }
    } else {
      const validChartTypes = [
        ChartType.BAR,
        ChartType.LINE,
        ChartType.HEATMAP,
        ChartType.PIE,
        ChartType.DPIE,
        ChartType.DDONAT,
        ChartType.CONTROL,
        ChartType.LABEL,
        ChartType.BOXPLOT,
        ChartType.WATERFALL,
        ChartType.WORDCLOUD,
        ChartType.COMBINE,
        ChartType.TREEMAP,
        ChartType.RADAR,
        ChartType.GRAPH,
        ChartType.SANKEY,
        ChartType.GAUGE,
        ChartType.GRID,
        ChartType.MAP,
        '',
      ];

      if (validChartTypes.includes(this.selectChart)) {
        if ('user_expr' == targetField.type && targetField.aggregated && isAlreadyPivot) {
          this.#getPivotComp().removeField(null, alreadyFieldPivot, alreadyPivot, alreadyIndex);
        } else {
          this.pivot.aggregations.push(pivotFiled as PivotField);
          this.sectionContent?.pagePivot.convertField(targetField, 'aggregation');
        }
      } else if (eq(this.selectChart, ChartType.SCATTER)) {
        if (!isAlreadyPivot) {
          let columnCount = 0;
          for (let num = 0; num < this.pivot.columns.length; num++) {
            columnCount++;
          }

          let rowCount = 0;
          for (let num = 0; num < this.pivot.rows.length; num++) {
            rowCount++;
          }

          if (columnCount == 0) {
            this.pivot.columns.push(pivotFiled as PivotField);
            this.sectionContent?.pagePivot.convertField(targetField, 'column');
          } else if (rowCount == 0) {
            this.pivot.rows.push(pivotFiled as PivotField);
            this.sectionContent?.pagePivot.convertField(targetField, 'row');
          }
        } else {
          this.#getPivotComp().removeField(null, alreadyFieldPivot, alreadyPivot, alreadyIndex);
        }
      }
    }
  }

  fieldPrev(isDimension: boolean): void {
    if (isDimension) {
      if (this.dimensionPage <= 1) {
        return;
      }

      this.dimensionPage--;

      this.pageDimensions = [];

      const list: DatasourceField[] = this.#getFieldSearchList(this.#dimensions);

      const start: number = (this.dimensionPage - 1) * this.#MAX_PAGE_COUNT;
      let end: number = Math.floor(this.dimensionPage * this.#MAX_PAGE_COUNT);
      end = end > list.length ? list.length : end;
      for (let num: number = start; num < end; num++) {
        this.pageDimensions.push(list[num]);
      }
    } else {
      if (this.measurePage <= 1) {
        return;
      }

      this.measurePage--;

      this.pageMeasures = [];

      const list: DatasourceField[] = this.#getFieldSearchList(this.#measures);

      const start: number = (this.measurePage - 1) * this.#MAX_PAGE_COUNT;
      let end: number = Math.floor(this.measurePage * this.#MAX_PAGE_COUNT);
      end = end > list.length ? list.length : end;
      for (let num: number = start; num < end; num++) {
        this.pageMeasures.push(list[num]);
      }
    }
  }

  fieldNext(isDimension: boolean): void {
    if (isDimension) {
      if (this.dimensionTotalPage <= this.dimensionPage) {
        return;
      }

      this.dimensionPage++;

      this.pageDimensions = [];

      const list: DatasourceField[] = this.#getFieldSearchList(this.#dimensions);

      const start: number = (this.dimensionPage - 1) * this.#MAX_PAGE_COUNT;
      let end: number = Math.floor(this.dimensionPage * this.#MAX_PAGE_COUNT);
      end = end > list.length ? list.length : end;
      for (let num: number = start; num < end; num++) {
        this.pageDimensions.push(list[num]);
      }
    } else {
      if (this.measureTotalPage <= this.measurePage) {
        return;
      }

      this.measurePage++;

      this.pageMeasures = [];

      const list: DatasourceField[] = this.#getFieldSearchList(this.#measures);

      const start: number = (this.measurePage - 1) * this.#MAX_PAGE_COUNT;
      let end: number = Math.floor(this.measurePage * this.#MAX_PAGE_COUNT);
      end = end > list.length ? list.length : end;
      for (let num: number = start; num < end; num++) {
        this.pageMeasures.push(list[num]);
      }
    }
  }

  setFieldTotalPage(page: number = 1): void {
    this.pageDimensions = [];
    this.pageMeasures = [];
    this.dimensionPage = page;
    this.measurePage = page;

    const dimensionList: DatasourceField[] = this.#getFieldSearchList(this.#dimensions);
    const measureList: DatasourceField[] = this.#getFieldSearchList(this.#measures);

    this.dimensionTotalPage =
      Math.floor(dimensionList.length / this.#MAX_PAGE_COUNT) +
      (dimensionList.length % this.#MAX_PAGE_COUNT == 0 ? 0 : 1);

    this.measureTotalPage =
      Math.floor(measureList.length / this.#MAX_PAGE_COUNT) + (measureList.length % this.#MAX_PAGE_COUNT == 0 ? 0 : 1);

    this.dimensionPage = 0;
    this.measurePage = 0;
    this.fieldNext(true);
    this.fieldNext(false);
  }

  getChartTypeTransLate(selectChart): string {
    switch (selectChart) {
      case ChartType.BAR:
        return this.translateService.instant('msg.page.ui.bar');
      case ChartType.GRID:
        return this.translateService.instant('msg.page.ui.text-table');
      case ChartType.LINE:
        return this.translateService.instant('msg.page.ui.line');
      case ChartType.SCATTER:
        return this.translateService.instant('msg.page.ui.scatter');
      case ChartType.HEATMAP:
        return this.translateService.instant('msg.page.ui.heat-map');
      case ChartType.PIE:
        return this.translateService.instant('msg.page.ui.pie');
      case ChartType.LABEL:
        return this.translateService.instant('msg.page.ui.kpi');
      case ChartType.BOXPLOT:
        return this.translateService.instant('msg.page.ui.box-plot');
      case ChartType.WATERFALL:
        return this.translateService.instant('msg.page.ui.water-fall');
      case ChartType.WORDCLOUD:
        return this.translateService.instant('msg.page.ui.word-cloud');
      case ChartType.COMBINE:
        return this.translateService.instant('msg.page.ui.combine-chart');
      case ChartType.TREEMAP:
        return this.translateService.instant('msg.page.ui.tree-map');
      case ChartType.RADAR:
        return this.translateService.instant('msg.page.ui.radar-chart');
      case ChartType.GRAPH:
        return this.translateService.instant('msg.page.ui.network');
      case ChartType.SANKEY:
        return this.translateService.instant('msg.page.ui.sankey');
      case ChartType.GAUGE:
        return this.translateService.instant('msg.page.ui.gauge-chart');
    }
  }

  onSelectChartChange(chartType) {
    this.selectChart = chartType;
    this.#drawChart();
  }

  onShowPopup(modalData: Modal) {
    this.confirmModalComponent.init(modalData);
  }

  changeDatasourceFieldAlias(changeField: DatasourceField) {
    this.dashboard.configuration.fields.some((field: DatasourceField) => {
      if (field.name === changeField.name && this.widgetConfiguration?.chart) {
        field = changeField;

        if (ChartType.MAP !== this.widgetConfiguration.chart.type) {
          PageComponent.updatePivotAliasFromField(this.widgetConfiguration.pivot, field);
        } else {
          PageComponent.updateShelfAliasFromField(
            this.widgetConfiguration.shelf,
            field,
            (<UIMapOption>this.widgetConfiguration.chart).layerNum,
          );
        }
        return true;
      }
    });
    this.dashboard.configuration.fields.some((field: DatasourceField) => {
      if (field.name === changeField.name && this.widgetConfiguration?.chart) {
        field = changeField;
        if (ChartType.MAP !== this.widgetConfiguration.chart.type) {
          PageComponent.updatePivotAliasFromField(this.widgetConfiguration.pivot, field);
        } else {
          PageComponent.updateShelfAliasFromField(
            this.widgetConfiguration.shelf,
            field,
            (<UIMapOption>this.widgetConfiguration.chart).layerNum,
          );
        }
        return true;
      }
    });
    changeField = cloneDeep(changeField);
    delete changeField.pivot;
    this.changeFieldAliasEvent.emit(changeField);
    this.#setDatasourceFields(true);

    if (eq(this.selectChart, ChartType.MAP)) {
      this.#onChangeShelf({
        shelf: this.shelf,
        eventType: EventType.DASHBOARD_ALIAS,
      });
    } else {
      this.onChangePivot({
        pivot: this.pivot,
        eventType: EventType.DASHBOARD_ALIAS,
      });
    }
  }

  removeAnalysisLayer(shelf) {
    this.changeDetect.detectChanges();

    this.#onChangeShelf({
      shelf: shelf,
      eventType: EventType.MAP_SPATIAL_ANALYSIS,
    });
  }

  isContainSearchText(sText: string, targetText: string) {
    if (StringUtil.isEmpty(sText)) return true;
    return targetText.toLowerCase().includes(sText.toLowerCase());
  }

  onChangePivot(data: Object) {
    const pivot = data['pivot'];

    const eventType = data['eventType'];
    this.pivot = pivot;

    this.uiOption = this.#setUIOptionByPivot();

    if (this.sectionSide?.formatOption) {
      this.sectionSide.formatOption.setPivot = pivot;
    }

    if (this.sectionSide?.commonOption) {
      this.sectionSide.commonOption.setPivot = pivot;
    }

    if (this.sectionSide?.dataLabelOption) {
      this.sectionSide.dataLabelOption.setPivot = pivot;
    }

    if (this.sectionSide?.secondaryIndicatorOption) {
      this.sectionSide.secondaryIndicatorOption.setPivot = pivot;
    }

    if (this.sectionSide?.secondaryIndicatorOption) {
      this.sectionSide.secondaryIndicatorOption.setPivot = pivot;
    }

    const sortFields: Sort[] = concat(pivot.columns, pivot.rows, pivot.aggregations)
      .filter((field: PivotField) => {
        return field.direction === DIRECTION.ASC || field.direction === DIRECTION.DESC;
      })
      .map((field: PivotField) => {
        const sort: Sort = createSort();

        sort.field = field.alias ? field.alias : field.name;
        if (field.type == 'measure' && field.aggregationType && (!field.alias || field.alias == field.name)) {
          const name: string = field['alias']
            ? field['alias']
            : field['fieldAlias']
            ? field['fieldAlias']
            : field['name'];
          sort.field = field.aggregationType + `(${name})`;
        }
        sort.direction = field.direction;
        sort.lastDirection = field.lastDirection;
        return sort;
      });

    if (this.sectionContent?.chart instanceof GridChartComponent) {
      this.sorts = sortFields;
    } else {
      if (sortFields.length > 0) {
        const sortList: Sort[] = [];

        if (this.sorts) {
          for (const beforeField of this.sorts) {
            let isUse = false;
            for (const afterField of sortFields) {
              if (afterField.field == beforeField.field && !afterField.lastDirection) {
                isUse = true;
                break;
              }
            }
            if (isUse) {
              sortList.push(beforeField);
            }
          }
        }

        const afterField = sortFields.find((field) => field.lastDirection);
        afterField && sortList.unshift({ ...afterField, lastDirection: undefined });

        this.sorts = sortList;
      } else {
        this.sorts = [];
      }
    }

    this.#recommendChart();

    this.#drawChart({ type: eventType });
  }

  #onChangeShelf(data: Object) {
    const shelf = data['shelf'];
    const eventType = data['eventType'];
    this.shelf = shelf;

    if (this.sectionSide.mapLayerOption) {
      this.sectionSide.mapLayerOption.setShelf = shelf;
    }

    if (this.sectionSide.mapFormatOption) {
      this.sectionSide.mapFormatOption.setShelf = shelf;
    }

    if (this.sectionSide.mapTooltipOption) {
      this.sectionSide.mapTooltipOption.setShelf = shelf;
    }

    this.#recommendChart();

    this.#setDatasourceFields(true);

    if (this.selectChart === 'map') {
      this.analysisComponent?.mapSpatialChanges(this.uiOption, this.shelf);
    }

    this.#drawChart({ type: eventType });
  }

  // private getNavWidth() {
  //   if (this.nav.data === true) {
  //     this.navWidth = "0px";
  //     this.navWidthContChart =
  //       "-" + (this.navWidthCont.nativeElement.offsetWidth - 63 * 2) + "px";
  //   }
  //   if (this.nav.chart == true) {
  //     this.navWidth = 1 * 63 + "px";
  //     this.navWidthContChart = "0px";
  //   }
  // }

  #setDefaultAreaForBBox(dataSource: Datasource) {
    if (
      (isNullOrUndefined(this.widgetConfiguration?.chart['lowerCorner']) || !this.isChartShow) &&
      dataSource.summary
    ) {
      this.widgetConfiguration.chart['lowerCorner'] = dataSource.summary['geoLowerCorner'];
      this.widgetConfiguration.chart['upperCorner'] = dataSource.summary['geoUpperCorner'];
    }
  }

  #setDataSourceCurrentLayer(dataSource: Datasource) {
    if (this.widgetConfiguration.shelf) {
      const currentLayer: ShelfLayers = this.widgetConfiguration.shelf.layers[(<UIMapOption>this.uiOption).layerNum];
      if (0 === currentLayer.fields.length) {
        currentLayer.ref = dataSource.engineName;
      }
    }
  }

  #isNewWidget() {
    return !this.widget?.createdTime;
  }

  #initGridChart() {
    try {
      if (this.sectionContent?.gridChart && this.sectionContent?.gridChart.isLoaded) {
        this.sectionContent.gridChart.resultData = this.resultData;
      }
    } catch (err) {
      console.error(err);
    }
  }

  #getPivotComp(): PagePivotComponent {
    if (eq(this.selectChart, 'map')) {
      return this.sectionContent?.mapPivot;
    }
    return this.sectionContent?.pagePivot;
  }

  #getFieldSearchList(list: DatasourceField[]): DatasourceField[] {
    const result: DatasourceField[] = [];

    if (StringUtil.isEmpty(this.fieldSearchText)) {
      return list;
    }

    for (const item of list) {
      if (item.name.toLowerCase().includes(this.fieldSearchText.toLowerCase())) {
        result.push(item);
      }
    }

    return result;
  }

  #setDisableShelf(layerNum: number): boolean {
    let valid = true;

    if ('map' == this.selectChart && this.uiOption['analysis'] != null && this.uiOption['analysis']['use'] == true) {
      layerNum == this.shelf.layers.length - 1 ? (valid = false) : (valid = true);
      return valid;
    }

    if (isUndefined(this.shelf.layers[layerNum])) {
      return valid;
    }

    const layers = this.shelf.layers[layerNum].fields;
    if (layers) {
      for (const layer of layers) {
        if (layer.field && layer.field.logicalType && -1 !== layer.field.logicalType.toString().indexOf('GEO')) {
          valid = false;
        }
      }
    }

    return valid;
  }

  #setDisableMapLayer(): boolean {
    let index: number;
    this.rnbMenu.indexOf('1') != -1 ? (index = 0) : (index = Number(this.rnbMenu.split('mapLayer')[1]) - 1);

    return this.#setDisableShelf(index);
  }

  #settingDragAndDrop() {
    const acceptsContainer = [
      'column',
      'row',
      'aggregation',
      'column-guide',
      'row-guide',
      'aggregation-guide',
      'layer0',
      'layer1',
      'layer2',
      'layer-guide',
    ];

    function copy(el) {
      return ['dimension', 'measure'].indexOf(el['dataset']['source']) > -1;
    }

    function accepts(el, target, source, sibling) {
      return acceptsContainer.indexOf(target.dataset.container) > -1;
    }

    function moves(el, source) {
      return !el.classList.contains('dragIgnore');
    }

    this.dragulaService.setOptions('dragbag', {
      copy,
      accepts,
      moves,

      direction: 'horizontal',
      revertOnSpill: true,
    });

    const dragulaDragSubs = this.dragulaService.drag.subscribe((value) => {
      if (eq(this.selectChart, ChartType.MAP)) {
        this.#setDataSourceCurrentLayer(this.dataSource);
      }
    });

    const dragulaDropSubs = this.dragulaService.drop.subscribe((value) => {});

    const dragulaOverSubs = this.dragulaService.over.subscribe((value) => {});

    const dragulaOutSubs = this.dragulaService.out.subscribe((value) => {});

    const dragulaDropModelSubs = this.dragulaService.dropModel.subscribe((value) => {
      this.dragulaService.find('dragbag').drake.cancel(true);

      const [el, target, source] = value.slice(1);

      if (undefined === target) {
        return;
      }

      const info = {
        name: el.dataset.name,
        source: el.dataset.source,
        target: target.dataset.container,
      };

      let targetField;
      if (info.source === 'dimension') {
        targetField = this.#dimensions.find((field) => {
          return info.name === field.name;
        });
      } else if (info.source === 'measure') {
        targetField = this.#measures.find((field) => {
          return info.name === field.name;
        });
      }

      if (acceptsContainer.indexOf(info.target) > -1) {
        if (info.target.includes('guide') && targetField) {
          this.#getPivotComp().addField(targetField, info.target.replace(/-.*$/, ''), this.#getPivotComp().dragField);
        } else {
          if (targetField) {
            if (
              !eq(this.selectChart, ChartType.MAP) &&
              !eq(this.selectChart, '') &&
              targetField.logicalType &&
              targetField.logicalType.toString().indexOf('GEO') != -1
            ) {
              if (info.target === 'column') {
                this.#invalidGeoData(this.pivot.columns);
              } else if (info.target === 'row') {
                this.#invalidGeoData(this.pivot.rows);
              } else if (info.target === 'aggregation') {
                this.#invalidGeoData(this.pivot.aggregations);
              }
              this.alertPrimeService.warn(this.translateService.instant('msg.board.ui.invalid-column'));
              return;
            }
            this.#getPivotComp().convertField(targetField, info.target);
          } else if (info.target) {
            targetField = concat(this.#dimensions, this.#measures).find((field) => {
              return this.#getPivotComp().dragField.name === field.name;
            });

            this.#getPivotComp().changeFieldPivot(targetField, info.target, this.#getPivotComp().dragField);
          }
        }
      }
    });

    const dragulaRemoveModelSubs = this.dragulaService.removeModel.subscribe((value) => {});

    this.subscriptions.push(
      dragulaDragSubs,
      dragulaDropSubs,
      dragulaOverSubs,
      dragulaOutSubs,
      dragulaDropModelSubs,
      dragulaRemoveModelSubs,
    );
  }

  #setDatasourceFields(fieldPivotSetFl?: boolean) {
    this.#dimensions = [];
    this.#measures = [];

    const boardConf: BoardConfiguration = {
      ...this.dashboard.configuration,
      fields: this.dataSource.fields,
    };

    const totalFields: DatasourceField[] = boardConf.fields;

    if (totalFields && totalFields.length > 0) {
      totalFields.forEach((field) => {
        if (field.role === FieldRole.MEASURE) {
          this.#measures.push(field);
        } else if (field.role === FieldRole.DIMENSION || field.role === FieldRole.TIMESTAMP) {
          this.#dimensions.push(field);
        } else {
        }
      });
      this.fields = totalFields;
    } else {
      this.fields = [];
    }

    this.#customDimensions = [];
    this.#customMeasures = [];
    if ('customFields' in boardConf && boardConf.customFields && boardConf.customFields.length > 0) {
      boardConf.customFields
        .filter((item) =>
          item.dataSource === this.widget.configuration.dataSource.engineName
            ? this.widget.configuration.dataSource.engineName
            : this.widget.configuration.dataSource.name,
        )
        .forEach((field: CustomField) => {
          if (field.role === FieldRole.DIMENSION) {
            this.#customDimensions.push(field);

            const dimension: DatasourceField = createDatasourceField({
              name: field.name,
              type: field.type,
              role: field.role,
              expr: field.expr,
              ref: field.ref,
              alias: field.name,
            });

            this.#dimensions.push(dimension);
          } else if (field.role === FieldRole.MEASURE) {
            this.#customMeasures.push(field);

            const measure: DatasourceField = createDatasourceField({
              name: field.name,
              type: field.type,
              role: field.role,
              expr: field.expr,
              ref: field.ref,
              alias: field.name,
              aggregated: field.aggregated,
            });

            this.#measures.push(measure);
          }
        });
    }

    this.setFieldTotalPage();

    if (this.widgetConfiguration.pivot && fieldPivotSetFl) {
      concat(this.#dimensions, this.#measures).forEach((field) => {
        field.pivot = [];

        this.widgetConfiguration.pivot.rows.forEach((abstractField) => {
          if (String(field.type) == abstractField.type.toUpperCase() && field.name == abstractField.name) {
            abstractField.field = field;
            field.pivot = field.pivot ? field.pivot : [];
            field.pivot.push(FieldPivot.ROWS);
          }
        });

        this.widgetConfiguration.pivot.columns.forEach((abstractField) => {
          if (String(field.type) == abstractField.type.toUpperCase() && field.name == abstractField.name) {
            abstractField.field = field;
            field.pivot = field.pivot ? field.pivot : [];
            field.pivot.push(FieldPivot.COLUMNS);
          }
        });

        this.widgetConfiguration.pivot.aggregations.forEach((abstractField) => {
          if (String(field.type) == abstractField.type.toUpperCase() && field.name == abstractField.name) {
            abstractField.field = field;
            field.pivot = field.pivot ? field.pivot : [];
            field.pivot.push(FieldPivot.AGGREGATIONS);
          }
        });

        if (
          undefined !== this.widgetConfiguration?.chart['layerNum'] &&
          this.widgetConfiguration?.chart['layerNum'] >= 0
        ) {
          for (let layerIndex = 0; layerIndex < this.widgetConfiguration?.chart['layers'].length; layerIndex++) {
            const fieldPivot: FieldPivot =
              layerIndex == 1 ? FieldPivot.MAP_LAYER1 : layerIndex == 2 ? FieldPivot.MAP_LAYER2 : FieldPivot.MAP_LAYER0;
            this.widgetConfiguration.shelf.layers[this.widgetConfiguration?.chart['layerNum']].fields.forEach(
              (abstractField) => {
                if (String(field.type) == abstractField.type.toUpperCase() && field.name == abstractField.name) {
                  abstractField.field = field;
                  field.pivot = field.pivot ? field.pivot : [];
                  field.pivot.push(fieldPivot);
                }
              },
            );
          }
        }
      });
    }

    // this.fieldsWCustom = concat(this.dimensions, this.measures);

    this.#setUseFilter();
  }

  #init() {
    this.dataLayerKey = '';
    // this.isModelLayerShow = false;
    this.isDataDimensionLayerShow = true;
    this.isDataMeasureLayerShow = true;
    this.recommendCharts = [];
    // this.isShowGuide = true;

    // this.$fieldDetailLayer = $("#fieldDetailLayer");

    // this.guideLayout = {
    //   layout1: ["pie", "label", "wordcloud", "radar"],
    //   layout2: ["bar", "grid", "line", "combine"],
    //   layout3: ["waterfall", "sankey"],

    //   layout4: ["scatter", "heatmap", "boxplot", "treemap", "network"],
    //   layout5: ["gauge"],
    //   layout6: ["map"],
    // };
  }

  #recommendChart() {
    this.recommendCharts = [];

    function getShelfCnt(shelfType: string, fieldType: string[], pivot): number {
      let shelf: PivotField[];
      if (shelfType === 'col') {
        shelf = pivot.columns;
      } else if (shelfType === 'row') {
        shelf = pivot.rows;
      } else if (shelfType === 'agg') {
        shelf = pivot.aggregations;
      } else {
        throw new Error('Unknown shelfType');
      }

      return shelf.filter((field: PivotField) => {
        return fieldType.indexOf(field.type) > -1;
      }).length;
    }

    function getAllShelfCntByType(fieldType: string[], allPivot): number {
      return allPivot.filter((field: PivotField) => {
        return fieldType.indexOf(field.type) > -1;
      }).length;
    }

    function getGeoType(logicalType: string, allPivot: PivotField[], uiOption: UIOption): number {
      if (
        !isUndefined(uiOption['analysis']) &&
        !isUndefined(uiOption['analysis']['use']) &&
        uiOption['analysis']['use']
      ) {
        return allPivot.length;
      }

      return allPivot.filter((item: PivotField) => {
        return item.field?.logicalType && -1 !== item.field?.logicalType.toString().indexOf(logicalType);
      }).length;
    }

    let pivotList = [];
    if (this.shelf && this.shelf.layers && undefined !== (<UIMapOption>this.uiOption).layerNum)
      pivotList = this.shelf.layers[(<UIMapOption>this.uiOption).layerNum].fields;
    else if (this.pivot) pivotList = this.pivot.aggregations.concat(this.pivot.rows.concat(this.pivot.columns));

    const geoCnt = getGeoType('GEO', pivotList, this.uiOption);

    if (geoCnt > 0) {
      this.recommendCharts.push('map');
      return;
    }

    const dimensionCnt = getAllShelfCntByType(['dimension'], pivotList);
    const measureCnt = getAllShelfCntByType(['measure'], pivotList);
    const timestampCnt = getAllShelfCntByType(['timestamp'], pivotList);

    if (dimensionCnt > 0 && measureCnt > 0) {
      this.recommendCharts.push('bar');
      this.recommendCharts.push('line');
      this.recommendCharts.push('pie');
      this.recommendCharts.push('dpie');
      this.recommendCharts.push('ddonat');
    }

    if (timestampCnt > 0 && measureCnt > 0) {
      this.recommendCharts.push('bar');
      this.recommendCharts.push('line');
    }

    if (dimensionCnt > 0 && measureCnt > 0) {
      this.recommendCharts.push('grid');
    }

    if (dimensionCnt > 0 && measureCnt == 2) {
      this.recommendCharts.push('scatter');
    }

    if (dimensionCnt > 0 && measureCnt == 1) {
      this.recommendCharts.push('heatmap');
      this.recommendCharts.push('boxplot');
      this.recommendCharts.push('wordcloud');
      this.recommendCharts.push('treemap');
      this.recommendCharts.push('gauge');
    }

    if (timestampCnt > 0 && measureCnt > 0) {
      this.recommendCharts.push('control');
    }

    if (dimensionCnt == 0 && timestampCnt == 0 && measureCnt > 0) {
      this.recommendCharts.push('label');
    }

    if (timestampCnt === 1 && measureCnt === 1) {
      this.recommendCharts.push('waterfall');
    }

    if (dimensionCnt > 0 && measureCnt > 1 && 5 > measureCnt) {
      this.recommendCharts.push('combine');
    }

    if (dimensionCnt === 1 && measureCnt > 0) {
      this.recommendCharts.push('radar');
    }

    if (dimensionCnt == 2 && measureCnt == 1) {
      this.recommendCharts.push('network');
    }

    if (dimensionCnt > 2 && measureCnt == 1) {
      this.recommendCharts.push('sankey');
    }

    this.recommendCharts = uniq(this.recommendCharts);
  }

  #chartResize(isImmediate: boolean = false) {
    if (this.sectionContent?.chart) {
      setTimeout(
        () => {
          if (
            this.sectionContent?.chart.hasOwnProperty('barChart') &&
            this.sectionContent?.chart.hasOwnProperty('lineChart')
          ) {
            const barChart: BarChartComponent = this.sectionContent?.chart['barChart'];
            const lineChart: LineChartComponent = this.sectionContent?.chart['lineChart'];
            barChart.chart.resize();
            lineChart.chart.resize();
          } else if (this.sectionContent?.chart.uiOption.type === ChartType.LABEL) {
          } else if (this.widgetConfiguration?.chart.type.toString() === 'grid') {
            if (this.sectionContent?.chart && this.sectionContent?.chart.chart)
              this.sectionContent?.chart.chart.resize();
            // } else if (this.sectionContent?.chart.uiOption.type === ChartType.GRAPH) {
            //   this.networkChart.draw();
          } else if (this.sectionContent?.chart.uiOption.type === ChartType.MAP) {
            this.sectionContent?.mapChart.resize();
          } else {
            if (this.sectionContent?.chart && this.sectionContent?.chart.chart)
              this.sectionContent?.chart.chart.resize();
          }
        },
        isImmediate ? 0 : 300,
      );
    }

    if (this.sectionContent?.gridChart?.pivotGrid) {
      setTimeout(() => this.sectionContent?.gridChart.pivotGrid.arrange(), isImmediate ? 0 : 300);
    }
  }

  #drawChart(
    params: any = {
      successCallback: null,
      resultFormatOptions: {},
      filters: [],
      type: '',
    },
  ) {
    if (StringUtil.isEmpty(this.selectChart)) return;

    if (this.sectionContent?.chart === undefined || this.sectionContent?.chart === null) {
      setTimeout(() => this.#drawChart(), 300);
      return;
    }
    if (!this.#isVersionCheck) {
      if (!this.uiOption.version || this.uiOption.version < SPEC_VERSION) {
        this.uiOption = OptionGenerator.initUiOption(this.uiOption);

        if (this.selectChart == 'map') {
          this.#setDefaultAreaForBBox(this.dataSource);
        }
      }

      this.#isVersionCheck = true;
    }

    // this.aggregation = this.pivot.aggregations;
    this.emitAggregationValueToChild();

    if (
      ('map' !== this.selectChart && false === this.sectionContent?.chart.isValid(this.pivot as any)) ||
      ('map' === this.selectChart &&
        false === this.sectionContent?.chart.isValid(createPivot() as any, this.shelf as any))
    ) {
      this.isChartShow = false;
      this.isError = true;
      return;
    }

    if (
      this.widgetConfiguration?.chart.type == 'grid' &&
      this.uiOption.dataType !== 'MASTER' &&
      this.pivot.aggregations.length == 0
    ) {
      return;
    }

    if (
      this.widgetConfiguration?.chart.type == 'grid' &&
      this.uiOption.dataType === 'MASTER' &&
      this.pivot.aggregations.length == 0
    ) {
      const count_aggregation: MeasureField = {
        type: ShelveFieldType.MEASURE,
        aggregationType: AggregationType.COUNT,
        name: 'count',
        subType: 'STRING',
        subRole: 'MEASURE',
        alias: 'COUNT(count)',
        format: {
          type: 'number',
          sign: 'KRW',
          decimal: 2,
          useThousandsSep: true,
          abbr: 'NONE',
          customSymbol: null,
        },
        aggregationTypeList: [],
      };

      this.pivot.aggregations = [count_aggregation];
    }

    const query: SearchQueryRequest = this.datasourceService.makeQuery(
      this.widgetConfiguration,
      this.fields,
      {
        url: this.router.url,
        dashboardId: this.dashboard.id,
        widgetId: this.widget.id,
      },
      params.resultFormatOptions,
    );
    const uiCloneQuery = cloneDeep(query);
    if (
      'map' !== this.selectChart &&
      uiCloneQuery.pivot.columns.length + uiCloneQuery.pivot.rows.length + uiCloneQuery.pivot.aggregations.length === 0
    ) {
      return;
    }

    if ('bar' == this.selectChart) {
      let isChangeDimensionType = false;

      this.pivot.rows.forEach((item) => {
        if (item.type === String(ShelveFieldType.DIMENSION)) {
          isChangeDimensionType = true;
        }
      });

      this.pivot.aggregations.forEach((item) => {
        if (item.type === String(ShelveFieldType.DIMENSION)) {
          isChangeDimensionType = true;
        }
      });

      if (isChangeDimensionType) {
        this.uiOption.color['schema'] = 'SC1';
        this.uiOption.color['type'] = ChartColorType.DIMENSION;
        this.uiOption.color['targetField'] = '';
      }
    }

    this.loadingShow();
    this.isNoData = false;
    this.isError = false;
    this.isChartShow = true;

    if (this.boardFilters && 0 < this.boardFilters.length) {
      uiCloneQuery.filters = this.boardFilters.concat(uiCloneQuery.filters);
    }

    if (params.filters && params.filters.length > 0) uiCloneQuery.filters = params.filters;

    const cloneQuery = this.#makeSearchQueryParam(cloneDeep(uiCloneQuery));

    this.#query = cloneQuery;
    if (this.selectChart === 'label') {
      this.sectionContent.chart['setQuery'] = this.#query;
    }

    this.datasourceService
      .searchQuery(cloneQuery)
      .then((data) => {
        const resultData = {
          data: data,
          config: uiCloneQuery,
          uiOption: this.uiOption,
          type: params.type,
        };
        this.resultData = resultData;

        if (Object.keys(this.uiOption).length === 1) {
          delete resultData.uiOption;
        }

        this.#initGridChart();

        this.limitInfo = DashboardUtil.getChartLimitInfo(this.widget.id, this.widget.configuration.chart.type, data);

        if (this.selectChart === 'line') {
          if (this.#isAnalysisPredictionEnabled()) {
            Promise.resolve().then(() => {
              this.analysisComponent.synchronize(
                this.uiOption,
                { pivot: this.pivot },
                {
                  type: params.type,
                  widget: this.widget,
                  lineChart: this.sectionContent?.lineChartComponent,
                },
              );

              if (this.analysisComponent.isValid() === false) {
                this.sectionContent.lineChartComponent.analysis = null;
                this.sectionContent.chart.resultData = resultData;
              }

              if (this.#isAnalysisPredictionEnabled()) {
                this.loadingShow();
                this.analysisPredictionService
                  .getAnalysisPredictionLineFromPage(
                    this.widgetConfiguration,
                    this.dashboard.configuration.filters,
                    this.sectionContent?.lineChartComponent,
                    resultData,
                  )
                  .catch((err) => {
                    this.loadingHide();
                    this.isError = true;
                    this.commonExceptionHandler(err);
                  });
              } else {
                this.sectionContent.lineChartComponent.analysis = null;
                this.sectionContent.chart.resultData = resultData;
              }
            });
          } else {
            if (this.analysisComponent?.isValid()) {
              this.analysisComponent.changePredictionLineDisabled();
            }
            this.sectionContent.lineChartComponent.analysis = null;
            setTimeout(() => {
              this.sectionContent.chart.resultData = resultData;
            }, 300);
          }
        } else if (this.selectChart == 'map') {
          const mapUiOption = <UIMapOption>this.uiOption;

          for (let mapIndex = 0; mapUiOption.layers.length > mapIndex; mapIndex++) {
            if (
              mapUiOption.layers[mapIndex].type == MapLayerType.SYMBOL &&
              !isUndefined(mapUiOption.layers[mapIndex]['clustering']) &&
              mapUiOption.layers[mapIndex]['clustering']
            ) {
              mapUiOption.layers[mapIndex].type = MapLayerType.CLUSTER;
            }

            if (!isUndefined(params) && !isUndefined(params.type) && params.type == EventType.AGGREGATION) {
              mapUiOption.layers[mapIndex]['isColorOptionChanged'] = true;
            }
          }
          setTimeout(() => {
            this.sectionContent.chart.resultData = resultData;
          }, 300);
        } else {
          setTimeout(() => {
            this.sectionContent.chart.resultData = resultData;
          }, 300);
        }

        this.loadingHide();
        if (params.successCallback) {
          params.successCallback();
        }
      })
      .catch((reason) => {
        let err = {};

        if (!isUndefined(this.uiOption['analysis']) && this.uiOption['analysis']['use'] == true) {
          if (!isUndefined(reason) && (!isUndefined(reason['message']) || !isUndefined(reason['details']))) {
            err['code'] = reason.code;

            const message = reason['message'];
            const detailMessage = reason['details'];

            if (!isUndefined(message) && message.length > 30) {
              err['message'] = 'Spatial Config Error <br/>' + message.substring(0, 30);
              err['details'] = message + '<br/>' + detailMessage;
            } else {
              err['message'] = 'Spatial Config Error';
              err['details'] = message + '<br/>' + detailMessage;
            }
          } else {
            err['message'] = 'Spatial Config Error <br/>';
            err['details'] = reason;
          }
        } else if (reason?.message) {
          err['message'] = reason.message.substring(0, 30);
          err['details'] = reason.details;
        } else {
          err = reason;
        }

        this.isChartShow = false;
        this.isError = true;
        this.commonExceptionHandler(err);

        this.changeDetect.detectChanges();
        this.loadingHide();
      });
  }

  #isAnalysisPredictionEnabled(): boolean {
    return (
      this.analysisComponent &&
      !isUndefined(this.widgetConfiguration.analysis) &&
      !isEmpty(this.widgetConfiguration.analysis)
    );
  }

  #makeSearchQueryParam(cloneQuery): SearchQueryRequest {
    if (cloneQuery.pivot) {
      for (const field of concat(cloneQuery.pivot.columns, cloneQuery.pivot.rows, cloneQuery.pivot.aggregations)) {
        delete field['field'];
        delete field['currentPivot'];
        delete field['granularity'];
        delete field['segGranularity'];
      }
    }

    if (cloneQuery.shelf && cloneQuery.shelf.layers && cloneQuery.shelf.layers.length > 0) {
      cloneQuery.shelf.layers = remove(cloneQuery.shelf.layers, function (layer) {
        return layer['fields'].length != 0;
      });

      for (const layers of cloneQuery.shelf.layers) {
        for (const layer of layers.fields) {
          delete layer['field'];
          delete layer['currentPivot'];
          delete layer['granularity'];
          delete layer['segGranularity'];
        }
      }

      if (!isUndefined(cloneQuery.analysis)) {
        if (cloneQuery.analysis.use == true) {
          delete cloneQuery.analysis.operation.unit;
          delete cloneQuery.analysis.layer;
          delete cloneQuery.analysis.layerNum;
          delete cloneQuery.analysis.use;
        } else {
          delete cloneQuery.analysis;
        }
      }
    }

    for (let idx = 0, nMax = cloneQuery.filters.length; idx < nMax; idx++) {
      cloneQuery.filters[idx] = FilterUtil.convertToServerSpec(cloneQuery.filters[idx]);
    }

    cloneQuery.filters = cloneQuery.filters.filter((item) => !(item.type === 'bound' && item['min'] == null));

    cloneQuery.userFields = CommonUtil.objectToArray(cloneQuery.userFields);

    return cloneQuery;
  }

  #setUIOptionByPivot(): UIOption {
    if (this.uiOption.type == ChartType.BAR) {
      this.pivot.rows.forEach((item) => {
        if (item.type === String(ShelveFieldType.DIMENSION)) {
          this.uiOption['mark'] = BarMarkType.STACKED;
        }
      });

      this.pivot.aggregations.forEach((item) => {
        if (item.type === String(ShelveFieldType.DIMENSION)) {
          this.uiOption['mark'] = BarMarkType.MULTIPLE;
        }
      });
    }

    return this.uiOption;
  }

  #setUseFilter() {
    if (!this.fields) return;

    let globalFilters = this.dashboard.configuration.filters;
    let chartFilters = this.widgetConfiguration.filters;

    if (!globalFilters) globalFilters = [];
    if (!chartFilters) chartFilters = [];

    const filters = globalFilters.concat(chartFilters);

    const fields: DatasourceField[] = this.#dimensions.concat(this.#measures);

    fields.concat(this.#measures).forEach((field) => {
      field.useFilter = false;
    });

    fields.concat(this.#measures).forEach((field) => {
      if (field.ref) {
        if (findIndex(filters, { field: field.name, ref: field.ref }) > -1) {
          field.useFilter = true;
        }
      } else {
        if (findIndex(filters, { field: field.name }) > -1) {
          field.useFilter = true;
        }
      }
    });
  }

  #setChartFilter(targetFilter: Filter, isSetPanel: boolean = true) {
    remove(this.dashboard.configuration.filters, {
      field: targetFilter.field,
      dataSource: targetFilter.dataSource,
    });

    if (ChartType.MAP === this.widget.configuration.chart.type) {
      this.boardFilters = this.dashboard.configuration.filters;
    } else {
      this.boardFilters = DashboardUtil.getAllFiltersDsRelations(
        this.dashboard,
        this.widget.configuration.dataSource.engineName,
      );
    }

    targetFilter.ui.widgetId = this.#isNewWidget() ? 'NEW' : this.widget.id;

    const chartFilters: Filter[] = this.widget.configuration.filters;
    const idx: number = chartFilters.findIndex((item) => item.field === targetFilter.field);
    if (-1 === idx) {
      chartFilters.push(cloneDeep(targetFilter));
    } else {
      chartFilters[idx] = cloneDeep(targetFilter);
    }
    this.widget.configuration.filters = chartFilters;

    if (isSetPanel && this.sectionSide._filterPanelComp) {
      this.sectionSide._filterPanelComp.setFilters(this.boardFilters, this.widget.configuration.filters);
    }
  }

  #convertShelfToPivot(pivot: Pivot, uiOption: UIOption) {
    if (
      this.shelf.layers &&
      this.shelf.layers[0] &&
      this.shelf.layers[0].fields[0] &&
      this.shelf.layers[0].fields.length > 0
    ) {
      pivot = createPivot();

      each(this.shelf.layers, (layer, layerNum) => {
        const layers = layer['fields'];
        each(layers, (item, index) => {
          if (item.field && item.field.pivot) {
            item.field.pivot = map(item.field.pivot, (pivotItem) => {
              pivotItem = FieldPivot.AGGREGATIONS;
              return pivotItem;
            });
          }

          if (
            MapLayerType.SYMBOL === (<UIMapOption>uiOption).layers[layerNum].type ||
            MapLayerType.CLUSTER === (<UIMapOption>uiOption).layers[layerNum].type ||
            MapLayerType.HEATMAP === (<UIMapOption>uiOption).layers[layerNum].type
          ) {
            this.sectionContent?.pagePivot.distinctPivotItems(layers, item, index, layers, 'layer' + layerNum);
          }

          pivot.aggregations.push(item);
        });
      });
    }

    this.shelf = createShelf();

    return pivot;
  }

  #convertPivotToShelf(shelf: Shelf): Shelf {
    const currentLayer: ShelfLayers = shelf.layers[(<UIMapOption>this.uiOption).layerNum];
    if (!isUndefined(currentLayer) && 0 === currentLayer.fields.length) {
      currentLayer.ref = this.dataSource.engineName;

      forEach(cloneDeep(this.pivot), (value, key) => {
        this.pivot[key].map((item) => {
          if (item.field && item.field.pivot) {
            item.field.pivot = map(item.field.pivot, (pivotItem) => {
              pivotItem = FieldPivot.MAP_LAYER0;
              return pivotItem;
            });
          }

          delete item.aggregationType;

          currentLayer.fields.push(item);
        });
      });

      currentLayer.fields = uniqBy(currentLayer.fields, 'name');

      for (const item of currentLayer.fields) {
        item.field.pivot = uniq(item.field.pivot);
      }
    }

    this.pivot = createPivot();

    return shelf;
  }

  #getMapGeoType() {
    for (const item of this.pageDimensions) {
      if (item.logicalType && -1 !== item.logicalType.toString().indexOf('GEO')) {
        return (this.geoType = item.logicalType);
      }
    }
  }

  #invalidGeoData(targetPivot: PivotField[]) {
    remove(targetPivot, function (item: PivotField) {
      return !isUndefined(item.logicalType) && item.logicalType.toString().indexOf('GEO') != -1;
    });
    return targetPivot;
  }

  #getDashboard() {
    this.dashboardDomainService.loadDashboard(this.widget.dashBoardId).pipe(takeUntil(this.destroy$)).subscribe();

    this.dashboardDomainService
      .getDashboard(this.widget.dashBoardId)
      .pipe(
        tap((dashboard) => {
          this.dashboard = dashboard;

          this.dataSourceList = getMainDataSources(this.dashboard);

          const widgetDataSource: Datasource = DashboardUtil.getDataSourceFromBoardDataSource(
            this.dashboard,
            this.widget.configuration.dataSource,
          );

          if (this.widget.configuration.filters) {
            this.widget.configuration.filters.forEach((item) => {
              item.ui ||
                (item.ui = {
                  importanceType: 'general',
                });
              if (!item.dataSource) {
                item.dataSource = widgetDataSource.name.toLowerCase();
              }
            });
          }

          this.selectDataSource(widgetDataSource ? widgetDataSource : this.dataSourceList[0], false);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  protected updateCustomFields(data: { customField: any; isEdit: boolean }) {
    const customField: any = data.customField;
    const isEdit: boolean = data.isEdit;

    if (this.dashboard.configuration && !this.dashboard.configuration.customFields)
      this.dashboard.configuration.customFields = [];
    const customFields = this.dashboard.configuration.customFields;

    if (!isEdit) {
      customFields.push(customField);
    } else {
      customFields.forEach((field) => {
        if (field.name === customField.oriColumnName) {
          field.alias = customField.alias;
          field.name = customField.name;
          field.expr = customField.expr;
          field.aggregated = customField.aggregated;
          field.oriColumnName = customField.oriColumnName;
        }
      });
      this.widget.configuration.filters.some((filter: Filter) => {
        if (filter.field === customField.oriColumnName) {
          filter.field = customField.name;
          return true;
        }
      });
      this.dashboard.configuration.filters.some((filter: Filter) => {
        if (filter.field === customField.oriColumnName) {
          filter.field = customField.name;
          return true;
        }
      });
    }
    if (this.isDashboard) {
      if (isEdit) {
        this.alertPrimeService.success(
          this.translateService.instant('msg.board.custom.ui.update', { name: customField.name }),
        );
      } else {
        this.#setDatasourceFields();
        this.alertPrimeService.success(
          this.translateService.instant('msg.board.custom.ui.create', { name: customField.name }),
        );
      }

      (<PageWidgetConfiguration>this.widget.configuration).customFields = this.dashboard.configuration.customFields;
      let currentField;

      if (customField.pivot && customField.pivot['length'] > 0) {
        const setPivot = (pivot) => {
          for (let index = pivot.length; index--; ) {
            const item = pivot[index];

            if (item.name === customField.oriColumnName) {
              item.name = customField.name;
              item['aggregated'] = customField.aggregated;
              item['expr'] = customField.expr;
              item['alias'] = customField.alias;
              item['biType'] = customField.biType;
              if (customField.aggregated) {
                const duplicateList = pivot.filter((data) => {
                  return customField.oriColumnName == data.name;
                });
                if (duplicateList.length > 1) {
                  pivot.splice(index, 1);
                  item.field.pivot.splice(item.field.pivot.indexOf(item.currentPivot), 1);
                }
                delete item['aggregationType'];
              }
              currentField = item;
            }
          }
        };

        setPivot(this.pivot.aggregations);
        setPivot(this.pivot.columns);
        setPivot(this.pivot.rows);
        const currentTarget =
          currentField['currentPivot'] === FieldPivot.AGGREGATIONS
            ? 'aggregation'
            : currentField['currentPivot'] === FieldPivot.ROWS
            ? 'row'
            : 'column';
        this.#getPivotComp().convertField(customField, currentTarget, false);
      }
      this.isShowCustomFiled = false;
    } else {
      this.widget.configuration['customFields'] = this.dashboard.configuration.customFields;
      this.#setDatasourceFields();
      this.isShowCustomFiled = false;
    }
  }
}
