import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { StringUtil } from '../../../common/util';

@Component({
  selector: 'selfai-platform-page-section-data',
  templateUrl: './page-section-data.component.html',
  styleUrls: ['./page-section-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PageSectionDataComponent {
  @Input() dataSourceList: any;
  @Input() dataSource: any;
  @Input() fieldSearchText: any;
  @Input() isDataDimensionLayerShow: any;
  @Input() pageDimensions: any;
  @Input() dimensionPage: any;
  @Input() dimensionTotalPage: any;
  @Input() isDataMeasureLayerShow: any;
  @Input() pageMeasures: any;
  @Input() measurePage: any;
  @Input() measureTotalPage: any;
  @Input() isContainSearchText: any;

  @Output() selectDataSource = new EventEmitter<any>();
  @Output() onDataPreviewPopup = new EventEmitter<any>();
  @Output() setFieldTotalPage = new EventEmitter<any>();
  @Output() openCustomFieldPopup = new EventEmitter<any>();
  @Output() fieldPrev = new EventEmitter<any>();
  @Output() fieldNext = new EventEmitter<any>();
  @Output() onPivotSelect = new EventEmitter<any>();
  @Output() isCustomMeasureField = new EventEmitter<any>();
  @Output() toggleFilter = new EventEmitter<any>();
  @Output() openFieldDetailLayer = new EventEmitter<any>();

  public unescapeCustomColumnExpr(expr: string) {
    return StringUtil.unescapeCustomColumnExpr(expr);
  }
}
