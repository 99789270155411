import { DashboardDetailViewApiModel } from '@selfai-platform/bi-api';
import { Datasource, FieldNameAlias, FieldValueAlias } from '../../datasource';
import { widgetsApiResponseArrayToWidgetsNormalizer } from '../../widget';
import { Dashboard, createBoardConfiguration, createBoardSource, createDashboard } from '../models';

export function DashboardApiModelToDashboard(dashboardApi: DashboardDetailViewApiModel): Dashboard {
  const dashboard = createDashboard({
    id: dashboardApi.id,
    name: dashboardApi.name,
    description: dashboardApi.description || '',
    imageUrl: dashboardApi.imageUrl,
    main: dashboardApi.main || false,
    seq: dashboardApi.seq,
    configuration: createBoardConfiguration(<any>dashboardApi.configuration),
    aliases: (dashboardApi.aliases as (FieldNameAlias | FieldValueAlias)[]) || [],
    createdBy: dashboardApi.createdBy,
    createdTime: new Date(dashboardApi.createdTime),
    modifiedBy: dashboardApi.modifiedBy,
    modifiedTime: new Date(dashboardApi.modifiedTime),
    dataSources: (<unknown>dashboardApi.dataSources) as Datasource[],
    hiding: dashboardApi.hiding || false,
    dataSource: createBoardSource(),
    updateId: null,
    widgets: dashboardApi.widgets ? widgetsApiResponseArrayToWidgetsNormalizer(dashboardApi.widgets) : [],
  });
  return dashboard;
}
