import {
  AfterViewInit,
  Component,
  ElementRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import * as _ from 'lodash';

import { DatasourceField as Field, BoundFilter, Filter } from '@selfai-platform/bi-domain';

import { SubscribeArg } from '../../../common/domain/subscribe-arg';
import { PopupService } from '../../../common/service/popup.service';
import { DatasourceService } from '../../../datasource/service/datasource.service';
import { AbstractFilterPanelComponent } from '../abstract-filter-panel.component';

import { BoundFilterComponent } from './bound-filter.component';

@Component({
  selector: 'bound-filter-panel',
  templateUrl: './bound-filter-panel.component.html',
})
export class BoundFilterPanelComponent
  extends AbstractFilterPanelComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @ViewChild(BoundFilterComponent)
  private _boundFilterComp: BoundFilterComponent;

  @Input()
  public filter: BoundFilter;

  public targetFilter: BoundFilter;

  public isNewFilter = false;

  constructor(
    private popupService: PopupService,
    protected datasourceService: DatasourceService,
    protected elementRef: ElementRef,
    protected injector: Injector,
  ) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    this.targetFilter = this.filter;
    super.ngOnInit();
  }

  public ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    const filterChanges: SimpleChange = changes.originalFilter;
    if (filterChanges) {
      const currFilter: BoundFilter = _.cloneDeep(filterChanges.currentValue);

      this.setPanelData(currFilter);

      this.dataSource && this._candidate(currFilter);
    }
  }

  public ngAfterViewInit() {
    if (this.filter['isNew']) {
      this.isNewFilter = true;
      this.safelyDetectChanges();
      delete this.filter['isNew'];
      setTimeout(() => {
        this.isNewFilter = false;
        this.safelyDetectChanges();
      }, 1500);
    }

    const popupSubscribe = this.popupService.filterView$.subscribe((data: SubscribeArg) => {
      if (data.type === 'page' && this.isDashboardMode) return;

      const filter: Filter = data.data;

      if ('remove-filter' === data.name && this.targetFilter.ui.importanceType === 'general') {
        if (filter.field !== this.targetFilter.field) {
          this._candidate(this.targetFilter, 'reset');
        }
      } else if ('reset-general-filter' === data.name) {
        this._candidate(this.targetFilter, 'reset');
      }
    });

    this.subscriptions.push(popupSubscribe);
  }

  public resetFilter() {
    this.targetFilter = _.cloneDeep(this.filter);
    this.safelyDetectChanges();
    this.updateFilterEvent.emit(this.targetFilter);
  }

  public toggleDetailMenu() {
    this.isShowDetailMenu = !this.isShowDetailMenu;
  }

  public applyValue() {
    this.updateFilterEvent.emit(this._boundFilterComp.getData());
  }

  public deleteFilter(filter: Filter) {
    this.deleteFilterEvent.emit(filter);
  }

  private _candidate(filter: BoundFilter, type?: string) {
    if (filter && this.dashboard && this.field) {
      this.loadingShow();
      this.datasourceService
        .getCandidateForFilter(filter, this.dashboard, this.getFiltersParam(filter), this.field)
        .then((result) => {
          if (result && Object.prototype.hasOwnProperty.call(result, 'maxValue')) {
            if ((filter.min === 0 && filter.max === 0) || type === 'reset') {
              filter.min = result.minValue;
              filter.max = result.maxValue;
            }
            filter.maxValue = result.maxValue;
            filter.minValue = result.minValue;
          } else {
            filter.min = null;
            filter.max = null;
            filter.maxValue = null;
            filter.minValue = null;
          }

          this.targetFilter = filter;
          this.isShowFilter = true;
          this.safelyDetectChanges();
          this._boundFilterComp.setFilter(this.targetFilter);
          this.loadingHide();
        })
        .catch((error) => {
          this.commonExceptionHandler(error);

          this.isShowFilter = true;
        });
    }
  }
}
