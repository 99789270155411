import { DatasourceField } from '../../../../datasource';

export interface JoinMapping {
  id?: string;
  type?: JoinType;
  name?: string;
  joinAlias?: string;
  keyPair?: { [key: string]: string };
  join?: JoinMapping;
  engineName?: string;
  uiFields?: DatasourceField[];
}

export function createJoinMapping(joinMappingOptions: Partial<JoinMapping> = {}): JoinMapping {
  return {
    ...joinMappingOptions,
  };
}

export enum JoinType {
  INNER = 'INNER',
  LEFT_OUTER = 'LEFT_OUTER',
  RIGHT_OUTER = 'RIGHT_OUTER',
  FULL = 'FULL',
}
