<div
  [class.ddp-selected]="showDatasourcePanel"
  class="ddp-wrap-datasource"
  style="z-index: 126"
>
  <div class="ddp-label-datasorce" (click)="toggleDatasourcePanel()">
    {{ "msg.board.th.datasource" | translate }}
  </div>
  <div class="ddp-ui-drop-contents" *ngIf="showDatasourcePanel">
    <div class="ddp-wrap-source-name">
      <dashboard-datasource-combo
        [enableInfo]="true"
        [dataSources]="dataSourceList"
        [initialValue]="dataSource"
        [enableEditAssociationJoin]="true"
        (selectOption)="selectDataSource($event)"
        (showInfo)="onDataPreviewPopup()"
      ></dashboard-datasource-combo>
    </div>

    <div class="ddp-ui-drop-option">
      <component-input
        [compType]="'search'"
        [value]="searchText"
        [showClear]="false"
        [placeHolder]="'msg.board.ui.board.dsource.ph' | translate"
        (changeValue)="searchField($event)"
      ></component-input>
    </div>

    <div
      class="ddp-wrap-drop-submenu"
      [class.ddp-selected]="isSelectedDimension"
    >
      <div
        (click)="isSelectedDimension = !isSelectedDimension"
        class="ddp-ui-sub-title"
      >
        <div class="ddp-ui-sub-titlein ddp-dimension">
          <em class="ddp-icon-menumove"></em>
          {{ "msg.comm.name.dim" | translate }}
          <a
            (click)="
              $event.preventDefault();
              $event.stopImmediatePropagation();
              openCustomFieldPopup('DIMENSION')
            "
            href="javascript:"
            class="ddp-icon-add"
          ></a>
        </div>
      </div>

      <div *ngIf="isSelectedDimension" class="ddp-wrap-list-source">
        <ul class="ddp-list-source ddp-list-dimension">
          <li class="ddp-ui-dimension" *ngFor="let field of displayDimensions">
            <em class="ddp-icon-datause" *ngIf="field.useChart"></em>

            <selfai-bi-shared-field-icon
              [view]="'DIMENSION'"
              [type]="field.type"
              [logicalType]="field.logicalType"
            ></selfai-bi-shared-field-icon>

            <div class="ddp-wrap-icons">
              <div
                class="ddp-wrap-user-preview"
                *ngIf="field.type === 'user_expr'"
              >
                <em
                  class="ddp-icon-control-user ddp-type"
                  (click)="openCustomFieldPopup('DIMENSION', field)"
                ></em>
                <div class="ddp-ui-tooltip-info">
                  <em class="ddp-icon-view-top"></em>
                  <span class="ddp-txt-tooltip">{{
                    unescapeCustomColumnExpr(field.expr)
                  }}</span>
                </div>
              </div>

              <div *ngIf="field['assInfo']" class="ddp-wrap-user-preview">
                <em class="ddp-icon-control-link ddp-type"></em>
                <div class="ddp-ui-tooltip-info">
                  <em class="ddp-icon-view-top"></em>
                  <span
                    *ngFor="let info of field['assInfo']"
                    class="ddp-txt-tooltip"
                    >{{ info }}</span
                  >
                </div>
              </div>

              <em
                class="ddp-icon-control-filter ddp-type"
                *ngIf="!field.useChartFilter"
                (click)="toggleFilter($event, field)"
                [ngClass]="{ 'ddp-selected': field.useFilter }"
              ></em>

              <div class="ddp-btn-control">
                <em
                  class="ddp-icon-control-more ddp-type"
                  (click)="openFieldDetailLayer($event, field)"
                ></em>
              </div>
            </div>

            <span
              class="ddp-data-name"
              [class.ddp-alias]="
                field['nameAlias'] &&
                field['nameAlias']['nameAlias'] != field.name
              "
            >
              <span class="ddp-data-in">
                {{ field.name }}
                <em
                  *ngIf="
                    field['nameAlias'] &&
                    field['nameAlias']['nameAlias'] != field.name
                  "
                  class="ddp-icon-alias"
                >
                  ({{ field["nameAlias"]["nameAlias"] }})
                </em>
              </span>
            </span>
          </li>
        </ul>
        <div class="ddp-ui-page">
          <a href="javascript:" class="ddp-prev" (click)="prevDimPage()"
            >&lt; Previous</a
          >
          <a href="javascript:" class="ddp-next" (click)="nextDimPage()"
            >Next &gt;</a
          >
        </div>
      </div>
    </div>

    <div class="ddp-wrap-drop-submenu" [class.ddp-selected]="isSelectedMeasure">
      <div
        (click)="isSelectedMeasure = !isSelectedMeasure"
        class="ddp-ui-sub-title"
      >
        <div class="ddp-ui-sub-titlein ddp-measure">
          <em class="ddp-icon-menumove"></em>
          {{ "msg.comm.name.mea" | translate }}
          <a
            (click)="
              $event.preventDefault();
              $event.stopImmediatePropagation();
              openCustomFieldPopup('MEASURE')
            "
            href="javascript:"
            class="ddp-icon-add"
          ></a>
        </div>
      </div>

      <div *ngIf="isSelectedMeasure" class="ddp-wrap-list-source">
        <ul class="ddp-list-source ddp-list-measure">
          <li class="ddp-ui-measure" *ngFor="let field of displayMeasures">
            <em class="ddp-icon-datause" *ngIf="field.useChart"></em>

            <selfai-bi-shared-field-icon
              [view]="'MEASURE'"
              [type]="field.type"
              [logicalType]="field.logicalType"
            ></selfai-bi-shared-field-icon>

            <div class="ddp-wrap-icons">
              <div
                class="ddp-wrap-user-preview"
                *ngIf="field.type === 'user_expr'"
              >
                <em
                  class="ddp-icon-control-calculat ddp-type"
                  (click)="openCustomFieldPopup('MEASURE', field)"
                ></em>
                <div class="ddp-ui-tooltip-info">
                  <em class="ddp-icon-view-top"></em>
                  <span class="ddp-txt-tooltip">{{ field.expr }}</span>
                </div>
              </div>

              <div *ngIf="field['assInfo']" class="ddp-wrap-user-preview">
                <em class="ddp-icon-control-link ddp-type"></em>
                <div class="ddp-ui-tooltip-info">
                  <em class="ddp-icon-view-top"></em>
                  <span
                    *ngFor="let info of field['assInfo']"
                    class="ddp-txt-tooltip"
                    >{{ info }}</span
                  >
                </div>
              </div>

              <em
                *ngIf="!field.useChartFilter && !field['isCustomMeasure']"
                (click)="toggleFilter($event, field)"
                [ngClass]="{
                  'ddp-selected': field.useFilter,
                  'ddp-disabled': field.aggregated
                }"
                class="ddp-icon-control-filter ddp-type"
              ></em>

              <div class="ddp-btn-control">
                <em
                  class="ddp-icon-control-more ddp-type"
                  (click)="openFieldDetailLayer($event, field)"
                ></em>
              </div>
            </div>

            <span
              class="ddp-data-name"
              [class.ddp-alias]="
                field['nameAlias'] &&
                field['nameAlias']['nameAlias'] != field.name
              "
            >
              <span class="ddp-data-in">
                {{ field.name }}
                <em
                  *ngIf="
                    field['nameAlias'] &&
                    field['nameAlias']['nameAlias'] != field.name
                  "
                  class="ddp-icon-alias"
                >
                  ({{ field["nameAlias"]["nameAlias"] }})
                </em>
              </span>
            </span>
          </li>
        </ul>
        <div class="ddp-ui-page">
          <a href="javascript:" class="ddp-prev" (click)="prevMeaPage()"
            >&lt; Previous</a
          >
          <a href="javascript:" class="ddp-next" (click)="nextMeaPage()"
            >Next &gt;</a
          >
        </div>
      </div>
    </div>
  </div>
</div>

<data-preview
  *ngIf="isShowDataPreview"
  [field]="selectedField"
  [singleTab]="isColumnDetail"
  [source]="dashboard"
  [initial]="dataSource"
  (close)="isShowDataPreview = false"
></data-preview>
<app-custom-field
  *ngIf="isShowCustomFiled"
  [fields]="dsFields"
  [dataSource]="boardDs"
  [customFields]="dsCustomFields"
  [customField]="selectedCustomField"
  [selectedColumnType]="customFieldPopupType"
  (updateColumn)="updateCustomField($event)"
  (closeDialog)="isShowCustomFiled = false"
></app-custom-field>

<page-data-context
  (openCustomFieldEvent)="openCustomFieldPopupFromContext($event)"
  (openColumnDetailEvent)="onColumnDetailPopup($event)"
  (deleteCustomFieldEvent)="deleteCustomField($event)"
  (changeAlias)="changeDatasourceFieldAlias($event)"
></page-data-context>
