<div class="ddp-ui-divide">
  <div
    class="ddp-ui-down-title2"
    [attr.data-gl-dragsource]="isDraggable ? 'enable' : 'disable'"
    [class.ddp-selected]="isNewFilter"
    [ngStyle]="!isDashboardMode ? { cursor: 'default' } : {}"
  >
    <div *ngIf="targetFilter" class="ddp-ui-buttons">
      <em class="ddp-icon-global-s" *ngIf="isBoardFilter"></em>

      <div
        *ngIf="targetFilter.ui?.filteringSeq > 0"
        class="ddp-wrap-datarecommend"
      >
        {{ targetFilter.ui?.filteringSeq }}
        <em class="ddp-icon-recommend-s2"></em>
      </div>

      <div
        class="ddp-wrap-morebutton ddp-hover-tooltip"
        [class.ddp-selected]="isShowDetailMenu"
        (click)="toggleDetailMenu()"
        (clickOutside)="isShowDetailMenu = false"
      >
        <em class="ddp-icon-more"></em>
        <div class="ddp-ui-tooltip-info ddp-down">
          <em class="ddp-icon-view-top"></em>
          {{ "msg.comm.ui.more" | translate }}
        </div>

        <div class="ddp-wrap-popup2 ddp-types">
          <ul class="ddp-list-popup">
            <li *ngIf="!isDashboardMode && isBoardFilter && isChangeable">
              <a href="javascript:" (click)="changeGlobalToChart(targetFilter)">
                <em class="ddp-icon-drop-global2"></em>
                {{ "msg.board.filter.ui.turn.chart" | translate }}
              </a>
            </li>
            <li *ngIf="false">
              <a href="javascript:" (click)="changeChartToGlobal(targetFilter)">
                <em class="ddp-icon-drop-global"></em>
                {{ "msg.board.filter.ui.turn.global" | translate }}
              </a>
            </li>
            <li>
              <a href="javascript:" (click)="resetFilter(targetFilter)">
                <em class="ddp-icon-drop-refresh"></em>
                {{ "msg.board.filter.ui.reset" | translate }}
              </a>
            </li>
            <li *ngIf="isEditable">
              <a href="javascript:" (click)="openUpdateFilterPopup(filter)">
                <em class="ddp-icon-drop-editmodify"></em>
                {{ "msg.board.filter.ui.edit" | translate }}
              </a>
            </li>
            <li *ngIf="isDeletable">
              <a href="javascript:" (click)="deleteFilter(targetFilter)">
                <em class="ddp-icon-drop-editdel3"></em>
                {{ "msg.board.filter.ui.remove" | translate }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div *ngIf="targetFilter" class="ddp-itemtype-title">
      <div class="ddp-data-itemtype ddp-type">
        <span class="ddp-icon-box ddp-measure">
          <selfai-bi-shared-field-icon
            *ngIf="field"
            [view]="'MEASURE'"
            [type]="field.type"
            [logicalType]="field.logicalType"
          ></selfai-bi-shared-field-icon>
          <em class="ddp-icon-use3" *ngIf="isWidgetInLayout"></em>
        </span>
        <span class="ddp-txt-itemtype">
          <span class="ddp-data-name">{{ targetFilter.field }}</span>
          <span class="ddp-data-sub">{{ dataSource?.name }}</span>
          <span *ngIf="!isBoardFilter" class="ddp-txt-chartname">
            {{ "msg.board.ui.filter.used-this-chart" | translate }}
          </span>
        </span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isShowFilter" class="ddp-contents-divide">
  <div class="ddp-ui-divide">
    <bound-filter
      [filter]="targetFilter"
      (changeFilterData)="applyValue()"
    ></bound-filter>
  </div>
</div>
