import { DashboardApiService } from '@selfai-platform/bi-api';
import { Dashboard } from '../models';
import { DestroyService } from '@selfai-platform/shared';
import { Observable, map, takeUntil, tap } from 'rxjs';
import { DashboardApiModelToDashboard } from '../normalizers/dashboard-api-to-dashboard.normalizer';
import { Injectable } from '@angular/core';
import { WidgetApiToDomainService } from '../../widget';

@Injectable({ providedIn: 'root' })
export class DashboardApiToDomainService {
  constructor(
    private readonly dashboardApiService: DashboardApiService,
    private readonly widgetSorageService: WidgetApiToDomainService,
    private readonly $destroy: DestroyService,
  ) {}

  getDashboard(id: string): Observable<Dashboard> {
    return this.dashboardApiService.getDashboardDetailView(id).pipe(
      takeUntil(this.$destroy),
      tap((dashboard) => {
        this.widgetSorageService.initDasboardWidgets(dashboard.widgets);
      }),
      map((dashboard) => {
        return DashboardApiModelToDashboard(dashboard);
      }),
    );
  }

  getDashboardByWidgetId(widgetId: string): Observable<Dashboard> {
    return this.dashboardApiService.getDashboardByWidgetId(widgetId).pipe(
      takeUntil(this.$destroy),
      tap((dashboard) => {
        this.widgetSorageService.initDasboardWidgets(dashboard.widgets);
      }),
      map((dashboard) => {
        return DashboardApiModelToDashboard(dashboard);
      }),
    );
  }
}
