import { Routes } from '@angular/router';

import { AppAuthGuard } from '@selfai-platform/shell';

import { AppComponent } from './app.component';
import { ConnectionListGuard } from './common/gaurd/connection-list.guard';
import { DatasourceManagementGuard } from './common/gaurd/datasource-management.guard';
import { SsoKeycloakGuard } from './common/gaurd/sso-keycloak.guard';
import { StagedbEnabledGuard } from './common/gaurd/stagedb-enabled.guard';
import { MainDashboardComponent } from './dashboard/component/main-dashboard/main-dashboard.component';
import { LayoutComponent } from './layout/layout.component';

export function getBiRoutes() {}

export const BiRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/main-dashboard',
  },
  {
    path: 'main-dashboard',
    pathMatch: 'full',
    canActivate: [AppAuthGuard],
    loadChildren: () =>
      import('./dashboard/component/main-dashboard/main-dashboard.module').then((m) => m.MainDashboardComponentModule),
    component: MainDashboardComponent,
  },
  {
    path: 'kd',
    canActivate: [AppAuthGuard],
    children: [
      {
        path: 'charts',
        loadChildren: () => import('./chart-list/chart-list.module').then((m) => m.ChartListModule),
      },
      {
        path: 'dashboards',
        loadChildren: () => import('./dashboard-list/dashboard.module').then((m) => m.DashboardListModule),
      },
      {
        path: 'preview',
        children: [
          {
            path: 'dashboard/:dashboardId',
            loadChildren: () =>
              import('./dashboard/component/dashboard-preview').then((m) => m.DashboardPreviewComponentModule),
          },
          {
            path: 'widget/:widgetId',
            loadChildren: () => import('./widget-preview').then((m) => m.WidgetPreviewComponentModule),
          },
        ],
      },
    ],
  },
  {
    path: 'bi',
    canActivate: [AppAuthGuard],
    component: AppComponent,
    children: [
      // NOT Used now. Maybe will be used in future. Do not remove commented routes
      //   {
      //     path: 'dashboard',
      //     loadChildren: () => import('./embedded/embedded-view.module').then((m) => m.EmbeddedViewModule),
      //   },
      //   {
      //     path: 'embedded',
      //     loadChildren: () => import('./embedded/embedded-view.module').then((m) => m.EmbeddedViewModule),
      //   },
      //   {
      //     path: 'edit-page',
      //     loadChildren: () => import('./page/page.module').then((m) => m.PageModule),
      //   },
      {
        path: '',
        component: LayoutComponent,
        canActivate: [SsoKeycloakGuard, StagedbEnabledGuard, ConnectionListGuard],
        children: [
          { path: '', redirectTo: 'workspace', pathMatch: 'full' },
          {
            path: 'workspace',
            loadChildren: () => import('./workspace/workspace.module').then((m) => m.WorkspaceModule),
          },
          {
            path: 'workbook',
            loadChildren: () => import('./workbook/workbook.module').then((m) => m.WorkbookModule),
          },
          {
            path: 'management/storage',
            loadChildren: () => import('./data-storage/data-storage.module').then((m) => m.DataStorageModule),
            canActivate: [DatasourceManagementGuard],
          },
          {
            path: 'management/datapreparation',
            loadChildren: () =>
              import('./data-preparation/data-preparation.module').then((m) => m.DataPreparationModule),
          },

          // NOT Used now. Maybe will be used in future. Do not remove commented routes
          //   {
          //     path: 'management/model',
          //     loadChildren: () =>
          //       import('./model-management/model-management.module').then((m) => m.ModelManagementModule),
          //     canActivate: [DatasourceManagementGuard],
          //   },
          {
            path: 'management/monitoring',
            loadChildren: () => import('./monitoring/monitoring.module').then((m) => m.MonitoringModule),
            //canActivate: [DatasourceManagementGuard],
          },
          //   {
          //     path: 'management/metadata',
          //     loadChildren: () =>
          //       import('./meta-data-management/meta-data-management.module').then((m) => m.MetaDataManagementModule),
          //     canActivate: [MetadataManagementGuard],
          //   },
          {
            path: 'management/engine-monitoring',
            loadChildren: () =>
              import('./engine-monitoring/engine-monitoring.module').then((m) => m.EngineMonitoringModule),
            //canActivate: [DatasourceManagementGuard],
          },
          //   { path: 'admin', loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule) },
          //   {
          //     path: 'notebook',
          //     loadChildren: () => import('./notebook/notebook.module').then((m) => m.NotebookModule),
          //   },
          //   {
          //     path: 'workbench',
          //     loadChildren: () => import('./workbench/workbench.module').then((m) => m.WorkbenchModule),
          //   },
          {
            path: 'exploredata',
            loadChildren: () => import('./explore-data/explore-data.module').then((m) => m.ExploreDataModule),
          },
        ],
      },
      { path: '**', redirectTo: '', pathMatch: 'full' },
    ],
  },
];
