import { ChangeDetectionStrategy, Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';

import $ from 'jquery';
import _ from 'lodash';
import pixelWidth from 'string-pixel-width';

import {
  BoardDataSource,
  convertDsToMetaDs,
  createBoardSource,
  createJoinMapping,
  createJoinMappingDataSource,
  createQueryParam,
  JoinMapping,
  JoinMappingDataSource,
  Datasource,
  DatasourceField as Field,
  FieldRole,
  LogicalType,
  createDatasourceField,
  JoinType,
} from '@selfai-platform/bi-domain';
import { DialogService } from '@selfai-platform/shell';

import { AbstractPopupComponent } from '../../../common/component/abstract-popup.component';
import { GridComponent } from '../../../common/component/grid/grid.component';
import { header, SlickGridHeader } from '../../../common/component/grid/grid.header';
import { GridOption } from '../../../common/component/grid/grid.option';
import { CommonConstant } from '../../../common/constant/common.constant';
import { CommonUtil } from '../../../common/util/common.util';
import { StringUtil } from '../../../common/util/string.util';
import { DatasourceService } from '../../../datasource/service/datasource.service';

import { AddJoinDataSource, EditJointDataSource } from './join-data-source.model';

@Component({
  selector: 'create-board-pop-join',
  templateUrl: './create-board-pop-join.component.html',
  styleUrls: ['./create-board-pop-join.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateBoardPopJoinComponent extends AbstractPopupComponent implements OnInit, OnDestroy {
  @ViewChild('leftGrid')
  private leftGrid: GridComponent;

  @ViewChild('rightGrid')
  private rightGrid: GridComponent;

  @ViewChild('joinPreview')
  private joinPreview: GridComponent;

  private _dataSource: BoardDataSource;
  private _queryLimit = 1000;
  private _similarity: SimilarityInfo[];
  private _joinMappings: JoinMapping[] = [];
  private _joinMapping: JoinMapping;

  private _candidateDataSources: Datasource[];

  isEmptyPreviewGrid = false;
  isShowJoinPopup = false;
  isShowTypes = false;

  editingJoin: EditJoin;

  logicalTypeMap: any = {};

  constructor(
    elementRef: ElementRef,
    injector: Injector,
    private datasourceService: DatasourceService,
    private readonly dialogService: DialogService<JoinMappingDataSource, AddJoinDataSource | EditJointDataSource>,
  ) {
    super(elementRef, injector);
  }

  ngOnInit() {
    super.ngOnInit();

    const data = this.dialogService.data;

    if (data) {
      if (this.isEditingJoin(data)) {
        this.editJoin(data);
      } else {
        this.addJoin(data);
      }
    } else {
      console.error('No data provided to join dialog');
    }
  }

  closeDialog(): void {
    this.dialogService.close();
    this.editingJoin = null;
  }

  addJoin({ dataSource, candidateDataSources, joinMappings, leftDataSource, join }: AddJoinDataSource) {
    this._dataSource = dataSource;
    this._candidateDataSources = candidateDataSources;
    this._joinMappings = joinMappings;
    this._joinMapping = join;
    this._queryLimit = 100;
    this.editingJoin = new EditJoin();
    this.editingJoin.isEdit = false;
    this.editingJoin.left = leftDataSource;
    this._queryData(this.editingJoin.left.engineName, this.editingJoin.left.temporary)
      .then((data) => {
        this.updateGrid(data[0], this.editingJoin.left.uiFields, 'left');
        this.changeDetect.detectChanges();
      })
      .catch((err) => this.showErrorMsgForJoin(err));
  }

  editJoin({
    dataSource,
    candidateDataSources,
    joinMappings,
    leftDataSource,
    rightDataSource,
    join,
    targetDsId,
  }: EditJointDataSource) {
    this._dataSource = dataSource;
    this._joinMappings = joinMappings;
    this._candidateDataSources = candidateDataSources;

    const editJoin: EditJoin = new EditJoin();
    editJoin.left = leftDataSource;
    editJoin.right = rightDataSource;
    editJoin.isEdit = true;
    const joinIdx: number = this._joinMappings.findIndex((mapping) => mapping.id === targetDsId);
    if (-1 < joinIdx) {
      editJoin.joinMappingIdx = joinIdx;
      this._joinMapping = this._joinMappings[joinIdx];
    } else {
      editJoin.joinMappingIdx = this._joinMappings.findIndex((mapping) => mapping.id === join.id);
      this._joinMapping = null;
    }

    if (join.type === 'LEFT_OUTER') {
      editJoin.selectedJoinType = 'left';
    } else if (join.type === 'INNER') {
      editJoin.selectedJoinType = 'inner';
    }

    const joinInfoList: JoinInfo[] = [];
    Object.keys(join.keyPair).forEach((key) => {
      const joinInfo: JoinInfo = new JoinInfo();
      joinInfo.leftJoinKey = key;
      joinInfo.rightJoinKey = join.keyPair[key];
      joinInfoList.push(joinInfo);
    });
    editJoin.joinInfoList = joinInfoList;

    this.editingJoin = editJoin;

    const promises = [];

    promises.push(
      new Promise<void>((res, rej) => {
        this._queryLimit = 100;
        this._queryData(this.editingJoin.left.engineName, this.editingJoin.left.temporary)
          .then((data) => {
            this.updateGrid(data[0], this.editingJoin.left.uiFields, 'left');
            res();
          })
          .catch(rej);
      }),
    );

    promises.push(
      new Promise<void>((res, rej) => {
        this._queryLimit = 100;
        this._queryData(this.editingJoin.right.engineName)
          .then((data) => {
            this.updateGrid(data[0], this.editingJoin.right.uiFields, 'right');
            res();
          })
          .catch(rej);
      }),
    );

    promises.push(
      new Promise<void>((res, rej) => {
        this.editingJoin.rowNum = 1000;
        this._loadDataToPreviewGrid(false).then(res).catch(rej);
      }),
    );

    this.loadingShow();
    CommonUtil.waterfallPromise(promises)
      .then(() => {
        this.loadingHide();
        this.changeDetect.detectChanges();
      })
      .catch(() => this.loadingHide());
  }

  getGridFields(fields: Field[]) {
    return fields.filter((item) => item.name !== CommonConstant.COL_NAME_CURRENT_DATETIME);
  }

  get joinCandidateKeysOptionsLeft(): { name: string }[] {
    return this.editingJoin.left
      ? this.getJoinCandidateKeys(this.editingJoin.left.uiFields).map(({ name }) => ({ name }))
      : [];
  }

  get joinCandidateKeysOptionsRight(): { name: string }[] {
    return this.editingJoin.right
      ? this.getJoinCandidateKeys(this.editingJoin.right.uiFields).map(({ name }) => ({ name }))
      : [];
  }

  getJoinCandidateKeys(fields: Field[]) {
    return fields.filter((item) => {
      return (
        item.logicalType !== LogicalType.TIMESTAMP &&
        item.role !== FieldRole.MEASURE &&
        item.logicalType !== LogicalType.GEO_POINT &&
        item.logicalType !== LogicalType.GEO_LINE &&
        item.logicalType !== LogicalType.GEO_POLYGON &&
        item.name !== CommonConstant.COL_NAME_CURRENT_DATETIME
      );
    });
  }

  get filteredCandidateDatasources() {
    if (StringUtil.isNotEmpty(this.editingJoin.joinSearchText)) {
      return this._candidateDataSources.filter((ds) => {
        return ds.name.indexOf(this.editingJoin.joinSearchText) > -1;
      });
    }
    return this._candidateDataSources;
  }

  completeJoin() {
    if (!this.editingJoin.right || 0 === this.editingJoin.joinInfoList.length) {
      this.alertPrimeService.warn(this.translateService.instant('msg.board.alert.join.complete.error'));
      return;
    }

    if (this.editingJoin.tempJoinMappings) {
      this.dialogService.close(
        createJoinMappingDataSource(this.editingJoin.tempJoinMappings, this._candidateDataSources),
      );
    } else {
      this.closeDialog();
    }
  }

  joinPopupLeftName() {
    let leftName = this.editingJoin.left.name;
    if (0 < this.editingJoin.left.joins.length) {
      leftName = leftName + ' - ' + this.editingJoin.left.joins[0].name;
    }
    return leftName;
  }

  openOptionsJoinDatasources() {
    this.editingJoin.isJoinDatasourceFl = true;
    this.editingJoin.joinSearchText = '';

    setTimeout(() => $('#joinSearchText').trigger('focus'));
  }

  loadDataToRightJoinGrid(ds: Datasource) {
    if (this.editingJoin.right && this.editingJoin.right.id === ds.id) {
      return;
    }

    this.rightGrid && this.rightGrid.destroy();
    this.editingJoin.right = convertDsToMetaDs(ds);
    this._queryLimit = 100;
    this._queryData(ds.engineName)
      .then((data) => {
        this.editingJoin.isJoinDatasourceFl = false;

        this.editingJoin.joinInfoList = [];
        this._initPreview();

        this.updateGrid(data[0], this.editingJoin.right.uiFields, 'right');

        this.datasourceService
          .getDatasourceSimilarity(this.editingJoin.left.engineName, this.editingJoin.right.engineName)
          .then((similarity) => {
            this._similarity = similarity;
            this._setSimilarity();
            this.changeDetect.detectChanges();
          })
          .catch((err) => {
            //this.showErrorMsgForJoin(err);
            console.warn(err);
            this._similarity = [];
            this._setSimilarity();
          });
      })
      .catch((err) => this.showErrorMsgForJoin(err));
  }

  selectJoinColumn(name: string, direction: 'left' | 'right') {
    if ('left' === direction) {
      this.editingJoin.leftJoinKey = name;
      this.editingJoin.isJoinLeftFl = false;
    } else {
      this.editingJoin.rightJoinKey = name;
      this.editingJoin.isJoinRightFl = false;
    }
  }

  isValidJoinKeys() {
    if ('' !== this.editingJoin.leftJoinKey.trim() && '' !== this.editingJoin.rightJoinKey.trim()) {
      const leftField = this.getGridFields(this.editingJoin.left.uiFields).find(
        (item) => item.name === this.editingJoin.leftJoinKey,
      );
      const rightField = this.getGridFields(this.editingJoin.right.uiFields).find(
        (item) => item.name === this.editingJoin.rightJoinKey,
      );
      if (leftField && rightField) {
        const numberTypes = ['INT', 'INTEGER', 'LONG', 'DOUBLE', 'FLOAT'];
        const leftType = leftField.logicalType
          ? -1 < numberTypes.indexOf(leftField.logicalType.toString())
            ? 'number'
            : leftField.logicalType.toString()
          : '';
        const rightType = rightField.logicalType
          ? -1 < numberTypes.indexOf(rightField.logicalType.toString())
            ? 'number'
            : rightField.logicalType.toString()
          : '';
        return leftType === rightType;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  addToJoinKeys() {
    if (!this.isValidJoinKeys()) {
      this.editingJoin.joinChooseColumnErrorFl = true;
      return;
    }

    const info = new JoinInfo();
    info.leftJoinKey = this.editingJoin.leftJoinKey;
    info.rightJoinKey = this.editingJoin.rightJoinKey;
    this.editingJoin.joinInfoList.push(info);

    this.editingJoin.leftJoinKey = '';
    this.editingJoin.rightJoinKey = '';
    this.editingJoin.joinChooseColumnErrorFl = false;

    this._setSimilarity();

    this.editingJoin.rowNum = 1000;
    this._loadDataToPreviewGrid().then();
  }

  setRowPreviewGrid(rowNum: number) {
    if (Number(rowNum) !== this.editingJoin.rowNum) {
      this.editingJoin.rowNum = rowNum;

      this._loadDataToPreviewGrid().then();
    }
  }

  changeJoinType(type) {
    if (this.editingJoin.selectedJoinType === type) return;
    this.editingJoin.selectedJoinType = type;
    if (this.editingJoin.joinInfoList.length !== 0) {
      this.editingJoin.rowNum = 1000;
      this._loadDataToPreviewGrid().then();
    }
  }

  removeJoinInfoList(idx) {
    this.editingJoin.joinInfoList.splice(idx, 1);
    if (this.editingJoin.joinInfoList.length !== 0) {
      this.editingJoin.rowNum = 1000;
      this._loadDataToPreviewGrid().then();
    } else {
      this._initPreview();
    }
    this._setSimilarity();
  }

  highlightSearchText(name, searchText): string {
    return name.replace(new RegExp('(' + searchText + ')'), '<span class="ddp-txt-search">$1</span>');
  }

  objKeyList(obj) {
    return obj ? Object.keys(obj) : [];
  }

  private isEditingJoin(data: EditJointDataSource | AddJoinDataSource): data is EditJointDataSource {
    return Boolean((data as EditJointDataSource).targetDsId);
  }

  private _setSimilarity() {
    const sim: SimilarityInfo[] = this._similarity;
    const joinInfos: JoinInfo[] = this.editingJoin.joinInfoList;

    this.editingJoin.leftJoinKey = '';
    this.editingJoin.rightJoinKey = '';

    if (this._similarity && 0 < this._similarity.length) {
      const unuseSims: SimilarityInfo[] = sim.filter((item) => {
        return (
          0 ===
          joinInfos.filter((info) => {
            return item.to.split('.')[1] === info.leftJoinKey && item.from.split('.')[1] === info.rightJoinKey;
          }).length
        );
      });
      unuseSims.sort((prev, next) => {
        if (prev.similarity > next.similarity) {
          return -1;
        } else if (prev.similarity < next.similarity) {
          return 1;
        } else {
          return 0;
        }
      });
      if (unuseSims && 0 < unuseSims.length) {
        this.editingJoin.leftJoinKey = unuseSims[0].to.split('.')[1];
        this.editingJoin.rightJoinKey = unuseSims[0].from.split('.')[1];
      }
    }
  }

  private _initPreview() {
    this.joinPreview && this.joinPreview.destroy();
    this.isEmptyPreviewGrid = false;
  }

  private _loadDataToPreviewGrid(loading: boolean = true): Promise<any> {
    return new Promise<void>((res, rej) => {
      this._initPreview();

      if (0 === this.editingJoin.joinInfoList.length) {
        this.alertPrimeService.warn(this.translateService.instant('msg.board.alert.join.setting.error'));
        return;
      }

      this.logicalTypeMap = []
        .concat(this.editingJoin.left.uiFields)
        .concat(this.editingJoin.right.uiFields)
        .reduce((acc, item) => {
          acc[item.logicalType] = acc[item.logicalType] ? acc[item.logicalType] + 1 : 1;
          return acc;
        }, {});

      const joinInfo = createJoinMapping();
      joinInfo.keyPair = {};
      joinInfo.id = this.editingJoin.right.id;
      joinInfo.name = this.editingJoin.right.engineName;
      joinInfo.type = this.editingJoin.joinType as JoinType;

      const paramJoins: JoinMapping[] = _.cloneDeep(this._joinMappings);
      if (this._joinMapping) {
        paramJoins.some((mapping: JoinMapping) => {
          if (mapping.id === this.editingJoin.left.id) {
            this.editingJoin.joinInfoList.forEach((item) => {
              joinInfo.keyPair[item.leftJoinKey] = item.rightJoinKey;
            });
            joinInfo.joinAlias = mapping.joinAlias + '_1';
            mapping.join = joinInfo;
            return true;
          }
        });
      } else {
        this.editingJoin.joinInfoList.forEach((item) => {
          joinInfo.keyPair[item.leftJoinKey] = item.rightJoinKey;
        });
        if (this.editingJoin.isEdit) {
          joinInfo.joinAlias = paramJoins[this.editingJoin.joinMappingIdx].joinAlias;
          paramJoins[this.editingJoin.joinMappingIdx] = joinInfo;
        } else {
          joinInfo.joinAlias = 'join_' + (paramJoins.length + 1);
          paramJoins.push(joinInfo);
        }
      }

      this.editingJoin.tempJoinMappings = paramJoins;

      this._queryLimit = this.editingJoin.rowNum;

      this._queryData(paramJoins)
        .then((data) => {
          this.editingJoin.columnCnt = data[1].length;

          if (0 < data[0].length && 0 < data[1].length) {
            this.isEmptyPreviewGrid = false;
            this.editingJoin.rowNum > data[0].length && (this.editingJoin.rowNum = data[0].length);
            this.updateGrid(data[0], data[1], 'joinPreview');
          } else {
            this.editingJoin.rowNum = 0;
            this.isEmptyPreviewGrid = true;
          }

          this.safelyDetectChanges();

          res && res();
          loading && this.loadingHide();
        })
        .catch((err) => {
          if (err && err.message) {
            this.alertPrimeService.error(err.message);
          } else {
            this.alertPrimeService.error(this.translateService.instant('msg.alert.retrieve.fail'));
          }
          rej && rej();
          loading && this.loadingHide();
        });
    });
  }

  private _queryData(
    queryTarget: JoinMapping[] | string,
    isTemporary: boolean = false,
    loading: boolean = true,
  ): Promise<[any, Field[]]> {
    return new Promise<any>((res, rej) => {
      let joins: JoinMapping[] = null;
      let dsName: string = null;
      if (queryTarget instanceof Array) {
        joins = queryTarget;
      } else {
        dsName = queryTarget;
      }

      const params = createQueryParam();
      params.limits.limit = this._queryLimit;

      params.dataSource = createBoardSource();
      params.dataSource.type = 'default';
      if (dsName) {
        params.dataSource.name = dsName;
        params.dataSource.temporary = isTemporary;
      } else {
        params.dataSource.name = this._dataSource.engineName;
        params.dataSource.temporary = this._dataSource.temporary;
      }

      if (joins && joins.length > 0) {
        params.dataSource.type = 'mapping';
        params.dataSource['joins'] = joins;
      }

      loading && this.loadingShow();
      this.datasourceService
        .getDatasourceQuery(params)
        .then((data) => {
          let fieldList: Field[] = [];
          if (data && 0 < data.length) {
            fieldList = Object.keys(data[0]).map((keyItem) => {
              const tempInfo = createDatasourceField({
                name: keyItem,
              });
              return tempInfo;
            });
          }
          res([data, fieldList]);
          this.loadingHide();
        })
        .catch((err) => {
          rej(err);
          this.loadingHide();
        });
    });
  }

  private updateGrid(data: any, fields: Field[], targetGrid: string = 'main') {
    const headers: header[] = this.getGridFields(fields).map((field: Field) => {
      const headerWidth: number = Math.floor(pixelWidth(field.name, { size: 12 })) + 62;
      return new SlickGridHeader()
        .Id(field.name)
        .Name(field.name)
        .Field(field.name)
        .Behavior('select')
        .Selectable(false)
        .CssClass('cell-selection')
        .Width(headerWidth)
        .CannotTriggerInsert(true)
        .Resizable(true)
        .Unselectable(true)
        .Sortable(true)
        .build();
    });

    let rows: any[] = data;

    if (data.length > 0 && !data[0].hasOwnProperty('id')) {
      rows = rows.map((row: any, idx: number) => {
        row.id = idx;
        return row;
      });
    }

    let grid: GridComponent;

    if (targetGrid === 'left') {
      grid = this.leftGrid;
    } else if (targetGrid === 'right') {
      grid = this.rightGrid;
    } else if (targetGrid === 'joinPreview') {
      grid = this.joinPreview;
    }

    grid.destroy();

    if (0 < headers.length) {
      grid.create(
        headers,
        rows,
        new GridOption().SyncColumnCellResize(true).MultiColumnSort(true).RowHeight(32).build(),
      );

      if (0 === rows.length) {
        grid.invalidateAllRows();
        grid.elementRef.nativeElement.querySelector('.grid-canvas').innerHTML =
          '<div class="ddp-data-empty"><span class="ddp-data-contents">' +
          this.translateService.instant('msg.space.ui.no.data') +
          '</span></div>';
      }
    }
  }

  private showErrorMsgForJoin(err) {
    err.message = this.translateService.instant('msg.space.alert.join.msg');
    this.commonExceptionHandler(err);
  }
}

class EditJoin {
  left: BoardDataSource;
  right: BoardDataSource | JoinMapping;

  viewModeForLeft = 'grid';
  viewModeForRight = 'grid';
  isJoinDatasourceFl = false;
  isJoinLeftFl = false;
  isJoinRightFl = false;
  joinSearchText = '';

  leftJoinKey = '';
  rightJoinKey = '';
  joinInfoList: JoinInfo[] = [];
  selectedJoinType = 'left';
  joinChooseColumnErrorFl = false;

  columnCnt = 0;
  rowNum = 1000;

  tempJoinMappings: JoinMapping[];

  isEdit = false;
  joinMappingIdx = -1;

  get joinType(): string {
    if (this.selectedJoinType === 'left') {
      return 'LEFT_OUTER';
    } else if (this.selectedJoinType === 'inner') {
      return 'INNER';
    } else {
      throw new Error(`지원되지 않는 타입 > ${this.selectedJoinType}`);
    }
  }
}

class JoinInfo {
  leftJoinKey: string;
  rightJoinKey: string;
}

class SimilarityInfo {
  from: string;
  similarity: number;
  to: string;
}
