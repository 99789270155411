<div class="ddp-ui-contentsin ddp-full page-dashboard-edit">
  <div *ngIf="dashboard" class="ddp-wrap-naviarea ddp-editnavi ddp-clear ddp-board-naviarea">
    <em class="ddp-icon-dashboard2"></em>
    <div class="ddp-ui-naviarea">
      <div
        class="ddp-wrap-naviname"
        [ngClass]="{ 'ddp-selected': isShowDashboardList }"
        (click)="isShowDashboardList = !isShowDashboardList"
        (clickOutside)="isShowDashboardList = false"
      >
        <span class="ddp-data-naviname">
          {{ dashboard.name }}
          <em class="ddp-icon-menuview"></em>
        </span>

        <div class="ddp-wrap-popup2 ddp-wrap-dashname-popup" *ngIf="isShowDashboardList">
          <div class="ddp-ui-top-setting" (click)="$event.stopPropagation()">
            <span class="ddp-data-name">
              {{ searchText || ('msg.comm.ui.dashboard' | translate) }}
              <em class="ddp-data-num">({{ (dashboards | baseFilter: ['name', searchText]).length }})</em>
            </span>

            <div class="ddp-btn-control" *ngIf="!isSearchMode">
              <em class="ddp-icon-editsearch" (click)="isSearchMode = true; safelyDetectChanges()"></em>
              <em class="ddp-icon-editadd" (click)="moveOrNewDashboard()"></em>
            </div>

            <component-input
              *ngIf="isSearchMode"
              [compType]="'search'"
              [value]="searchText"
              [immediately]="true"
              [placeHolder]="'msg.board.custom.ui.placeholder' | translate"
              (changeValue)="searchText = $event"
              (pressEnter)="isSearchMode = false; safelyDetectChanges()"
            ></component-input>
          </div>

          <ul class="ddp-list-popup ddp-list-imgchart">
            <li
              *ngFor="let dashboardItem of dashboards | baseFilter: ['name', searchText]"
              [ngClass]="{ 'ddp-selected': dashboard.id === dashboardItem.id }"
            >
              <a href="javascript:" (click)="moveOrNewDashboard(dashboardItem)">
                <span class="ddp-wrap-images">
                  <selfai-bi-dashbord-preview-image
                    class="ddp-img-chart"
                    [imageUrl]="dashboardItem?.imageUrl"
                  ></selfai-bi-dashbord-preview-image>
                </span>
                <span class="ddp-data-name">{{ dashboardItem.name }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="ddp-box-navidet" [title]="dashboard.description">
        <div class="ddp-wrap-navidet">
          <span class="ddp-data-navidet">{{ dashboard.description }}</span>
        </div>
      </div>
    </div>

    <div class="ddp-ui-navibuttons">
      <div class="ddp-ui-fix-buttons">
        <ul class="ddp-list-buttons2">
          <li>
            <a href="javascript:" class="ddp-btn-list" (click)="isAppendLayout = true; addChart()">
              <em class="ddp-icon-chart-new"></em>
              {{ 'msg.board.btn.new.chart' | translate }}
            </a>
          </li>
          <li>
            <a href="javascript:" class="ddp-btn-list" (click)="isAppendLayout = true; openTextWidgetEditor()">
              <em class="ddp-icon-text-new"></em>
              {{ 'msg.board.btn.new.text' | translate }}
            </a>
          </li>
          <li>
            <a href="javascript:" class="ddp-btn-list" (click)="isAppendLayout = true; openUpdateFilterPopup()">
              <em class="ddp-icon-filter-new"></em>
              {{ 'msg.board.btn.new.filter' | translate }}
            </a>
          </li>
        </ul>
        <a href="javascript:" class="ddp-btn-more-plus"></a>
      </div>

      <a href="javascript:" class="ddp-btn-line3" (click)="openDismissConfirm(); clearWindowUI()">
        <em class="ddp-icon-dismiss"></em>
        {{ 'msg.comm.btn.dismiss' | translate }}
      </a>
      <a href="javascript:" class="ddp-btn-buttons2" (click)="updateDashboard(); clearWindowUI()">
        <em class="ddp-icon-btndone"></em>
        {{ 'msg.comm.btn.done' | translate }}
      </a>
    </div>
  </div>

  <div class="ddp-wrap-editboard" cdkDropListGroup>
    <div class="ddp-ui-edit-contents">
      <div class="ddp-box-boardedit">
        <div class="ddp-view-boardedit">
          <div
            class="ddp-ui-boardedit static"
            #layoutContainer
            id="grid-drop-container"
            cdkDropList
            [cdkDropListData]="droppedData"
            (cdkDropListDropped)="endDrop($event)"
          >
            <ktd-grid
              #gridComponent
              [gap]="gap"
              [cols]="cols"
              [rowHeight]="rowHeight"
              [scrollableParent]="layoutContainer"
              [layout]="ktGridLayout"
              (layoutUpdated)="onLayoutUpdated($event)"
              (gridItemResize)="onGridItemResized($event)"
            >
              <ktd-grid-item
                *ngFor="let item of ktGridLayout; trackBy: trackById"
                [id]="item.id"
                [draggable]="!readOnlyGrid"
                [resizable]="!readOnlyGrid"
                style="overflow: initial"
              >
                <div class="ktd-grid-widget-wrapper">
                  <dashboard-widget-header
                    [widget]="item.widget"
                    [layoutMode]="item.layoutMode"
                    [isShowTitle]="true"
                  ></dashboard-widget-header>
                  <dashboard-widget
                    [widget]="item.widget"
                    [widgetOpts]="item.widgetOpts"
                    [layoutMode]="item.layoutMode"
                    [widgetInfo]="item.widgetInfo"
                    style="height: 100%"
                    [isDashboardLoaded]="true"
                  ></dashboard-widget>
                </div>
                <ng-template ktdGridItemPlaceholder>
                  <div class="grid-placeholder"></div>
                </ng-template>
              </ktd-grid-item>
            </ktd-grid>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="selectedRightTab !== rightTab.NONE"
      class="ddp-ui-boardside-contents"
      style="z-index: 126; position: relative"
    >
      <div class="ddp-view-boardside">
        <div class="ddp-ui-boardside-in ddp-chart-boardside" *ngIf="selectedRightTab === rightTab.CHART">
          <div class="ddp-area-boardside">
            <div class="ddp-ui-boardside-title">
              {{ 'msg.page.ui.chart' | translate }}
              <span class="ddp-data-num">{{ pageWidgets.length }}</span>

              <div
                class="ddp-info"
                [class.ddp-selected]="isShowChartPanelTooltip"
                (mouseenter)="isShowChartPanelTooltip = true"
                (mouseleave)="isShowChartPanelTooltip = false"
              >
                <a href="javascript:" class="ddp-btn-info"></a>

                <div class="ddp-box-layout4">
                  <a
                    href="javascript:"
                    class="ddp-btn-close"
                    (click)="isShowChartPanelTooltip = false; $event.preventDefault(); $event.stopPropagation()"
                  ></a>

                  <div class="ddp-data-title">
                    {{ 'msg.board.ui.chart.setting.guide' | translate }}
                  </div>

                  <div class="ddp-data-det2">
                    <strong>{{ 'msg.board.th.chart-widget' | translate }}</strong>
                    <div class="ddp-txt-det">{{ 'msg.board.th.chart-widget.description' | translate }}</div>
                    <strong>{{ 'msg.board.th.hierarchy' | translate }}</strong>
                    <div class="ddp-txt-det">{{ 'msg.board.th.hierarchy.description' | translate }}</div>
                  </div>
                </div>
              </div>

              <a (click)="addChart()" href="javascript:" class="ddp-icon-add">
                <div class="ddp-ui-tooltip-info ddp-down">
                  <em class="ddp-icon-view-top"></em>
                  {{ 'msg.board.ui.add.chart.tooltip' | translate }}
                </div>
              </a>
            </div>

            <div class="ddp-wrap-downmenu">
              <ul
                class="ddp-list-chart"
                cdkDropList
                [cdkDropListData]="hierarchy.get()"
                [cdkDropListConnectedTo]="['grid-drop-container']"
                cdkDropListSortingDisabled
                *ngIf="hierarchy"
              >
                <li
                  *ngFor="let widgetRel_1 of hierarchy.get()"
                  cdkDrag
                  [cdkDragData]="widgetRel_1.id"
                  [cdkDragDisabled]="isWidgetInLayout(widgetRel_1.id)"
                >
                  <div class="chart-drag-placeholder" *cdkDragPlaceholder></div>
                  <div class="ddp-chart-images" *cdkDragPreview>
                    <span class="ddp-wrap-chartimg">
                      <em class="{{ 'ddp-img-chart-' + getWidgetType(widgetRel_1.id) }}"></em>
                    </span>
                    <em *ngIf="isWidgetInLayout(widgetRel_1.id)" class="ddp-icon-used"></em>
                  </div>
                  <a href="javascript:">
                    <div class="ddp-chart-images">
                      <span class="ddp-wrap-chartimg">
                        <em class="{{ 'ddp-img-chart-' + getWidgetType(widgetRel_1.id) }}"></em>
                      </span>
                      <em *ngIf="isWidgetInLayout(widgetRel_1.id)" class="ddp-icon-used"></em>
                    </div>

                    <div class="ddp-data-info">
                      <span class="ddp-data-name">{{ widgetRel_1.name }}</span>
                      <span class="ddp-data-used">{{ getChartFields(widgetRel_1.id) }}</span>
                    </div>

                    <div class="ddp-btn-control">
                      <em class="ddp-icon-edit-s" (click)="editWidgetEventHandler(widgetRel_1.id)"></em>
                      <em class="ddp-icon-del-s" (click)="setRemoveWidget(widgetRel_1.id)"></em>
                    </div>
                  </a>

                  <ul
                    *ngIf="widgetRel_1.children && 0 < widgetRel_1.children.length"
                    class="ddp-list-chart-2depth"
                    cdkDropList
                    [cdkDropListData]="widgetRel_1.children"
                    [cdkDropListConnectedTo]="['grid-drop-container']"
                    cdkDropListSortingDisabled
                  >
                    <li
                      *ngFor="let widgetRel_2 of widgetRel_1.children"
                      cdkDrag
                      [cdkDragData]="widgetRel_2.id"
                      [cdkDragDisabled]="isWidgetInLayout(widgetRel_2.id)"
                    >
                      <div class="chart-drag-placeholder" *cdkDragPlaceholder></div>
                      <div class="ddp-chart-images" *cdkDragPreview>
                        <span class="ddp-wrap-chartimg">
                          <em class="{{ 'ddp-img-chart-' + getWidgetType(widgetRel_2.id) }}"></em>
                        </span>
                        <em *ngIf="isWidgetInLayout(widgetRel_2.id)" class="ddp-icon-used"></em>
                      </div>
                      <a href="javascript:">
                        <div class="ddp-chart-images">
                          <span class="ddp-wrap-chartimg">
                            <em class="{{ 'ddp-img-chart-' + getWidgetType(widgetRel_2.id) }}"></em>
                          </span>
                          <em *ngIf="isWidgetInLayout(widgetRel_2.id)" class="ddp-icon-used"></em>
                        </div>

                        <div class="ddp-data-info">
                          <span class="ddp-data-name">{{ widgetRel_2.name }}</span>
                          <span class="ddp-data-used">{{ getChartFields(widgetRel_2.id) }}</span>
                        </div>

                        <div class="ddp-btn-control">
                          <em class="ddp-icon-edit-s" (click)="editWidgetEventHandler(widgetRel_2.id)"></em>
                          <em class="ddp-icon-del-s" (click)="setRemoveWidget(widgetRel_2.id)"></em>
                        </div>
                      </a>

                      <ul
                        *ngIf="widgetRel_2.children && 0 < widgetRel_2.children.length"
                        class="ddp-list-chart-3depth"
                        cdkDropList
                        [cdkDropListData]="widgetRel_2.children"
                        [cdkDropListConnectedTo]="['grid-drop-container']"
                        cdkDropListSortingDisabled
                      >
                        <li
                          *ngFor="let widgetRel_3 of widgetRel_2.children"
                          cdkDrag
                          [cdkDragData]="widgetRel_3.id"
                          [cdkDragDisabled]="isWidgetInLayout(widgetRel_3.id)"
                        >
                          <div class="chart-drag-placeholder" *cdkDragPlaceholder></div>
                          <div class="ddp-chart-images" *cdkDragPreview>
                            <span class="ddp-wrap-chartimg">
                              <em class="{{ 'ddp-img-chart-' + getWidgetType(widgetRel_3.id) }}"></em>
                            </span>
                            <em *ngIf="isWidgetInLayout(widgetRel_3.id)" class="ddp-icon-used"></em>
                          </div>
                          <a href="javascript:">
                            <div class="ddp-chart-images">
                              <span class="ddp-wrap-chartimg">
                                <em class="{{ 'ddp-img-chart-' + getWidgetType(widgetRel_3.id) }}"></em>
                              </span>
                              <em *ngIf="isWidgetInLayout(widgetRel_3.id)" class="ddp-icon-used"></em>
                            </div>

                            <div class="ddp-data-info">
                              <span class="ddp-data-name">{{ widgetRel_3.name }}</span>
                              <span class="ddp-data-used">{{ getChartFields(widgetRel_3.id) }}</span>
                            </div>

                            <div class="ddp-btn-control">
                              <em class="ddp-icon-edit-s" (click)="editWidgetEventHandler(widgetRel_3.id)"></em>
                              <em class="ddp-icon-del-s" (click)="setRemoveWidget(widgetRel_3.id)"></em>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div class="ddp-foot-buttons">
              <a href="javascript:" class="ddp-btn-set" (click)="showSetPageRelation()">
                <em class="ddp-icon-hierarch"></em>
                {{ 'msg.board.btn.hierarchy' | translate }}
              </a>
            </div>
          </div>
        </div>

        <text-widgets-panel
          *ngIf="selectedRightTab === rightTab.TEXT"
          [dashboard]="dashboard"
          [widgetCompsInLayout]="getWidgetComps()"
          (changeEvent)="setTextWidget($event)"
        ></text-widgets-panel>

        <div class="ddp-ui-boardside-in ddp-filter-boardside" *ngIf="selectedRightTab === rightTab.FILTER">
          <div class="ddp-area-boardside">
            <div class="ddp-ui-boardside-title">
              {{ 'msg.board.filter.title.global-filter' | translate }}
              <span class="ddp-data-num">
                {{ boardFilters.length }}
              </span>
              <a href="javascript:" class="ddp-icon-add" (click)="openUpdateFilterPopup()">
                <div class="ddp-ui-tooltip-info ddp-down">
                  <em class="ddp-icon-view-top"></em>
                  {{ 'msg.board.ui.filter.tooltip' | translate }}
                </div>
              </a>
            </div>

            <div class="ddp-ui-boardside-scroll">
              <div [class.ddp-border-none]="pageFilters.length === 0">
                <div
                  *ngFor="let item of pageFilters; trackBy: filterListTrackByFn; let idx = index"
                  (click)="openIndexFilterPanel = idx"
                  [class.ddp-selected]="idx === openIndexFilterPanel"
                  class="ddp-wrap-divide ddp-dropdown filter-panel-container"
                  cdkDropList
                  [cdkDropListData]="pageFilters"
                  [cdkDropListConnectedTo]="['grid-drop-container']"
                  cdkDropListSortingDisabled
                >
                  <div
                    *ngIf="isTimeFilter(getFilterForFilterWidget(item))"
                    cdkDrag
                    [cdkDragData]="item.id"
                    [cdkDragDisabled]="isWidgetInLayout(item.id)"
                  >
                    <time-filter-panel
                      [filter]="getFilterForFilterWidget(item)"
                      [dashboard]="dashboard"
                      [isWidgetInLayout]="isWidgetInLayout(item.id)"
                      [isDraggable]="isDraggableFilterWidget(item)"
                      (openUpdateFilterPopupEvent)="openUpdateFilterPopup($event)"
                      (updateFilterEvent)="updateFilter($event)"
                      (deleteFilterEvent)="deleteFilter($event)"
                      (changeChartToGlobalEvent)="openChangeFilterConfirm($event)"
                    ></time-filter-panel>
                    <div class="chart-drag-placeholder" *cdkDragPlaceholder></div>
                    <div class="ddp-chart-images" *cdkDragPreview>
                      <span class="ddp-wrap-chartimg">
                        <em class="ddp-icon-dimension-calen"></em>
                      </span>
                    </div>
                  </div>
                  <div
                    *ngIf="getFilterForFilterWidget(item).type === 'include'"
                    cdkDrag
                    [cdkDragData]="item.id"
                    [cdkDragDisabled]="isWidgetInLayout(item.id)"
                  >
                    <inclusion-filter-panel
                      [filter]="getFilterForFilterWidget(item)"
                      [dashboard]="dashboard"
                      [isWidgetInLayout]="isWidgetInLayout(item.id)"
                      [isDraggable]="isDraggableFilterWidget(item)"
                      (openUpdateFilterPopupEvent)="openUpdateFilterPopup($event)"
                      (updateFilterEvent)="updateFilter($event)"
                      (deleteFilterEvent)="deleteFilter($event)"
                      (changeChartToGlobalEvent)="openChangeFilterConfirm($event)"
                    ></inclusion-filter-panel>
                    <div class="chart-drag-placeholder" *cdkDragPlaceholder></div>
                    <div class="ddp-chart-images" *cdkDragPreview>
                      <span class="ddp-wrap-chartimg">
                        <em class="ddp-icon-dimension-ab"></em>
                      </span>
                      <em *ngIf="isWidgetInLayout(item.id)" class="ddp-icon-used"></em>
                    </div>
                  </div>
                  <div
                    *ngIf="getFilterForFilterWidget(item).type === 'bound'"
                    cdkDrag
                    [cdkDragData]="item.id"
                    [cdkDragDisabled]="isWidgetInLayout(item.id)"
                  >
                    <bound-filter-panel
                      [filter]="getFilterForFilterWidget(item)"
                      [dashboard]="dashboard"
                      [isWidgetInLayout]="isWidgetInLayout(item.id)"
                      [isDraggable]="isDraggableFilterWidget(item)"
                      (openUpdateFilterPopupEvent)="openUpdateFilterPopup($event)"
                      (updateFilterEvent)="updateFilter($event)"
                      (deleteFilterEvent)="deleteFilter($event)"
                      (changeChartToGlobalEvent)="openChangeFilterConfirm($event)"
                    ></bound-filter-panel>
                    <div class="chart-drag-placeholder" *cdkDragPlaceholder></div>
                    <div class="ddp-chart-images" *cdkDragPreview>
                      <span class="ddp-wrap-chartimg">
                        <em class="ddp-icon-measure-sharp"></em>
                      </span>
                      <em *ngIf="isWidgetInLayout(item.id)" class="ddp-icon-used"></em>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <app-dashboard-layout-config
          *ngIf="selectedRightTab === rightTab.LAYOUT"
          [dashboard]="dashboard"
          [boardConf]="boardConfiguration(dashboard)"
          (changeBoardConf)="changeBoardConf($event)"
        ></app-dashboard-layout-config>

        <datasource-panel
          [dashboard]="dashboard"
          [chartFilters]="chartFilters"
          (changeFilter)="changeFilter($event)"
          (onFilterClick)="selectedRightTab = rightTab.FILTER"
          (onUpdateFilter)="addFilter($event)"
          (onDeleteFilter)="deleteFilter($event)"
          (onUpdateCustomField)="updateCustomField($event)"
          (onDeleteCustomField)="deleteCustomField($event)"
          (changeFieldAlias)="updateFieldAndWidgetPivotAndRender($event)"
        ></datasource-panel>
      </div>
    </div>

    <div class="ddp-ui-boardside-menu">
      <a
        href="javascript:"
        [ngClass]="{ 'ddp-selected': selectedRightTab === rightTab.CHART }"
        (click)="toggleRnb(rightTab.CHART)"
      >
        <em class="ddp-icon-menu-chart"></em>
      </a>
      <a
        href="javascript:"
        [ngClass]="{ 'ddp-selected': selectedRightTab === rightTab.TEXT }"
        (click)="toggleRnb(rightTab.TEXT)"
      >
        <em class="ddp-icon-menu-text"></em>
      </a>
      <a
        href="javascript:"
        [ngClass]="{ 'ddp-selected': selectedRightTab === rightTab.FILTER }"
        (click)="toggleRnb(rightTab.FILTER)"
      >
        <em class="ddp-icon-menu-filter"></em>
      </a>
      <span class="ddp-ui-menu-layout">
        <a
          href="javascript:"
          [ngClass]="{ 'ddp-selected': selectedRightTab === rightTab.LAYOUT }"
          (click)="toggleRnb(rightTab.LAYOUT)"
        >
          <em class="ddp-icon-menu-layout"></em>
        </a>
      </span>
      <span class="ddp-ui-menu-layout ddp-bottom">
        <a href="javascript:" (click)="toggleDatasourcePanel()">
          <em class="ddp-icon-menu-datasorce"></em>
        </a>
      </span>
    </div>
  </div>
</div>

<app-config-filter (done)="configureFilter($event)" (close)="closeComponent('UPDATE-FILTER')"></app-config-filter>
<app-page
  #page
  *ngIf="isShowPage"
  [widget]="selectedPageWidget"
  (changeFieldAlias)="updateFieldAndWidgetPivot($event)"
  (close)="closeComponent('PAGE')"
></app-page>
<app-page-relation (changeRelation)="changePageWidgetRelation($event)"></app-page-relation>
<app-update-datasource
  *ngIf="isUpdateDataSource"
  [workbookId]="workbook.id"
  [workbookName]="workbook.name"
  [workspaceId]="workbook['workspaceId']"
  [sourceDashboard]="dashboard"
  (done)="changeDataSource($event)"
  (close)="closeUpdateDataSource()"
></app-update-datasource>
