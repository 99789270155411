<div class="chart-wrapper-section-content">
  <div class="ddp-ui-drop-contents">
    <div class="ddp-wrap-source-name">
      <dashboard-datasource-combo
        [enableInfo]="true"
        [dataSources]="dataSourceList"
        [initialValue]="dataSource"
        (selectOption)="selectDataSource.emit([$event, true])"
        (showInfo)="onDataPreviewPopup.emit()"
      ></dashboard-datasource-combo>
    </div>
  </div>

  <div class="ddp-ui-drop-option">
    <div class="ddp-form-search">
      <em class="ddp-icon-search"></em>
      <component-input
        [compType]="'search'"
        [showClear]="true"
        [immediately]="true"
        [value]="fieldSearchText"
        [placeHolder]="'msg.page.ui.search.ph' | translate"
        (changeValue)="fieldSearchText = $event; setFieldTotalPage.emit()"
      ></component-input>
    </div>
  </div>
  <ng-container>
    <div class="chart-wrapper-section-content-submenu" [class.ddp-selected]="isDataDimensionLayerShow">
      <div class="content-sub-title" (click)="isDataDimensionLayerShow = !isDataDimensionLayerShow">
        <div class="content-sub-tiltein ddp-dimension">
          {{ 'msg.page.th.dimension' | translate }}
          <a href="javascript:" (click)="$event.stopPropagation(); openCustomFieldPopup.emit([null, 'DIMENSION'])">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-plus-circle"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" y1="8" x2="12" y2="16"></line>
              <line x1="8" y1="12" x2="16" y2="12"></line>
            </svg>
          </a>
        </div>
      </div>
      <div class="chart-wrapper-section-content-source">
        <ul class="ddp-list-source ddp-list-dimension" [dragula]="'dragbag'" [dragulaModel]="pageDimensions">
          <li
            class="ddp-ui-dimension dimension-wrapper"
            *ngFor="let field of pageDimensions; let idx = index"
            (click)="onPivotSelect.emit([field, true])"
            [style.display]="isContainSearchText(fieldSearchText, field.name) ? 'flex' : 'none'"
            [attr.data-name]="field.name"
            [attr.data-source]="'dimension'"
            [class.ddp-selected]="fieldDetailLayer === field && showFieldIconsFl"
          >
            <em
              *ngIf="'network' !== selectChart && 'map' !== selectChart"
              [class.ddp-icon-columns]="field.pivot && field.pivot.length > 0 && field.pivot[0] === 'ROWS'"
              [class.ddp-icon-rows]="field.pivot && field.pivot.length > 0 && field.pivot[0] === 'COLUMNS'"
              [class.ddp-icon-cross]="field.pivot && field.pivot.length > 0 && field.pivot[0] === 'AGGREGATIONS'"
              style="padding: 3px 7px 0 0"
              class="type-apply"
            ></em>
            <em
              *ngIf="'network' === selectChart"
              [class.icon-source]="field.pivot && field.pivot.length > 0 && field.pivot[0] === 'ROWS'"
              [class.icon-target]="field.pivot && field.pivot.length > 0 && field.pivot[0] === 'COLUMNS'"
              [class.icon-link]="field.pivot && field.pivot.length > 0 && field.pivot[0] === 'AGGREGATIONS'"
              class="ddp-icon-network"
            ></em>
            <em
              *ngIf="'map' === selectChart"
              [class.ddp-icon-mapview1]="
                field.pivot && field.pivot.length > 0 && 'MAP_LAYER0' === field.pivot[0]?.toString()
              "
              [class.ddp-icon-mapview2]="
                field.pivot && field.pivot.length > 0 && 'MAP_LAYER1' === field.pivot[0]?.toString()
              "
              [class.ddp-icon-mapview3]="
                field.pivot && field.pivot.length > 0 && 'MAP_LAYER2' === field.pivot[0]?.toString()
              "
            ></em>
            <em
              class="ddp-icon-dimension-ab"
              *ngIf="field.logicalType?.toString() === 'STRING' || field.type === 'user_expr'"
            ></em>
            <em class="ddp-icon-dimension-array" *ngIf="field.logicalType?.toString() === 'ARRAY'"></em>
            <em class="ddp-icon-dimension-latitude" *ngIf="field.logicalType?.toString() === 'LNT'"></em>
            <em class="ddp-icon-dimension-longitude" *ngIf="field.logicalType?.toString() === 'LNG'"></em>
            <em class="ddp-icon-dimension-calen" *ngIf="field.logicalType?.toString() === 'TIMESTAMP'"></em>
            <em
              class="ddp-icon-dimension-float"
              *ngIf="field.logicalType?.toString() === 'DOUBLE' || field.logicalType?.toString() === 'INTEGER'"
            ></em>
            <em class="ddp-icon-dimension-tf" *ngIf="field.logicalType?.toString() === 'BOOLEAN'"></em>
            <em
              class="ddp-icon-dimension-point ddp-icon-map-view"
              *ngIf="field.logicalType?.toString() === 'GEO_POINT'"
            ></em>
            <em
              class="ddp-icon-dimension-line ddp-icon-map-view"
              *ngIf="field.logicalType?.toString() === 'GEO_LINE'"
            ></em>
            <em
              class="ddp-icon-dimension-polygon ddp-icon-map-view"
              *ngIf="field.logicalType?.toString() === 'GEO_POLYGON'"
            ></em>
            <span class="content-source--ul--li-name">
              {{ field.name }}
            </span>
            <div class="wrap-icons">
              <div class="ddp-wrap-user-preview" *ngIf="field.type === 'user_expr'">
                <em
                  class="ddp-icon-control-user ddp-type"
                  (click)="$event.stopPropagation(); openCustomFieldPopup(field)"
                ></em>
                <div class="ddp-ui-tooltip-info">
                  <em class="ddp-icon-view-top"></em>
                  <span class="ddp-txt-tooltip">{{ unescapeCustomColumnExpr(field.expr) }}</span>
                </div>
              </div>
              <em
                class="ddp-icon-control-filter ddp-type"
                [ngClass]="{ 'ddp-selected': field.useFilter }"
                (click)="toggleFilter.emit([field, $event])"
              ></em>
              <div class="ddp-btn-control">
                <em class="ddp-icon-control-more ddp-type" (click)="openFieldDetailLayer.emit([$event, field])"></em>
              </div>
            </div>
          </li>
        </ul>
        <div class="pagination">
          <a
            (click)="$event.stopPropagation(); fieldPrev.emit(true)"
            [class.ddp-disabled]="dimensionPage === 1"
            href="javascript:"
            class="ddp-prev"
          >
            <em><</em>
            {{ 'msg.comm.btn.previous' | translate }}
          </a>
          <a
            (click)="$event.stopPropagation(); fieldNext.emit(true)"
            [class.ddp-disabled]="dimensionPage === dimensionTotalPage"
            href="javascript:"
            class="ddp-next"
          >
            {{ 'msg.comm.btn.next' | translate }}
            <em>></em>
          </a>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="chart-wrapper-section-content-submenu" [class.ddp-selected]="isDataMeasureLayerShow">
    <div class="content-sub-title" (click)="isDataMeasureLayerShow = !isDataMeasureLayerShow">
      <div class="content-sub-tiltein ddp-measure">
        <em class="ddp-icon-menumove"></em>
        {{ 'msg.page.th.measure' | translate }}
        <a href="javascript:" (click)="$event.stopPropagation(); openCustomFieldPopup.emit([null, 'MEASURE'])">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-plus-circle"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="8" x2="12" y2="16"></line>
            <line x1="8" y1="12" x2="16" y2="12"></line>
          </svg>
        </a>
      </div>
    </div>
    <div class="chart-wrapper-section-content-source">
      <ul class="ddp-list-source ddp-list-measure" [dragula]="'dragbag'" [dragulaModel]="pageMeasures">
        <li
          class="ddp-ui-measure"
          *ngFor="let field of pageMeasures"
          (click)="onPivotSelect.emit([field, false])"
          [style.display]="isContainSearchText(fieldSearchText, field.name) ? 'flex' : 'none'"
          [attr.data-name]="field.name"
          [attr.data-source]="'measure'"
          [class.ddp-selected]="fieldDetailLayer === field && showFieldIconsFl"
        >
          <em
            *ngIf="'network' !== selectChart && 'map' !== selectChart"
            [class.ddp-icon-columns]="field.pivot && field.pivot.length > 0 && field.pivot[0] === 'ROWS'"
            [class.ddp-icon-rows]="field.pivot && field.pivot.length > 0 && field.pivot[0] === 'COLUMNS'"
            [class.ddp-icon-cross]="field.pivot && field.pivot.length > 0 && field.pivot[0] === 'AGGREGATIONS'"
            class="type-apply"
          ></em>
          <em
            *ngIf="'network' === selectChart"
            [class.icon-source]="field.pivot && field.pivot.length > 0 && field.pivot[0] === 'ROWS'"
            [class.icon-target]="field.pivot && field.pivot.length > 0 && field.pivot[0] === 'COLUMNS'"
            [class.icon-link]="field.pivot && field.pivot.length > 0 && field.pivot[0] === 'AGGREGATIONS'"
            class="ddp-icon-network"
          ></em>
          <em
            *ngIf="'map' === selectChart"
            [class.ddp-icon-mapview1]="
              field.pivot && field.pivot.length > 0 && 'MAP_LAYER0' === field.pivot[0]?.toString()
            "
            [class.ddp-icon-mapview2]="
              field.pivot && field.pivot.length > 0 && 'MAP_LAYER1' === field.pivot[0]?.toString()
            "
            [class.ddp-icon-mapview3]="
              field.pivot && field.pivot.length > 0 && 'MAP_LAYER2' === field.pivot[0]?.toString()
            "
          ></em>
          <em class="ddp-icon-dimension-ab" *ngIf="field.logicalType?.toString() === 'STRING'"></em>
          <em
            class="ddp-icon-dimension-local"
            *ngIf="field.logicalType?.toString() === 'LNG' || field.logicalType?.toString() === 'LNT'"
          ></em>
          <em class="ddp-icon-dimension-calen" *ngIf="field.logicalType?.toString() === 'TIMESTAMP'"></em>
          <em class="ddp-icon-measure-array" *ngIf="field.logicalType?.toString() === 'ARRAY'"></em>
          <em
            class="ddp-icon-measure-sharp"
            *ngIf="
              field.logicalType?.toString() === 'DOUBLE' ||
              field.logicalType?.toString() === 'INTEGER' ||
              field.type === 'user_expr'
            "
          ></em>
          <em class="ddp-icon-measure-tf" *ngIf="field.logicalType?.toString() === 'BOOLEAN'"></em>
          <div class="wrap-icons" (clickOutside)="showFieldIconsFl = false">
            <div class="ddp-wrap-user-preview" *ngIf="field.type === 'user_expr'">
              <em
                class="ddp-icon-control-calculat ddp-type ddp-selected"
                (click)="$event.stopPropagation(); openCustomFieldPopup(field)"
              ></em>
              <div class="ddp-ui-tooltip-info">
                <em class="ddp-icon-view-top"></em>
                <span class="ddp-txt-tooltip">{{ field.expr }}</span>
              </div>
            </div>
            <em
              *ngIf="!isCustomMeasureField.emit(field)"
              class="ddp-icon-control-filter ddp-type"
              [ngClass]="{
                'ddp-selected': field.useFilter,
                'ddp-disabled': field.aggregated
              }"
              (click)="toggleFilter(field, $event)"
            ></em>
            <div class="ddp-btn-control">
              <em class="ddp-icon-control-more ddp-type" (click)="openFieldDetailLayer($event, field)"></em>
            </div>
          </div>
          <span
            class="wrap-data-name"
            [class.ddp-alias]="
              'user_expr' !== field.type && field['nameAlias'] && field['nameAlias']['nameAlias'] !== field.name
            "
          >
            {{ field.name }}
            <!-- <em
                *ngIf="
                  'user_expr' !== field.type &&
                  field['nameAlias'] &&
                  field['nameAlias']['nameAlias'] !== field.name
                "
                class="ddp-icon-alias"
              >
                ({{ field['nameAlias']['nameAlias'] }})
              </em> -->
          </span>
        </li>
      </ul>
      <div class="pagination">
        <a
          (click)="$event.stopPropagation(); fieldPrev.emit(false)"
          [class.ddp-disabled]="measurePage === 1"
          href="javascript:"
          class="ddp-prev"
        >
          <em><</em>
          {{ 'msg.comm.btn.previous' | translate }}
        </a>
        <a
          (click)="$event.stopPropagation(); fieldNext.emit(false)"
          [class.ddp-disabled]="measurePage === measureTotalPage"
          href="javascript:"
          class="ddp-next"
        >
          {{ 'msg.comm.btn.next' | translate }}
          <em>></em>
        </a>
      </div>
    </div>
  </div>
</div>
