import {
  ApplicationRef,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { KtdGridComponent, KtdGridItemResizeEvent, KtdGridLayout, ktdTrackById } from '@katoid/angular-grid-layout';
import $ from 'jquery';
import * as _ from 'lodash';
import { fromEvent, merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import {
  BoardConfiguration,
  BoardWidgetOptions,
  Dashboard,
  Datasource,
  LayoutMode,
  LayoutWidgetInfo,
  PageWidget,
  Widget,
  WidgetShowType,
  createBoardConfiguration,
} from '@selfai-platform/bi-domain';

import { AbstractComponent } from '../../../common/component/abstract.component';
import { EventBroadcaster } from '../../../common/event/event.broadcaster';
import { ImageService } from '../../../common/service/image.service';
import { PopupService } from '../../../common/service/popup.service';
import { removeItemFromArray } from '../../../common/util';
import { DatasourceService } from '../../../datasource/service/datasource.service';
import { DashboardApiService } from '../../service/dashboard-api.service';
import { WidgetService } from '../../service/widget.service';
import { DashboardUtil } from '../../util/dashboard.util';

import {
  WidgetGridItem,
  WidgetGridLayout,
  checkAndCorrectBoardInfo,
  convertSpecToUI,
  createConfigurationFromKtdGridLayout,
  getGridItemSettingsFromLayoutSettings,
  getInformationAboutDeletedDatasources,
  migrationFromOldSpec,
  removeDuplicateFilters,
  setBoardPropertiesByMode,
  setDatasourceForDashboard,
} from './dashboard-layout';
import { DashboardLayoutFilterService } from './dashboard-layout/dashboard-layot-filter.service';
import { DASHBOARD_GRID_SETTINGS } from './dashboard-layout/grid-settings';
import { DashboardWidgetComponent } from './dashboard.widget.component';

@Directive({
  providers: [DashboardLayoutFilterService],
})
export abstract class DashboardLayoutComponent extends AbstractComponent implements OnInit, OnDestroy {
  @ViewChild('layoutContainer')
  public layoutContainer: ElementRef;

  @ViewChild(KtdGridComponent, { static: false })
  gridCompomnent: KtdGridComponent | undefined;

  @ViewChildren(DashboardWidgetComponent)
  _widgetComps: QueryList<DashboardWidgetComponent>;

  private _isCompleteLayoutLoad = false;

  private _$layoutContainer: JQuery<any>;

  private _layoutMode: LayoutMode = LayoutMode.VIEW;

  private _invalidLayoutWidgets: string[] = [];

  protected _removeDsEngineNames: string[] = [];

  protected dashboardLayoutFilterService: DashboardLayoutFilterService;

  public dashboard: Dashboard;

  public isShowDashboardLoading = false;

  cols: number = DASHBOARD_GRID_SETTINGS.columns;
  rowHeight: number = DASHBOARD_GRID_SETTINGS.rowHeight;
  gridHeight = 10;
  gap = DASHBOARD_GRID_SETTINGS.gap;
  ktGridLayout: WidgetGridLayout = [];
  trackById = ktdTrackById;
  readOnlyGrid = false;
  showSaveButton = false;
  saveStateInProgress = false;

  protected constructor(
    protected broadCaster: EventBroadcaster,
    protected widgetService: WidgetService,
    protected datasourceService: DatasourceService,
    protected popupService: PopupService,
    protected appRef: ApplicationRef,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected elementRef: ElementRef,
    protected injector: Injector,
    public imageService: ImageService,
    protected dashboardApiService: DashboardApiService,
  ) {
    super(elementRef, injector);

    this.dashboardLayoutFilterService = this.injector.get(DashboardLayoutFilterService);
  }

  public ngOnInit() {
    super.ngOnInit();

    this.subscriptions.push(
      this.broadCaster.on<any>('START_PROCESS').subscribe((data: { widgetId: string }) => {
        DashboardUtil.getLayoutWidgetInfos(this.dashboard).some((item) => {
          if (item.ref === data.widgetId) {
            item.isLoaded = false;
          }
          return item.ref === data.widgetId;
        });
        this.showBoardLoading();
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on<any>('STOP_PROCESS').subscribe((data: { widgetId: string }) => {
        const layoutWidgets: LayoutWidgetInfo[] = DashboardUtil.getLayoutWidgetInfos(this.dashboard);
        let cntInLayout = 0;
        let cntLoaded = 0;
        layoutWidgets.forEach((item) => {
          if (item.isInLayout) {
            cntInLayout = cntInLayout + 1;
            item.ref === data.widgetId && (item.isLoaded = true);
            item.isLoaded && (cntLoaded = cntLoaded + 1);
          }
        });
        if (cntInLayout === cntLoaded) {
          this.updateLayoutFinished();
        }
      }),
    );

    this.destroyPreviosDashboardAndSetWidgetComponentsEmpty();

    this.setGridEvents();
  }

  onLayoutUpdated($event: KtdGridLayout) {
    const currentBoardConfig = DashboardUtil.getBoardConfiguration(this.dashboard);
    const configuration = createConfigurationFromKtdGridLayout($event, currentBoardConfig);
    currentBoardConfig.content = configuration;
    DashboardUtil.updateBoardConfiguration(this.dashboard, currentBoardConfig);
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
    this.destroyDashboard();
  }

  private setGridEvents() {
    const resizeSubscription = merge(fromEvent(window, 'resize'), fromEvent(window, 'orientationchange'))
      .pipe(debounceTime(50))
      .subscribe(() => {
        this.gridCompomnent?.resize();
        this.broadCaster.broadcast('RESIZE_WIDGET');
      });
    this.subscriptions.push(resizeSubscription);
  }

  private destroyPreviosDashboardAndSetWidgetComponentsEmpty() {
    this.destroyDashboard();
    this._isCompleteLayoutLoad = false;
  }

  private getWidgetComponentRef(widgetId: string): DashboardWidgetComponent {
    return this._widgetComps.filter((item) => item.getWidgetId() === widgetId)[0];
  }

  public removeWidgetComponent(widgetId: string) {
    this.dashboard = DashboardUtil.setUseWidgetInLayout(this.dashboard, widgetId, false);
    const olditemsWithNewCoordinatesAndSizes = this.createWidgetGridItemsFromGridLayout();
    this.ktGridLayout = removeItemFromArray(olditemsWithNewCoordinatesAndSizes, (item) => item.id === widgetId);
  }

  private updateLayoutFinished() {
    if (!this._isCompleteLayoutLoad) {
      this._isCompleteLayoutLoad = true;
      this.updateLayoutSize();
      this.onLayoutInitialised();
    }
    this.hideBoardLoading();
  }

  private setForceLayout() {
    const board: Dashboard = this.dashboard;

    this.initLayout(board, this._layoutMode);

    this.renderLayout();
    this.gridCompomnent?.calculateRenderData();
  }

  private initWidgetsAndLayout(boardInfo: Dashboard, mode: LayoutMode): Dashboard {
    checkAndCorrectBoardInfo(boardInfo);

    this.initLayout(boardInfo, mode);
    this.renderLayout();

    return boardInfo;
  }

  protected appendWidgetInLayout(newWidgets: Widget[]) {
    if (!newWidgets || !newWidgets.length) {
      return;
    }
    const layoutInfo = this.dashboard.configuration.layout;
    const itemsConfig = getGridItemSettingsFromLayoutSettings(layoutInfo);
    const layoutWidgetItems: WidgetGridItem[] = newWidgets.map((item) => {
      const itemConfig = itemsConfig[item.id];
      return {
        id: item.id,
        x: itemConfig?.x || 0,
        y: itemConfig?.y || 0,
        w: itemConfig?.w || DASHBOARD_GRID_SETTINGS.columns / 2,
        h: itemConfig?.h || 3,
        layoutMode: this._layoutMode,
        widget: item,
        widgetInfo: this.dashboard.configuration.widgets.find(({ ref }) => ref === item.id),
        widgetOpts: this.dashboard.configuration.options.widget,
      };
    });
    const olditemsWithNewCoordinatesAndSizes = this.createWidgetGridItemsFromGridLayout();
    this.ktGridLayout = [...olditemsWithNewCoordinatesAndSizes, ...layoutWidgetItems];
    this.updateLayoutSize();
  }

  private createWidgetGridItemsFromGridLayout() {
    if (!this.gridCompomnent) {
      return this.ktGridLayout;
    }
    const olditemsWithNewCoordinatesAndSizes: WidgetGridItem[] = this.gridCompomnent.layout.map((w) => {
      const current = this.ktGridLayout.find((t) => t.id === w.id);
      return {
        ...w,
        widget: current.widget,
        layoutMode: current.layoutMode,
        widgetInfo: current.widgetInfo,
        widgetOpts: current.widgetOpts,
      };
    });

    return olditemsWithNewCoordinatesAndSizes;
  }

  public updateLayoutSize() {
    if (this._isCompleteLayoutLoad) {
      setTimeout(() => {
        this.gridCompomnent && this.gridCompomnent.resize();
      }, 100);
    }
  }

  public onGridItemResized($event: KtdGridItemResizeEvent) {
    this.saveGridConfigTOBoardConfig();
    this.broadCaster.broadcast('RESIZE_WIDGET');
    if (this._layoutMode !== LayoutMode.EDIT) {
      this.showSaveButton = true;
    }
  }

  private saveGridConfigTOBoardConfig() {
    const newConfig = createConfigurationFromKtdGridLayout(this.gridCompomnent.layout, this.dashboard.configuration);
    this.dashboard.configuration.content = newConfig;
    this.dashboard.configuration.layout.content = newConfig;
  }

  public initLayout(board: Dashboard, mode: LayoutMode) {
    this.destroyPreviosDashboardAndSetWidgetComponentsEmpty();
    board.configuration && (board.configuration = _.merge(createBoardConfiguration(), board.configuration));
    this._layoutMode = mode;

    setBoardPropertiesByMode(mode, board);
    this.dashboard = board;
  }

  public reloadWidget(widgetInfo: Widget) {
    const widgetItems = this.createWidgetGridItemsFromGridLayout();
    this.ktGridLayout = widgetItems.map((t) => {
      if (t.id !== widgetInfo.id) {
        return t;
      }
      return {
        ...t,
        layoutMode: this._layoutMode,
        widget: widgetInfo,
        widgetInfo: this.dashboard.configuration.widgets.find((item) => item.ref === item.id),
        widgetOpts: this.dashboard.configuration.options.widget,
      };
    });
  }

  public reloadAllWidgets() {
    const oldLayout = this.ktGridLayout;
    this.ktGridLayout = [];

    setTimeout(() => {
      this.ktGridLayout = oldLayout.map((t) => {
        const widgetInfo = DashboardUtil.getWidget(this.dashboard, t.id);
        return {
          ...t,
          layoutMode: this._layoutMode,
          widget: widgetInfo,
          widgetInfo: this.dashboard.configuration.widgets.find((item) => item.ref === item.id),
          widgetOpts: this.dashboard.configuration.options.widget,
        };
      });
    });
  }

  public renderLayout() {
    this.gap = this.dashboard?.configuration?.options?.layout?.widgetPadding || DASHBOARD_GRID_SETTINGS.gap;
    if (this.layoutContainer) {
      this._$layoutContainer = $(this.layoutContainer.nativeElement);
    }
    if (DashboardUtil.getWidgets(this.dashboard).length > 0) {
      const dashboard: Dashboard = this.dashboard;
      const layoutWidgets: LayoutWidgetInfo[] = DashboardUtil.getLayoutWidgetInfos(dashboard);

      if (!this.ktGridLayout) {
        if (layoutWidgets.filter((item) => item.isInLayout).length > 0) {
          this.showBoardLoading();
        }
      }

      const currentWidgetIds = this.ktGridLayout.map((t) => t.id);

      const newWidgets = layoutWidgets
        .filter((item) => item.isInLayout && currentWidgetIds.indexOf(item.ref) === -1)
        .map((item) => DashboardUtil.getWidget(this.dashboard, item.ref));

      this.appendWidgetInLayout(newWidgets);
      this.updateLayoutFinished();
    } else {
      this.updateLayoutSize();
      this.onLayoutInitialised();
      this.hideBoardLoading();
    }
  }

  public abstract onLayoutInitialised();

  public showBoardLoading() {
    if (LayoutMode.EDIT === this._layoutMode) {
      this.loadingShow();
    } else {
      const isRealTimeBoard: boolean =
        this.dashboard &&
        this.dashboard.configuration.options.sync &&
        this.dashboard.configuration.options.sync.enabled;
      isRealTimeBoard || (this.isShowDashboardLoading = true);
    }
    this.safelyDetectChanges();
  }

  public hideBoardLoading() {
    if (LayoutMode.EDIT === this._layoutMode) {
      this.loadingHide();
      this.isShowDashboardLoading = false;
    } else {
      this.isShowDashboardLoading = false;
    }
    this.safelyDetectChanges();
    this.loadingHide();
  }

  public resizeToFitScreenForSave() {
    this._$layoutContainer.parent().css({ height: '', overflow: 'hidden' });
    this._$layoutContainer.width('100%').height('100%');
    this.updateLayoutSize();
  }

  public refreshLayout(conf?: BoardConfiguration) {
    this.dashboard = DashboardUtil.updateBoardConfiguration(this.dashboard, conf);

    const widgetGlobalOpts: BoardWidgetOptions = this.dashboard.configuration.options.widget;
    if (widgetGlobalOpts) {
      DashboardUtil.getPageWidgets(this.dashboard).forEach((widgetItem: PageWidget) => {
        const chartConf = widgetItem.configuration['chart'];
        if (chartConf.legend && WidgetShowType.BY_WIDGET !== widgetGlobalOpts.showLegend) {
          chartConf.legend.auto = WidgetShowType.ON === widgetGlobalOpts.showLegend;
        }
        if (chartConf.chartZooms && WidgetShowType.BY_WIDGET !== widgetGlobalOpts.showMinimap) {
          chartConf.chartZooms[0].auto = WidgetShowType.ON === widgetGlobalOpts.showMinimap;
        }
      });
    }
    this.setForceLayout();
  }

  public isWidgetInLayout(widgetId: string): boolean {
    const infoList: LayoutWidgetInfo[] = DashboardUtil.getLayoutWidgetInfos(this.dashboard);
    if (infoList) {
      const info: LayoutWidgetInfo = infoList.find((item) => item.ref === widgetId);
      return info ? info.isInLayout : false;
    } else {
      return false;
    }
  }

  public getWidgetComps(): DashboardWidgetComponent[] {
    return this._widgetComps.toArray();
  }

  public getWidgetComp(widgetId: string) {
    const widgetComps = this._widgetComps.filter((item) => item.getWidgetId() === widgetId);
    return widgetComps && 0 < widgetComps.length ? widgetComps[0] : null;
  }

  public destroyDashboard(): void {
    this.ktGridLayout = [];
  }

  public initializeDashboard(boardInfo: Dashboard, mode: LayoutMode): Promise<Dashboard> {
    return new Promise<any>((resolve) => {
      const result: [Dashboard, Datasource] = setDatasourceForDashboard(boardInfo);
      boardInfo = result[0];
      boardInfo = migrationFromOldSpec(boardInfo);
      removeDuplicateFilters(boardInfo);
      this._removeDsEngineNames = getInformationAboutDeletedDatasources(boardInfo, this._removeDsEngineNames);
      boardInfo = DashboardUtil.convertSpecToUI(boardInfo);

      this.dashboardLayoutFilterService
        .initFilters(boardInfo)
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          boardInfo = convertSpecToUI(boardInfo);
          boardInfo = this.initWidgetsAndLayout(boardInfo, mode);
          resolve(boardInfo);
        });
      this.isShowDashboardLoading = false;
      this.showSaveButton = false;
    });
  }

  public async updateDashboardGridItemPositions() {
    this.showBoardLoading();
    this.saveStateInProgress = true;
    try {
      this.showSaveButton = false;
    } catch (error) {
      console.error(error);
    } finally {
      this.hideBoardLoading();
      this.saveStateInProgress = false;
    }
  }

  protected async callUpdateDashboardGridItemPositionsService(imageUrl: string | null) {
    const param = {
      configuration: DashboardUtil.getBoardConfiguration(this.dashboard),
      imageUrl: imageUrl,
    };

    const settings = createConfigurationFromKtdGridLayout(this.gridCompomnent.layout, param.configuration);
    param.configuration.content = settings;
    param.configuration.layout.content = settings;

    const boardId = this.dashboard.id;
    try {
      await this.dashboardApiService.updateDashboard(boardId, param);
    } catch (error) {
      this.alertPrimeService.error(this.translateService.instant('msg.board.alert.update.board.error'));
    }

    // TODO: I think this is not necessary but I should check it later
    await this.dashboardApiService.getDashboard(boardId);
  }
}
