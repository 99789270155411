import { isNullOrUndefined, isUndefined } from 'util';

import { Component, ElementRef, EventEmitter, Injector, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import * as _ from 'lodash';

import {
  Datasource,
  DatasourceField as Field,
  FieldRole,
  LogicalType,
  TimeFilter,
  BoardConfiguration,
  Widget,
  createTimeListFilter,
  Filter,
  BoundFilter,
  ByTimeUnit,
  GranularityType,
  TimeUnit,
  CustomField,
} from '@selfai-platform/bi-domain';

import { ConfirmModalComponent } from '../../common/component/modal/confirm/confirm.component';
import { Modal } from '../../common/domain/modal';
import { AbstractFilterPopupComponent } from '../../dashboard/filters/abstract-filter-popup.component';
import { DashboardUtil } from '../util/dashboard.util';
import { FilterUtil } from '../util/filter.util';

@Component({
  selector: 'app-config-filter-select',
  templateUrl: './configure-filters-select.component.html',
  styles: [
    '.ddp-pop-filter .ddp-filter0 .ddp-list-filter { top : 0px !important;} ',
    "li.ddp-disabled div:before {display:inline-block; content:'';position:absolute; top:0; right:0; bottom:0; left:0; background-color:rgba(255,255,255,0.5); z-index:3; cursor: no-drop;}",
  ],
})
export class ConfigureFiltersSelectComponent extends AbstractFilterPopupComponent implements OnInit, OnDestroy {
  @ViewChild(ConfirmModalComponent, { static: true })
  private _confirmModalComp: ConfirmModalComponent;

  private _measureFields: (Field | CustomField)[] = [];
  private _dimensionFields: (Field | CustomField)[] = [];

  private _selectedField: Field | CustomField;

  private _allContinuousList: string[] = ['Minute', 'Hour', 'Day', 'Week', 'Month', 'Quarter', 'Year', 'None'];
  private _allDiscontinuousList = {
    DAY: [
      { name: 'Day by week', unit: 'DAY', byUnit: 'WEEK' },
      { name: 'Day by month', unit: 'DAY', byUnit: 'MONTH' },
      { name: 'Day by year', unit: 'DAY', byUnit: 'YEAR' },
    ],
    WEEK: [
      { name: 'Week by month', unit: 'WEEK', byUnit: 'MONTH' },
      { name: 'Week by year', unit: 'WEEK', byUnit: 'YEAR' },
    ],
    MONTH: [{ name: 'Month by year', unit: 'MONTH', byUnit: 'YEAR' }],
    QUARTER: [{ name: 'Quarter', unit: 'QUARTER' }],
    YEAR: [{ name: 'Year', unit: 'YEAR' }],
  };

  private _boardFilters: Filter[] = [];
  private _chartFilters: Filter[] = [];
  private _boardConf: BoardConfiguration;

  public dataSources: Datasource[] = [];
  public selectedDataSource: Datasource;

  public isShow = false;
  public isShowDimTab = true;

  public widget: Widget;

  public measureFields: (Field | CustomField)[] = [];
  public dimensionFields: (Field | CustomField)[] = [];

  public searchText = '';

  public granularity: GranularityType;
  public dpContinuousList: string[] = [];
  public dpDiscontinuousList: any[] = [];

  @Output('setFilter')
  public setFilterEvent: EventEmitter<{
    key: string;
    filter: Filter;
    conf?: any;
  }> = new EventEmitter();

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public open(
    boardConf: BoardConfiguration,
    dataSources: Datasource[],
    targetDataSource: Datasource,
    chartFilters: Filter[],
    widget?: Widget,
  ) {
    this.widget = widget;

    this.dataSources = dataSources;
    this.selectedDataSource = targetDataSource ? targetDataSource : dataSources[0];

    this._boardFilters = isUndefined(boardConf.filters) ? [] : boardConf.filters;
    this._chartFilters = chartFilters;

    this._boardConf = boardConf;

    this.selectDataSource(this.selectedDataSource);

    this.isShow = true;
  }

  public close() {
    this.isShow = false;
  }

  public selectDataSource(dataSource: Datasource) {
    this.granularity = dataSource.granularity;
    this.granularity || (this.granularity = GranularityType.ALL);
    if (GranularityType.ALL !== this.granularity) {
      const strGranularity: string = this.granularity.toString();
      const idx: number = this._allContinuousList.findIndex((unit) => unit.toUpperCase() === strGranularity);
      if (-1 < idx) {
        const dpContinuousList: string[] = this._allContinuousList.slice(idx);
        let dpDiscontinuousList: any[] = [];
        dpContinuousList.forEach((unit) => {
          const data = this._allDiscontinuousList[unit.toUpperCase()];
          if (data && 0 < data.length) {
            dpDiscontinuousList = dpDiscontinuousList.concat(data);
          }
        });
        this.dpContinuousList = dpContinuousList;
        this.dpDiscontinuousList = dpDiscontinuousList;
      }
    }

    let totalFields: (Field | CustomField)[] = DashboardUtil.getFieldsForMainDataSource(
      this._boardConf,
      dataSource.engineName,
    );

    if (this._boardConf.customFields && 0 < this._boardConf.customFields.length) {
      totalFields = totalFields.concat(
        this._boardConf.customFields.filter((item) => item.dataSource === dataSource.engineName),
      );
    }
    this._setFields(totalFields, this._boardFilters, this._chartFilters, this.widget);
  }

  public selectField(field: Field | CustomField) {
    if (field['type'] === 'ARRAY') {
      return;
    }

    this._selectedField = field;

    if (!field['isTimestamp']) {
      if (field['isEditable']) {
        this.editFilter(field);
      } else {
        this.addFilter(field);
      }
    } else {
      if (field['isEditable']) {
        const timeFilter: TimeFilter = field['filter'] || field['someChartFilter'] || field['thisChartFilter'];
        this.editTimestampFilter(field, timeFilter.timeUnit, timeFilter.byTimeUnit);
      } else {
        this.addTimestampFilter(field);
      }
    }
  }

  public isSelectedField(field: Field | CustomField): boolean {
    return this._selectedField && this._selectedField.name === field.name;
  }

  public selectTimestampGranularity(
    field: Field | CustomField,
    discontinuous: boolean = false,
    unit: TimeUnit,
    byUnit?: ByTimeUnit,
  ) {
    if (field['isEditable']) {
      this.editTimestampFilter(field, unit, byUnit);
    } else {
      this.addTimestampFilter(field, unit, byUnit);
    }
  }

  public addFilter(field: Field | CustomField) {
    if (field.role === FieldRole.MEASURE) {
      const boundFilter: BoundFilter = FilterUtil.getBasicBoundFilter(<Field>field);
      this.widget && (boundFilter.ui.widgetId = this.widget.id);
      this.setFilterEvent.emit({ key: 'ADD', filter: boundFilter });
    } else {
      const preFilterData = {
        contains: this.wildCardTypeList[0].value,
        aggregation: this.aggregationTypeList[0].value,
        inequality: this.conditionTypeList[0].value,
        position: this.limitTypeList[0].value,
      };
      const inclusionFilter = FilterUtil.getBasicInclusionFilter(<Field>field, null, preFilterData);
      if (field.type === 'user_expr') {
        inclusionFilter.ref = 'user_defined';
      }
      this.widget && (inclusionFilter.ui.widgetId = this.widget.id);
      this.setFilterEvent.emit({ key: 'ADD', filter: inclusionFilter });
    }
  }

  public addTimestampFilter(field: Field | CustomField, unit?: TimeUnit, byUnit?: ByTimeUnit) {
    let timeFilter: TimeFilter;
    if (isNullOrUndefined(unit)) {
      timeFilter = FilterUtil.getTimeRangeFilter(<Field>field, undefined, undefined, this.selectedDataSource);
    } else {
      timeFilter = createTimeListFilter(<Field>field);
      timeFilter.timeUnit = unit;
      byUnit && (timeFilter.byTimeUnit = byUnit);
    }
    this.widget && (timeFilter.ui.widgetId = this.widget.id);
    this.setFilterEvent.emit({ key: 'ADD', filter: timeFilter });
  }

  public editFilter(field: Field | CustomField) {
    if (this.widget && ('recommended' === field['importanceType'] || 'timestamp' === field['importanceType'])) {
      this.alertPrimeService.warn(
        this.translateService.instant('msg.board.filter.alert.change.chart.warning-recommend'),
      );
      return;
    }

    if (field['someChartFilter']) {
      this._openConfirmToBoardFilter(field['someChartFilter']);
    } else {
      if (this.widget && field['useBoardFilter']) {
        this._openConfirmToChartFilter(field['thisChartFilter'] || field['filter']);
      } else {
        this.setFilterEvent.emit({
          key: 'EDIT',
          filter: field['thisChartFilter'] || field['filter'],
        });
      }
    }
  }

  public editTimestampFilter(field: Field | CustomField, unit?: TimeUnit, byUnit?: ByTimeUnit) {
    if (this.widget && ('recommended' === field['importanceType'] || 'timestamp' === field['importanceType'])) {
      this.alertPrimeService.warn(
        this.translateService.instant('msg.board.filter.alert.change.chart.warning-recommend'),
      );
      return;
    }

    const isToBoardFilter = !!field['someChartFilter'];
    const timeFilter: TimeFilter = field['someChartFilter'] || field['filter'] || field['thisChartFilter'];
    if (isNullOrUndefined(unit) || TimeUnit.NONE === unit) {
      timeFilter.type = 'time_relative';
    } else {
      timeFilter.type = 'time_list';
      timeFilter.timeUnit = unit;
      byUnit && (timeFilter.byTimeUnit = byUnit);
    }

    if (!timeFilter.clzField) {
      const totalFields: (Field | CustomField)[] = DashboardUtil.getFieldsForMainDataSource(
        this._boardConf,
        timeFilter.dataSource,
      );
      timeFilter.clzField = totalFields.find((field) => field.name === timeFilter.field) as Field;
    }

    if (isToBoardFilter) {
      this._openConfirmToBoardFilter(timeFilter);
    } else {
      if (this.widget && field['useBoardFilter']) {
        this._openConfirmToChartFilter(timeFilter);
      } else {
        this.setFilterEvent.emit({ key: 'EDIT', filter: timeFilter });
      }
    }
  }

  public searchEvent(searchText: string) {
    this.searchText = searchText;
    if (this.isShowDimTab) {
      this.dimensionFields = this._dimensionFields.filter((item) => {
        return item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1;
      });
    } else {
      this.measureFields = this._measureFields.filter((item) => {
        return item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1;
      });
    }
  }

  public confirm(modal: Modal) {
    modal.data.afterConfirm && modal.data.afterConfirm.call(this);
  }

  private _openConfirmToBoardFilter(filter: Filter) {
    const modal = new Modal();
    modal.name = this.translateService.instant('msg.board.filter.alert.change.global');
    modal.description = this.translateService.instant('msg.board.filter.alert.change.global.des');
    modal.data = {
      afterConfirm: () => {
        delete filter.ui.widgetId;
        this.setFilterEvent.emit({ key: 'EDIT', filter: filter });
      },
    };
    this._confirmModalComp.init(modal);
  }

  private _openConfirmToChartFilter(filter: Filter) {
    const modal = new Modal();
    modal.name = this.translateService.instant('msg.board.filter.alert.change.chart');
    modal.description = this.translateService.instant('msg.board.filter.alert.change.chart.des');
    modal.data = {
      afterConfirm: () => {
        filter.ui.widgetId = this.widget.id;
        this.setFilterEvent.emit({ key: 'EDIT', filter: filter });
      },
    };
    this._confirmModalComp.init(modal);
  }

  private _setFields(fields: (Field | CustomField)[], boardFilters: Filter[], chartFilters: Filter[], widget?: Widget) {
    fields.forEach((field: Field) => {
      const boardFilter: Filter = boardFilters.find((filter) => {
        return field.name === filter.field && filter.dataSource === field.dataSource;
      });

      field['isTimestamp'] = 'user_expr' !== field.type && (<Field>field).logicalType === LogicalType.TIMESTAMP;

      if (field.role === FieldRole.TIMESTAMP && (<Field>field).logicalType === LogicalType.TIMESTAMP) {
        field['importanceType'] = 'timestamp';
      } else if (boardFilter && boardFilter.ui.filteringSeq) {
        field['importanceType'] = 'recommended';
        field['filteringSeq'] = boardFilter.ui.filteringSeq;
      }

      field['useBoardFilter'] = Boolean(boardFilter);

      field['useBoardFilter'] && (field['filter'] = boardFilter);

      if (widget) {
        const filter = chartFilters.find((filter) => {
          return field.name === filter.field && widget.id === filter.ui.widgetId;
        });
        field['thisChartFilter'] = _.cloneDeep(filter);
        field['isEditable'] = Boolean(field['useBoardFilter'] || field['thisChartFilter']);
      } else {
        field['someChartFilter'] = chartFilters.filter((filter) => field.name === filter.field);
        if (0 < field['someChartFilter'].length) {
          field['someChartFilter'] = field['someChartFilter'].reduce((prev, curr) => _.merge(prev, curr), {});
        } else {
          delete field['someChartFilter'];
        }
        field['isEditable'] = Boolean(field['useBoardFilter'] || field['someChartFilter']);
      }
    });

    const dimensionFields: (Field | CustomField)[] = fields.filter((item) => item.role !== FieldRole.MEASURE);
    const measureFields: (Field | CustomField)[] = fields.filter((item) => item.role === FieldRole.MEASURE);

    this.dimensionFields = dimensionFields;
    this.measureFields = measureFields;
    this._dimensionFields = dimensionFields;
    this._measureFields = measureFields;
  }
}
